<template>
	<div class="order_wrap">
		<!-- UIDEV_220818_01 -->
		<v-container class="cust">
			<!-- UIDEV_220818_02 -->
			<div class="inner">
				<div class="txt_box">
					<!-- 상단설명 -->
					<p>
						<span class="small">안녕하세요. 코웨이㈜ 입니다.</span>
						<span class="impact">
							주문 접수 전 <br />고객 확인을 위한 인증이<br />필요합니다.
						</span>
						<span class="txt_body1" v-if="custType === 'L'">
							<!-- UIDEV_220818_03 -->
							사업자 정보를 입력하고, 휴대폰 인증을<br />진행해 주세요.
							<!-- //UIDEV_220818_03 -->
						</span>
						<span class="txt_body1" v-else>
							다음을 눌러 휴대폰 인증을 진행해 주세요.
						</span>
					</p>
					<!-- //상단설명 -->

					<!-- 고객정보 -->
					<!-- UIDEV_220818_04 -->
					<ul class="info_list mt-6">
						<li>
							<dt>고객명</dt>
							<dd>{{ name }}</dd>
						</li>
						<li>
							<dt>전화번호</dt>
							<dd>{{ getPhone(phone) }}</dd>
						</li>
					</ul>
					<!-- //고객정보 -->

					<v-form ref="reqPCCForm" class="form">
						<div class="u-cust-contents">
							<!-- 개인사업자 -->
							<div v-if="custType === 'L'">
								<div class="wrap_input ">
									<h3 class="label">사업자번호</h3>

									<v-row class="wrap_field_3 ">
										<v-col cols="3">
											<v-text-field
												dense
												outlined
												:class="{
													'error--text vaild-msg': vaildChk()
												}"
												v-model="bizNoArr[0]"
												inputmode="numeric"
												maxlength="3"
												v-mask="'###'"
												ref="biz-no-first"
												@keyup="moveNextField('first')"
											/>
										</v-col>
										<span class="dash">-</span>
										<v-col cols="2">
											<v-text-field
												dense
												outlined
												:class="{
													'error--text vaild-msg': vaildChk()
												}"
												v-model="bizNoArr[1]"
												inputmode="numeric"
												maxlength="2"
												v-mask="'##'"
												ref="biz-no-middle"
												@keyup="moveNextField('middle')"
											/>
										</v-col>
										<span class="dash">-</span>
										<v-col cols="5">
											<v-text-field
												dense
												outlined
												:class="{ 'error--text vaild-msg': vaildChk() }"
												v-model="bizNoArr[2]"
												inputmode="numeric"
												maxlength="5"
												v-mask="'#####'"
												ref="biz-no-last"
												@keyup="moveNextField('last')"
											/>
										</v-col>
									</v-row>
									<p
										class="vaild-msg error"
										v-if="
											['81', '82', '83', '84', '85', '86', '87', '88'].includes(
												bizNoArr[1]
											)
										"
									>
										법인 사업자 고객은 모바일주문 이용이 불가합니다.
									</p>

									<p v-else></p>
									<!--// 사업자번호 -->
								</div>

								<div class="wrap_input">
									<h3 class="label">상호</h3>
									<v-text-field
										dense
										outlined
										ref="name"
										placeholder="상호를 입력해주세요"
										inputmode="text"
										v-model="comName"
										:rules="rules.comName"
									/>
								</div>
								<div class="wrap_input">
									<h3 class="label">대표 업태</h3>
									<v-text-field
										dense
										outlined
										placeholder="(예시) 서비스업"
										inputmode="text"
										v-model="business"
										:rules="rules.business"
										ref="business"
									/>
								</div>
								<div class="wrap_input">
									<h3 class="label">대표 업종</h3>
									<v-text-field
										dense
										outlined
										placeholder="(예시) 정수기"
										inputmode="text"
										v-model="industry"
										:rules="rules.industry"
										ref="industry"
									/>
								</div>
								<div class="wrap_input">
									<h3 class="label expand">
										<span class="title">전자세금계산서 발행 정보</span>
									</h3>

									<div class="wrap_file_input">
										<label
											name="no_txt"
											class="no_txt v-btn v-btn--depressed theme--light v-size--default justify-space-between"
											@click="openInputPage()"
										>
											<span
												class="v-btn__content primary--text"
												v-if="info.registration === 'Y'"
												>입력 완료</span
											>
											<span
												class="v-btn__content primary--text"
												v-else-if="info.registration === 'N'"
												>추후 등록</span
											>
											<span class="v-btn__content" v-else>미입력</span>
										</label>
									</div>
									<p class="msg-dot">
										<em
											>인증 완료 후 세금계산서 발행 정보 변경은 콜센터, 판매처
											를 통하여 진행 가능합니다.</em
										>
									</p>
								</div>

								<div class="wrap_input">
									<h3 class="label expand">
										<span class="title">사업자등록증 사본</span>
									</h3>
									<div class="wrap_file_input">
										<label
											name="no_txt"
											class="no_txt v-btn v-btn--depressed theme--light v-size--default justify-space-between"
											@click="nameAndBizNoChk"
										>
											<span class="v-btn__content primary--text" v-if="imgFile"
												>등록 완료</span
											>
											<span class="v-btn__content" v-else
												>사본 이미지 등록</span
											>
										</label>
										<input
											v-if="comName && bizNoArr.join('').length === 10"
											name="no_txt"
											class="file_no_txt"
											type="file"
											accept=".tif, .jpg, .jpeg, .tiff, .TIF, .png, .PNG, .TIFF, .JPG, .JPEG, image/tif, image/tiff, image/jpg, image/jpeg;capture=camera"
											@click="resetFile"
											@change="onFileChange"
											ref="imgFile"
										/>
									</div>
								</div>
							</div>
							<!-- //개인사업자 -->
							<div class="u-cust-agreement">
								<!-- 약관정보 -->
								<v-checkbox
									class="bold_check"
									:label="'전체 동의'"
									v-model="checkedAll"
									@change="changeCheckedAll($event)"
								>
								</v-checkbox>

								<v-expansion-panels
									multiple
									focusable
									class="bor_0"
									v-model="openedSub"
								>
									<!-- [필수] 개인 및 신용정보의 수집/이용 동의 -->
									<v-expansion-panel class="accodion_select">
										<div class="multi_wrap">
											<v-checkbox
												v-model="checked0"
												@change="changeChecked0"
												:label="'[필수] 개인 및 신용정보의 수집/이용 동의'"
											>
											</v-checkbox>
											<v-expansion-panel-header class="header_center">
											</v-expansion-panel-header>
										</div>
										<v-expansion-panel-content class="warn_box">
											<div class="txt warn">
												<div class="u-cust-terms">
													<h3>수집주체: 코웨이㈜</h3>
													<p>
														<em>수집하는 개인정보 항목</em>
														<br />성명, 법정 생년월일, 성별, 국적, 연락처, 주소,
														계약제품정보, 설치인증정보, 계좌 등 결제정보,
														신용거래정보, 연체 등 신용도 정보
													</p>
													<p>
														<em>수집 및 이용목적</em>
														<br />계약 체결 결정 이행 유지관리, 개인
														신용정보조회, 기존계약확인, 결제(자동이체 등), 분쟁
														해결
													</p>
													<p>
														<em>보유 및 이용기간</em>
														<br />동의 철회 또는 관계 법령에 따른 보존 의무가
														없는 경우 계약 종료 후 5년
													</p>
												</div>
												<div class="u-cust-terms">
													<h3>수집주체: 코웨이라이프솔루션㈜</h3>
													<p>
														<em>수집하는 개인정보 항목</em>
														<br />성명, 법정 생년월일, 성별, 내외국인, 연락처,
														주소, E-mail주소, 이동통신사 구분, 본인확인값(CI,
														DI), 세이프키, 결제정보(금융기관명, 결제자명,
														생년월일, 카드번호, 계좌번호), 신용거래정보, 연체 등
														신용도 정보
													</p>
													<p>
														<em>수집 및 이용목적</em>
														<br />계약 체결 결정 이행(코웨이 결합 렌탈)
														유지관리, 개인 신용정보조회, 기존계약확인, 결제정보
														확인, 개인식별, 개인정보 도용방지, 부정이용 방지,
														분쟁해결
													</p>
													<p>
														<em>보유 및 이용기간</em>
														<br />동의 철회 또는 관계 법령에 따른 보존 의무가
														없는 경우 계약 종료 후 5년
													</p>
												</div>
											</div>
											<p class="add_txt">
												<span>
													코웨이㈜의 상품만을 주문하시는 경우,
													코웨이라이프솔루션㈜는 고객님의 개인정보를 수집 및
													보관하지 않습니다.
												</span>
											</p>
										</v-expansion-panel-content>
									</v-expansion-panel>
									<!-- //[필수] 개인 및 신용정보의 수집/이용 동의 -->

									<!-- [필수] 개인 및 신용정보의 제공/조회 동의 -->
									<v-expansion-panel class="accodion_select">
										<div class="multi_wrap">
											<v-checkbox
												v-model="checked1"
												@change="changeChecked1"
												:label="'[필수] 개인 및 신용정보의 제공/조회 동의'"
											>
											</v-checkbox>
											<v-expansion-panel-header class="header_center">
											</v-expansion-panel-header>
										</div>
										<v-expansion-panel-content class="warn_box">
											<div class="txt warn mb-0">
												<div class="u-cust-terms">
													<h3>수집주체: 코웨이㈜</h3>
													<p>
														<em>제공하는 개인정보 항목</em>
														<br />성명, 법정 생년월일, 성별, 국적, 연락처, 주소,
														결제관련정보, 신용개설정보, 신용등급 및 평점 정보,
														연체정보
													</p>
													<p>
														<em>제공목적</em>
														<br />본인확인, 상거래 설정 유지 채권추심을 위한
														신용정보조회, 신용도 평가, 연체 시 채무불이행 등록
														등
													</p>
													<p>
														<em>제공받는자</em>
														<br />NHN한국사이버결제, 이동통신사 등 (약관 및
														개인정보처리방침 참조)
													</p>
													<p>
														<em>보유 및 이용기간</em>
														<br />동의일부터 계약 종료일까지 <br />(계약 미체결
														시 효력 소멸)
													</p>
												</div>
												<div class="u-cust-terms">
													<h3>수집주체: 코웨이라이프솔루션㈜</h3>
													<p>
														<em>제공하는 개인정보 항목</em>
														<br />성명, 법정 생년월일, 성별, 내외국인, 연락처,
														주소, 계약유지정보, 세이프키, 상품구매 정보,
														납부현황, 연체정보, 결제관련정보, 추가납부금액정보
													</p>
													<p>
														<em>제공목적</em>
														<br />본인확인, 고객에 대한 계약체결, 계약이행 및
														재화 또는 서비스 제공 등
													</p>
													<p>
														<em>제공받는자</em>
														<br />나이스평가정보, 하이픈코퍼레이션 등
														<br />(코웨이라이프솔루션 홈페이지 개인정보처리방침
														참조)
													</p>
													<p>
														<em>보유 및 이용기간</em>
														<br />동의일부터 계약 종료일까지 <br />(계약 미체결
														시 효력 소멸)
													</p>
												</div>
											</div>
											<p class="add_txt">
												<span
													>코웨이㈜의 상품만을 주문하시는 경우,
													코웨이라이프솔루션㈜는 고객님의 개인 및 신용정보를
													제공받거나 조회하지 않습니다.</span
												>
												<span>
													회사가 신용조회회사를 통하여 귀하의 개인(신용)정보를
													조회한 기록은 타 금융기관등에 제공될 수 있고, 연체로
													인한 채무불이행 등록시에는 신용등급이 변동될수 있음
												</span>
												<span>동의거부 가능하나 필수동의 거부 시 계약불가</span>
												<span>개인정보처리방침 : 당사 홈페이지 게시</span>
											</p>
										</v-expansion-panel-content>
									</v-expansion-panel>
									<!-- //[필수] 개인 및 신용정보의 제공/조회 동의 -->

									<!-- [선택] 마케팅 선택 동의 -->
									<v-expansion-panel class="accodion_select">
										<div class="multi_wrap">
											<v-checkbox
												v-model="checked2"
												@change="changeChecked2"
												:label="'[선택] 마케팅 선택 동의'"
											>
											</v-checkbox>
											<v-expansion-panel-header class="header_center">
											</v-expansion-panel-header>
										</div>
										<v-expansion-panel-content class="warn_box">
											<p class="txt warn ">
												<strong class="tit">수집하는 개인정보 항목</strong>
												<span class="flat">
													성명, 법정 생년월일, 성별, 국적, 연락처, 주소, 계약
													제품 정보
												</span>
												<strong class="tit">수집 및 이용목적 </strong>
												<span class="flat">
													전화, SMS/LMS, 이메일, 우편 등을 통해 시장조사,
													상품서비스 연구개발, 이벤트 소개, 신제품 정보 전달 등
													<br />
													수집된 정보는 회사와 판매위임계약을 체결한 코디,
													&nbsp;홈케어닥터 등에게 회원모집, 고객상담 등
													&nbsp;제품/서비스 홍보 및 판매 권유 활용 목적으로
													&nbsp;위탁됩니다.
												</span>
												<strong class="tit">보유 및 이용기간 </strong>
												<span class="flat">
													동의 철회 또는 계약종료 후 1년
												</span>
											</p>
										</v-expansion-panel-content>
									</v-expansion-panel>
									<!-- //[선택] 마케팅 선택 동의 -->
								</v-expansion-panels>
							</div>
							<!-- //약관정보 -->
						</div>
					</v-form>

					<div class="u-cust-notice">
						<h4>
							[개인 및 신용정보의 수집/이용/제공/조회 동의]에 대한 안내
						</h4>
						<p>
							코웨이는 자사 상품 외에도 코웨이라이프솔루션㈜의 상품을 판매하고
							있습니다. 고객님이 코웨이㈜ 상품만을 주문하시는 경우,
							코웨이라이프솔루션㈜에 고객님의 개인 및 신용정보를 제공하지
							않으며, 수집, 이용, 조회도 하지 않습니다.
						</p>
					</div>
				</div>
			</div>

			<div class="cw-app-radius">
				<div class="u-btns-group">
					<div class="column">
						<v-btn
							large
							class="u-btn-active"
							@click="next('SAVE')"
							:disabled="isDisableBtn"
						>
							인증하기
						</v-btn>
					</div>
				</div>
			</div>
			<!-- //bottom -->
		</v-container>
		<!-- //UIDEV_220818_01 -->
		<PopTaxInfoDialog
			:name="name"
			:mob="phone"
			:info="info"
			@inputInfo="getInputInfo"
			ref="input"
		/>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import {
	fetchCustVerifyInfoByUserKey,
	requestPhotoUpload,
	fetchCustSetup
} from '@/apis/order.verify.api'
import { fetchWebOrderInfoById } from '@/apis/order.status.api'
import { openPassVerification } from '@/utils/pass'
import { getPhone } from '@/utils/ec'
import { email } from '@/utils/rules'
import { postBizCreateCookie } from '@/apis/order.verify.api'
import { isParameterEmpty } from '@/utils/common'

export default {
	name: 'CustVerifySelf',
	data() {
		return {
			name: '',
			bizName: '',
			phone: '',
			expiredTime: '',
			timeRemain: '',
			custType: '',
			orderType: '', // 청약(S), 일반(N)
			openedMain: 0,
			openedSub: [],
			checkedAll: false,
			checked0: false, // 개인 및 신용정보의 수집/이용 동의
			checked1: false, // 개인 및 신용정보의 제공/조회 동의
			checked2: false, // 마케팅 선택 동의
			bizNoArr: [{}, {}, {}],
			retUrl: '',
			codyPhoneNo: '',
			bizNo: '',
			comName: '',
			business: '',
			industry: '',
			email: '',
			mob: '',
			telNumber: '',
			tel: '',
			info: {},
			rules: {
				comName: [v => !!v || '상호를 입력해 주세요.'],
				business: [v => !!v || '대표 업태를 입력해 주세요.'],
				industry: [v => !!v || '대표 업종을 입력해 주세요.'],
				email: [v => !v || email(v)]
			},
			imgFile: undefined,
			registration: ''
		}
	},

	async mounted() {
		const { userKey, id, type } = this.$route.query
		this.$store.dispatch('verify/setUserKey', userKey)
		sessionStorage.setItem('orderId', id)
		sessionStorage.setItem('type', type)

		try {
			// 파라미터가 없이 들어오는 예외적이 경우 방지 id, userKey 필수 값
			if (this.isParameterEmpty(id) || this.isParameterEmpty(userKey)) {
				this.$router.push({ name: 'verify-cust-expired' })
				return
			}
			const fetchRes = await fetchCustVerifyInfoByUserKey(userKey)
			const res = fetchRes.resultObject
			if (res.validYn === 'N') {
				// 인증만료 화면으로 이동
				this.$router.push({ name: 'verify-cust-expired' })
				return
			}

			// 웹 주문정보 조회
			const status = await fetchWebOrderInfoById(id)
			const customerJson = JSON.parse(status.resultObject.orderInfoJson)
				.customer
			this.orderType = customerJson.orderType

			// userKey 재발급 후에도 이전 userKey로 호출시
			if (res.code === 1006) {
				// 인증만료 화면으로 이동
				this.$router.push({ name: 'verify-cust-expired' })
			}

			// 만료시 만료여부 체크
			if (res.validYn === 'N') {
				// 인증만료 화면으로 이동
				this.$router.push({ name: 'verify-cust-expired' })
				return
			} else {
				// 진행
				this.name = res.name
				this.phone = res.phoneNo
				this.expiredTime = res.expireDateTime
				this.timeRemain = res.timeRemain
				this.custType = res.custType
				this.retUrl = `31${window.location.protocol}//${window.location.host}/order/cust/payadd`

				// 고객정보 STORE Customer 저장
				let customer = {
					name: res.name,
					phone: res.phoneNo,
					custType: res.custType,
					custSelf: true,
					codyNo: res.codyNo,
					codyPhoneNo: res.codyPhoneNo,
					userKey: this.$store.getters['verify/userKey'],
					orderType: this.orderType // 사전청약(S), 일반(N)
				}
				this.$store.dispatch('verify/setCustomer', customer)

				// 코디정보 STORE userInfo 저장
				let cody = {
					codyNo: res.codyNo,
					codyPhoneNo: res.codyPhoneNo,
					type: customerJson.type,
					app: customerJson.app
				}
				this.codyPhoneNo = res.codyPhoneNo
				this.$store.dispatch('common/setUserInfo', cody)

				if (this.custType === 'L') {
					const createCookie = await postBizCreateCookie('get-cookie', {})
					const cookie = createCookie.resultObject
					if (cookie?.comName) {
						this.bizNoArr[0] = cookie ? cookie.bizNoArr0 : ''
						this.bizNoArr[1] = cookie ? cookie.bizNoArr1 : ''
						this.bizNoArr[2] = cookie ? cookie.bizNoArr2 : ''
						this.comName = cookie.comName
						this.industry = cookie.industry
						this.business = cookie.business
						this.info = cookie.info
					}
				}
			}

			/**
			 * 고입확 완료 체크 (개인, 개인사업자 동일)
			 * @type {*}
			 * @description 주소정보, 고객직접입력 추가정보 조회 후 정보가 있고, 주문번호가 있어 완료이면 완료 화면으로 이동
			 */
			if (
				['108', '106', '109', '111', '201', '202', '203'].includes(
					status.resultObject.statusCode
				)
			) {
				const inaccessMsgJson = JSON.parse(status.resultObject.orderInfoJson)

				if (status.resultObject.statusCode === '203') {
					this.$router.push({ name: 'verify-cust-deleted' })
					return
				} else if (
					//미납/연체 인증 재진입시
					inaccessMsgJson.inaccessMsg?.rtnType === 'F' &&
					(inaccessMsgJson.inaccessMsg?.rtnCode === 'OV' ||
						inaccessMsgJson.inaccessMsg?.rtnCode === 'FR')
				) {
					this.$router.push({
						name: 'cust-payuncomplete',
						params: {
							status: status.resultObject.statusCode,
							message:
								inaccessMsgJson.inaccessMsg.rtnCode === 'OV'
									? '고객 인증을 정상적으로 완료했으나, <br /> 미납/연체 금액이 있어 주문 진행이 <br /> 불가능합니다. <br />미납/연체 금액의 납부를 먼저 부탁드립니다. <br />감사합니다.'
									: '정도경영팀에 의해 주문이 차단되었습니다.'
						}
					})
				} else if (
					inaccessMsgJson.inaccessMsg?.rtnType === 'F' &&
					inaccessMsgJson.inaccessMsg?.rtnCode === 'FH'
				) {
					// 가해약 고객
					this.$router.push({
						name: 'cust-payuncomplete',
						params: {
							status: status.resultObject.statusCode,
							message: '가해약 고객은 주문접수가 불가합니다.'
						}
					})
				} else if (
					inaccessMsgJson.inaccessMsg?.rtnType === 'F' &&
					inaccessMsgJson.inaccessMsg?.rtnCode === 'ICB'
				) {
					// 휴폐업(미등록) 개인사업자
					this.$router.push({
						name: 'cust-payuncomplete',
						params: {
							status: status.resultObject.statusCode,
							message:
								'폐업(미등록)한 사업자로 확인되어 주문을 진행할수 없습니다.'
						}
					})
				} else if (
					inaccessMsgJson.inaccessMsg?.rtnType === 'F' &&
					inaccessMsgJson.inaccessMsg?.rtnCode === 'FI'
				) {
					// 대손 고객
					this.$router.push({
						name: 'cust-payuncomplete',
						params: {
							status: status.resultObject.statusCode,
							message:
								'대손 고객은 주문접수가 불가합니다.<br />' +
								'자세한 내용은 담당 판매인에게<br />' +
								'문의해 주시기 바랍니다.'
						}
					})
				} else if (
					inaccessMsgJson.inaccessMsg?.rtnType === 'F' &&
					inaccessMsgJson.inaccessMsg?.rtnCode === 'BC'
				) {
					// 블랙컨슈머
					this.$router.push({
						name: 'cust-payuncomplete',
						params: {
							status: status.resultObject.statusCode,
							message:
								'서비스 이용이 불가능합니다.<br />' +
								'콜센터(1588-5200)로 문의 부탁드립니다.'
						}
					})
				} else {
					//기타
					this.$router.push({
						name: 'cust-payuncomplete',
						params: {
							status: status.resultObject.statusCode,
							message: inaccessMsgJson.inaccessMsg?.message
						}
					})
				}
			} else if (status.resultObject.statusCode !== '110') {
				// 고객인증(01), 인증입력(110)이 아니면

				// 주소정보, 고객직접입력 추가정보 조회
				const res = await fetchCustSetup(sessionStorage.getItem('orderId'))

				if (res.resultObject.data.setup) {
					// 고입확 정보 있음(고입확 완료 페이지로 이동)
					this.$store.dispatch(
						'verify/setMobOrderNo',
						sessionStorage.getItem('orderId')
					)
					this.$router.push({
						name: 'cust-paycomplete'
					})
				} else {
					// 고입확 정보 없음(고입확 입력 페이지로 이동)
					this.$router.push({
						name: 'cust-payadd',
						query: this.$store.getters['custom/retInfo']
					})
				}
			}
		} catch (e) {
			// 인증만료 화면으로 이동
			this.$router.push({ name: 'verify-cust-expired' })
		}
	},

	computed: {
		isDisableBtn() {
			let is = false

			if (this.custType === 'L') {
				is = !(
					this.checked0 &&
					this.checked1 &&
					this.chkbizNo() &&
					this.info.registration
				)
			} else {
				is = !(this.checked0 && this.checked1)
			}

			return is
		}
	},

	methods: {
		getPhone,
		isParameterEmpty,
		...mapActions({ showSnackbar: 'common/showSnackbar' }),
		//	법인사업자 주문 불가
		vaildChk() {
			if (
				['81', '82', '83', '84', '85', '86', '87', '88'].includes(
					this.bizNoArr[1]
				)
			) {
				return true
			}
		},

		// 사업자등록번호 입력 포커스 이동
		moveNextField(name) {
			if (name === 'first') {
				if (this.bizNoArr[0].length === 3) {
					this.$refs['biz-no-middle'].focus()
				}
			} else if (name === 'middle') {
				if (this.bizNoArr[1].length === 2) {
					this.$refs['biz-no-last'].focus()
				}
			} else if (name === 'last') {
				if (this.bizNoArr[2].length === 5) {
					this.$refs['biz-no-last'].blur()
				}
			}
		},

		// 모두 체크했는지 확인
		isCheckedAll() {
			return this.checked0 && this.checked1 && this.checked2
		},

		// 모두 체크
		changeCheckedAll(value) {
			this.checked0 = value
			this.checked1 = value
			this.checked2 = value
			this.openedMain = 0
			this.openedSub = [0, 1, 2]
		},

		// 개인 및 신용정보 이용 동의
		changeChecked0(value) {
			this.openedSub = [0]
			if (this.checked0) {
				if (this.isCheckedAll()) this.checkedAll = this.checked0
			} else {
				this.checkedAll = value
			}
		},

		// 개인 및 신용정보 제공 동의
		changeChecked1(value) {
			this.openedSub = [1]
			if (this.checked1) {
				if (this.isCheckedAll()) this.checkedAll = this.checked1
			} else {
				this.checkedAll = value
			}
		},

		// 마케팅 선책 동의
		changeChecked2(value) {
			this.openedSub = [2]
			if (this.checked2) {
				if (this.isCheckedAll()) this.checkedAll = this.checked2
			} else {
				this.checkedAll = value
			}
		},

		nameAndBizNoChk() {
			if (this.bizNoArr[0].length != 3) {
				this.$refs['biz-no-first'].focus()
			} else if (this.bizNoArr[1].length != 2) {
				this.$refs['biz-no-middle'].focus()
			} else if (this.bizNoArr[2].length != 5) {
				this.$refs['biz-no-last'].focus()
			} else if (!this.comName) {
				this.$refs.name.focus()
			}
		},

		// 첨부파일 정보 초기화
		resetFile() {
			this.$refs.imgFile.value = ''
			this.imgFile = undefined
		},

		// 첨부파일 변경
		onFileChange(event) {
			let file = event.target.files[0]

			const mb = 1024 * 1024
			const limitSize = 10 * mb
			const uploadSize = event.target.files[0].size

			if (limitSize < uploadSize) {
				this.$alert({
					message: '10MB 미만 크기의 이미지로 등록해 주세요.',
					okText: '확인'
				})
				return
			}

			let codyNo = this.$store.getters['common/codyNo']
			let docInfo = {
				// regNo, // API에서 정의하여 사용
				userId: codyNo, // 판매인번호
				name: this.comName, // 상호명
				filePath: file.name, // 파일 경로
				bizNo: this.bizNoArr.join('') // 사업자 번호
			}

			let formData = new FormData()
			formData.append('userId', docInfo.userId)
			formData.append('userName', docInfo.name)
			formData.append('imgFile', file)
			formData.append('bizNo', docInfo.bizNo)
			requestPhotoUpload({}, formData)
				.then(upRes => {
					if (upRes.code === 200 && upRes.resultObject.code === '0') {
						this.$store.dispatch('verify/setCustDocInfo', {
							docInfo,
							imgFile: file,
							res: upRes.resultObject
						})
						this.imgFile = file
					} else if (upRes.code === 200 && upRes.resultObject.code === '-1') {
						this.$alert({
							message: upRes.resultObject.value
						}).then(() => {
							this.resetFile()
						})
					} else {
						this.$alert({
							message: '추가 서류 등록 중 오류가 발생하였습니다.'
						}).then(() => {
							this.resetFile()
						})
					}
				})
				.finally(() => {
					this.$log('finally')
				})
		},

		// 사업자등록번호 체크
		chkbizNo() {
			let no = this.bizNoArr.join('')

			return no.length === 10
		},

		openInputPage() {
			this.$refs.input.open()
		},

		getInputInfo(info) {
			this.info = info
		},
		// 다음으로 이동
		async next() {
			let current = moment().format('YYYY-MM-DD HH:mm:ss')

			if (current > this.expireDateTime) {
				let confirmRes = await this.$confirm({
					message:
						'인증 만료시간이 지났습니다.<br />담당코디에게 연락하시겠습니까?',
					okText: '전화걸기',
					cancelText: '취소'
				})
				if (confirmRes) {
					location.href = `tel:${this.codyPhoneNo}`
				}
			} else {
				if (this.custType === 'L') {
					if (!this.chkbizNo()) {
						this.noBizNo = true
						this.$refs['biz-no-first'].focus()
						return
					}
					if (
						['81', '82', '83', '84', '85', '86', '87', '88'].includes(
							this.bizNoArr[1]
						)
					) {
						this.$refs['biz-no-first'].focus()
						return
					}

					if (!this.comName) {
						this.$refs.name.focus()
					}

					if (!this.$refs.reqPCCForm.validate()) {
						return false
					}
					const docInfo = this.$store.getters['verify/custDocInfo']
					let custNo = this.$store.getters['verify/custNo']
					this.$log('고객번호 출력 1====>>>', custNo)

					let customer = this.$store.getters['verify/customer']
					//사업자고객 추가입력정보
					let inputParam = {
						custType: customer.custType,
						name: this.comName, //상호
						name4: customer.name,
						mob: this.mob,
						telNumber: this.telNumber,
						usrLtdNo: this.bizNo, //사업자번호
						kftbus: this.business, //업태
						kftind: this.industry, //업종
						image: docInfo,
						smtpAddr: this.email,
						kunnr: custNo,
						salesCd: this.$store.getters['common/codyNo'],
						billInfo: this.info
					}
					this.$store.dispatch('verify/setCustInputParams', inputParam)

					if (!this.imgFile) {
						this.$alert({
							message: '사업자등록증 사본을 등록해 주세요.',
							okText: '확인'
						})
						return false
					}
				}

				// 모든 동의 항목은 동의 시점과 내용에 대한 로그가 저장되어 있어야 함
				let agreeInfo = {
					aggreeTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
					agree1: this.checked0,
					agree2: this.checked1,
					agree3: this.checked2
				}

				let customer = {
					agreeInfo,
					name: this.name,
					phone: this.phone,
					custType: this.custType,
					custSelf: true,
					codyNo: this.$store.getters['common/codyNo'],
					codyPhoneNo: this.$store.getters['common/codyPhoneNo'],
					userKey: this.$store.getters['verify/userKey'],
					orderType: this.orderType
				}

				if (this.custType === 'L') {
					const param = {
						bizNoArr0: this.bizNoArr[0],
						bizNoArr1: this.bizNoArr[1],
						bizNoArr2: this.bizNoArr[2],
						comName: this.comName,
						industry: this.industry,
						business: this.business,
						telNumber: this.telNumber,
						email: this.email,
						info: this.info
					}
					await postBizCreateCookie('post-cookie', param)

					customer = Object.assign(customer, {
						bizNo: this.bizNoArr.join(''),
						bizName: this.comName
					})
				}

				this.$store.dispatch('verify/setCustomer', customer)

				openPassVerification(
					process.env.VUE_APP_VERIFY_PASS_SERVICE_NO_MWB_P,
					this.retUrl
				)
			}
		}
	}
}
</script>

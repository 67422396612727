<template>
	<div class="digital-sales-header" :class="{ fixed: isHeaderDsFixed }">
		<button
			v-if="showPhysicalBack"
			type="button"
			class="ico ds-btn-back"
			@click="dsPhysicalBack()"
		></button>
		<button
			type="button"
			class="ico ds-btn-close"
			@click="dsCloseWebView()"
		></button>
		<span class="ds-title">{{ title }}</span>
	</div>
</template>

<script>
/**
 * 디지털세일즈(DS) 앱 헤더
 * @description store에 app === 'ds'일 때 웹에서 DS앱 헤더를 생성한다.
 *              query param에 app=ds가 있을 경우 common.js에서 해당 값을 store에 넣는다.
 */
import store from '@/store'
import { createDsCloseWebView } from '@/utils/navigation'

export default {
	name: 'MHeaderDs',
	props: {
		isHeaderDsFixed: { type: Boolean, default: false }
	},
	computed: {
		title() {
			return store.getters['common/getDsHeaderDataTitle'] || '주문접수'
		},
		showPhysicalBack() {
			return store.getters['common/showPhysicalBack'] || false
		}
	},
	created() {
		this.initAppPage()
	},
	methods: {
		dsPhysicalBack() {
			window.cbPhysicalBack()
		},
		async dsCloseWebView() {
			let ok = false
			// 코웨이라이프 가이드 페이지에서는 컨펌 사용 안함
			if (this.$route && this.$route.name === 'coway-life-solution-guide') {
				ok = true
			} else {
				ok = await this.$alert({
					message: '모바일 주문을 종료하시겠습니까?',
					okText: '확인',
					cancelText: '취소'
				})
			}
			if (ok) {
				if (
					window.webkit &&
					window.webkit.messageHandlers &&
					window.webkit.messageHandlers.cowayNativeCall
				) {
					const param = {
						functionName: 'dismissInAppBrowser',
						shouldRefresh: true
					}
					window.webkit.messageHandlers.cowayNativeCall.postMessage(param)
				} else if (window.coway) {
					window.coway.dismissInAppBrowser(true)
				}
			}
		},
		initAppPage() {
			createDsCloseWebView(this)
		}
	}
}
</script>

<style>
.digital-sales-header {
	position: relative;
	height: 56px;
	padding: 8px 0;
	background-color: #fff;
	border-bottom: 1px solid #f1f1f1;
}

.digital-sales-header .ds-title {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translate(-50%, 0);
	width: calc(100% - 104px);
	font-size: 18px;
	font-weight: bold;
	color: #222;
	line-height: 56px;
	text-align: center;
}

.digital-sales-header .ico {
	position: absolute;
	width: 40px;
	height: 40px;
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: 50%;
	background-size: contain;
}

.digital-sales-header .ds-btn-back {
	left: 12px;
	background-image: url('/img/icons/btn_ds_back.png');
}

.digital-sales-header .ds-btn-close {
	right: 12px;
	background-image: url('/img/icons/btn_ds_close.png');
}
</style>

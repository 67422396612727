<template>
	<v-dialog fullscreen v-model="dialog" content-class="scroll">
		<!-- dialog -->
		<div class="u-dialog-wrap">
			<!--header-->
			<div class="u-dialog-header">
				<h2 class="heading">{{ title }}</h2>
				<v-btn icon plain depressed class="btn-close" @click="close">
					<v-icon dark>mdi-window-close</v-icon>
				</v-btn>
			</div>
			<!--//header-->
			<!--contents-->
			<div class="u-dialog-tabs expand">
				<CowayLifeSolutionGuideTab
					:tabId="tabId"
					:open="dialog"
					:pageFlag="`dialog`"
					@titleChange="titleChange"
				></CowayLifeSolutionGuideTab>
			</div>
		</div>
		<!-- //dialog -->
	</v-dialog>
</template>

<script>
/**
 * 코웨이 라이프 솔루션 가이드 팝업
 */
import CowayLifeSolutionGuideTab from '@/views/common/CowayLifeSolutionGuideTab'

export default {
	name: 'CowayLifeSolutionGuide',
	components: {
		CowayLifeSolutionGuideTab
	},
	data() {
		return {
			dialog: false,
			tabId: 0,
			title: '주문 가이드'
		}
	},
	methods: {
		open(tabId) {
			if (tabId > 0) {
				this.tabId = tabId
			} else {
				this.tabId = 0
			}
			this.dialog = true
		},
		close() {
			this.dialog = false
		},
		titleChange(title, tabId) {
			document.querySelector('.scroll').scrollTo(0, 0)
			this.tabId = tabId
			this.title = title
		}
	}
}
</script>

const state = {
	userKey: '',
	funeralProducts: [] // 신규 주문 생성 후 상조 상품
}

const getters = {
	userKey: state => state.userKey,
	funeralProducts: state => state.funeralProducts
}

const actions = {
	async setUserKey({ commit }, value) {
		commit('setUserKey', value)
	},
	//상조 상품 추가
	async setFuneralProducts({ commit }, value) {
		commit('setFuneralProducts', value)
	}
}

const mutations = {
	setUserKey(state, value) {
		state.userKey = value
	},
	setFuneralProducts(state, value) {
		state.funeralProducts = [...value]
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}

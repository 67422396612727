import verify from './verify.router' // 주문접수, 고객인증
import recp from './recp.router'
import ec from './ec.router' // 전자계약(전자서명)
import common from './common.router' // 공통, 코디폼

export default [
	{
		path: '/order',
		name: 'order',
		meta: {
			isPublic: true
		},
		component: () => import('@/views/OrderPage.vue'),
		children: [
			...verify,
			...recp,
			...ec,
			...common,
			{
				path: 'pay',
				name: 'order-pay',
				meta: {
					isPublic: true,
					type: 'main',
					title: '할인선택'
				},
				component: () => import('@/views/pay/PayPageSale.vue')
			},
			{
				path: 'prd/search',
				name: 'prd-search',
				meta: {
					isPublic: true,
					type: 'main',
					title: '상품검색'
				},
				component: () => import('@/views/prd/PrdSearch.vue')
			},
			{
				path: 'prd/detail',
				name: 'prd-detail',
				meta: {
					isPublic: true,
					type: 'main',
					title: '상품선택'
				},
				component: () => import('@/views/prd/PrdDetail.vue')
			},
			{
				path: 'prd/funeralPrd/detail',
				name: 'funeral-prd-detail',
				meta: {
					isPublic: true,
					type: 'main',
					title: '상품선택'
				},
				component: () => import('@/views/prd/FuneralPrdDetail.vue')
			},
			{
				path: 'inst',
				name: 'order-inst',
				meta: {
					isPublic: true,
					type: 'main',
					title: '설치정보'
				},
				component: () => import('@/views/inst/InstPage.vue')
			},
			{
				path: 'status',
				name: 'order-status',
				meta: {
					isPublic: true,
					type: 'main',
					title: '주문진행현황'
				},
				component: () => import('@/views/status/OrderStatusPage.vue')
			},
			{
				path: 'status/cancel',
				name: 'status-cancel',
				meta: {
					isPublic: true,
					type: 'main'
				},
				component: () => import('@/views/status/OrderStatusCancel.vue')
			}
		]
	}
]

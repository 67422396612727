const state = {
	prdList: [],
	funeralPrdList: [],
	recpAmt: 0, // 임시 수납금액
	prdChk: false, //선택한 상품갯수
	matterssChk: false // 매트리스 추가 안내
}

const getters = {
	prdList: state => state.prdList,
	funeralPrdList: state => state.funeralPrdList,
	recpAmt: state => state.recpAmt,
	prdChk: state => state.prdChk,
	matterssChk: state => state.matterssChk
}

const actions = {
	async addPrd({ commit }, value) {
		commit('addPrd', value)
	},
	async addFuneralPrd({ commit }, value) {
		commit('addFuneralPrd', value)
	},
	async delPrd({ commit }, value) {
		commit('delPrd', value)
	},
	async delFuneralPrd({ commit }, value) {
		commit('delFuneralPrd', value)
	},
	async delPrdOid({ commit }, value) {
		commit('delPrdOid', value)
	},
	async delFuneralPrdOid({ commit }, value) {
		commit('delFuneralPrdOid', value)
	},
	async delAllPrd({ commit }) {
		commit('delAllPrd')
	},
	async setRecpAmt({ commit }, value) {
		commit('setRecpAmt', value)
	},
	async setPrdList({ commit }, value) {
		commit('setPrdList', value)
	},
	async setFuneralPrdList({ commit }, value) {
		commit('setFuneralPrdList', value)
	},
	async setPrdChk({ commit }, value) {
		commit('setPrdChk', value)
	},
	async setMatterssChk({ commit }, value) {
		commit('setMatterssChk', value)
	}
}

const mutations = {
	addPrd(state, value) {
		state.prdList.push(value)
	},
	addFuneralPrd(state, value) {
		state.funeralPrdList.push(value)
	},
	delPrd(state, payload) {
		// let i = state.prdList.indexOf(value)
		const { i } = payload
		state.prdList.splice(i, 1)
		// 결합되어 있는 상조 상품 삭제
		if (state.funeralPrdList && state.funeralPrdList.length > 0) {
			state.funeralPrdList = state.funeralPrdList.filter(
				v => v.oid !== payload.prd.oid
			)
		}
	},
	delFuneralPrd(state, payload) {
		// let i = state.prdList.indexOf(value)
		const { i } = payload
		state.funeralPrdList.splice(i, 1)
	},
	delPrdOid(state, payload) {
		state.prdList.forEach((v, i) => {
			if (v.oid === payload.oid) {
				if (v.options.quantity > 1) {
					// 수량 변경
					const count = Number(v.options.quantity) - 1
					state.prdList[i].options.quantity = count
				} else {
					// 삭제
					state.prdList.splice(i, 1)
				}
			}
		})

		// 결합되어 있는 상조 상품 삭제
		if (
			state.funeralPrdList.some(v => v.type === 'new' && v.oid === payload.oid)
		) {
			// 상조prdList 삭제된것 제거
			state.funeralPrdList = state.funeralPrdList.filter(
				v =>
					v.oid !== payload.oid ||
					(v.oid === payload.oid && v.oidPrdIndex !== payload.prdIdx)
			)
			// list 안에 저장되어있는 인덱스 수정
			state.funeralPrdList
				.filter(v => v.type === 'new' && v.oid === payload.oid)
				.forEach(v => {
					v.oidPrdIndex =
						v.oidPrdIndex > payload.prdIdx ? v.oidPrdIndex - 1 : v.oidPrdIndex
				})
		}
	},
	delFuneralPrdOid(state, payload) {
		state.funeralPrdList.forEach((v, i) => {
			if (v.funeralPrdOid === payload) {
				if (v.options.quantity > 1) {
					// 수량 변경
					const count = Number(v.options.quantity) - 1
					state.funeralPrdList[i].options.quantity = count
				} else {
					// 삭제
					state.funeralPrdList.splice(i, 1)
				}
			}
		})
	},
	delAllPrd(state) {
		state.prdList = []
		state.funeralPrdList = []
	},
	setRecpAmt(state, value) {
		state.recpAmt = value
	},
	setPrdList(state, value) {
		state.prdList = value
	},
	setFuneralPrdList(state, value) {
		state.funeralPrdList = value
	},
	setPrdChk(state, value) {
		state.prdChk = value
	},
	setMatterssChk(state, value) {
		state.matterssChk = value
	}
}

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations
}

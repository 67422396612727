<template>
	<div class="order_wrap px-6" v-if="!progress">
		<v-container>
			<div class="txt_box">
				<p>
					<span class="small">안녕하세요. 코웨이(주) 입니다. </span>
					<span class="impact"
						>고객님의 주문이 <br />
						확정 되었습니다.
					</span>
					<span class="txt_body1"
						>제품 설치를 위해 담당 서비스 매니저가 연락 드릴 예정입니다. 주문해
						주셔서 감사합니다.</span
					>
				</p>
			</div>
		</v-container>
	</div>
</template>

<script>
import {
	checkingStatus,
	postWebOrderInfoById,
	fetchWebOrderInfoById,
	updateCheckingSchedule
} from '@/apis/order.status.api'
import {
	sendAlimTalk,
	fetchEContract,
	paymentRecpCheck
} from '@/apis/order.ec.api'
import { cardTransfer } from '@/apis/order.recp.api'
import { bankInstantTransfer } from '@/apis/order.recp.api'
import { registerCallbackToApp } from '@/utils/navigation'
import filters from '@/mixins/filters'
import computeds from '@/mixins/computeds'
export default {
	mixins: [filters, computeds],
	data() {
		return {
			isBankType: false, // 계좌입금: true, 계좌입금 외: false
			orderNo: '',
			orderList: [],
			sumAmt: {},
			webOrderSum: {},
			progress: true,
			deliveryInfos: {},
			mobOrderNo: undefined,
			orderStatusList: [],
			isPreContract: false,
			funeralProducts: []
		}
	},
	created() {
		this.initAppPage()
	},
	async mounted() {
		this.mobOrderNo = sessionStorage.getItem('mobOrderNo')
		sessionStorage.removeItem('mobOrderNo')

		if (!this.mobOrderNo) {
			await this.$router.push({ name: 'ec-cust-complete-homepage' })
			return
		}

		const infoRes = await fetchWebOrderInfoById(this.mobOrderNo)
		const info = infoRes.resultObject

		// 주문확정,배정실패, 배정대기, 배정진행인 경우 리턴
		if (['109', '111', '112', '113'].includes(info.statusCode)) {
			this.progress = false
			return
		}

		// 주문불가인 경우
		if (['201'].includes(info.statusCode)) {
			const params = {
				title: '주문 진행이 불가한 상태입니다.',
				msg: '자세한 내용은 담당 판매인에게 연락 바랍니다.'
			}
			this.setGuideMessage(params.title, params.msg)
			return
		}

		const json = JSON.parse(info.orderInfoJson)

		await this.$store.dispatch(
			'pay/saveMonthAmtDc',
			json.MONTH_AMT_DC !== undefined ? json.MONTH_AMT_DC : 0
		)
		await this.$store.dispatch('common/setUserInfo', {
			codyNo: info.codyNo,
			codyPhoneNo: json.customer.codyPhoneNo,
			app: json.customer.app || ''
		})
		await this.$store.dispatch('verify/setMobOrderNo', info.id)
		await this.$store.dispatch('verify/setCustomer', json.customer)
		await this.$store.dispatch('pay/saveRecpAmt', json.RECP_AMT)
		await this.$store.dispatch('inst/setOrderInfoResult', json.orderInfoResult)
		this.isPreContract = this.$store.state['verify'].customer.orderType === 'C'

		this.deliveryInfos = json.deliveryInfos
		this.webOrderSum = json.PAY_SUMMARY_AMT
		this.funeralProducts = json.funeralProducts || []

		const res = await fetchEContract({ mobOrderNo: this.mobOrderNo })
		this.orderList = res.resultObject.data[0].setup
		this.sumAmt = res.resultObject.data[0].sumAmt
		this.sumAmt.MONTH_AMT_DC = this.$store.getters['pay/monthAmtDc']
		this.sumAmt.MONTH_AMT_TOTAL =
			this.sumAmt.MONTH_AMT - this.sumAmt.MONTH_AMT_DC
		let prdNm = this.orderList[0] ? this.orderList[0].GOODS_NM : '제품명'
		let planDt = this.orderList[0].FIRST_DT ?? ''
		let count = this.orderList.length - 1
		let ordNoStr = ''
		this.orderList.forEach((res, i) => {
			if (i === 0) {
				ordNoStr = `${res.ORDER_NO}`
			} else {
				ordNoStr = `${ordNoStr}, ${res.ORDER_NO}`
			}
		})

		await this.createOrderStatusList()
		const allCanceledOrder = await this.allCanceledOrderChkAndUpdate()
		if (allCanceledOrder) {
			const params = {
				title: '주문이 삭제된 상태입니다.',
				msg: '자세한 내용은 담당 판매인에게 연락 바랍니다.'
			}
			this.setGuideMessage(params.title, params.msg)
			return
		}

		if (json.RECP_AMT > 0) {
			// 전자서명 후 수납금액 있을 때
			if (json.recp) {
				await this.$store.dispatch(
					'recp/setRecpParams',
					json.recp.recpParams ? json.recp.recpParams : {}
				)
				await this.$store.dispatch(
					'recp/setRecpInfos',
					json.recp.recpInfos ? json.recp.recpInfos : {}
				)
				await this.$store.dispatch(
					'recp/setVAccountParams',
					json.recp.vaccountParams ? json.recp.vaccountParams : {}
				)
				await this.$store.dispatch(
					'recp/setDpParams',
					json.recp.dpParams ? json.recp.dpParams : {}
				)
				await this.$store.dispatch(
					'recp/setPayRecp',
					json.recp.payRecp ? json.recp.payRecp : {}
				)
				await this.$store.dispatch(
					'recp/setIsCustSelfPay',
					json.recp.isCustSelfPay ? json.recp.isCustSelfPay : false
				)
			}

			const recpInfos = this.$store.getters['recp/recpInfos']

			await paymentRecpCheck(this.mobOrderNo)
				.then(async RES => {
					const result = RES.resultObject.data.E_RETURN
					if (result.TYPE === 'S') {
						// 수납 확인이 되었음
						await this.alimTalk(
							this.isPreContract ? '6' : '2',
							ordNoStr,
							prdNm,
							planDt,
							count
						)
						// 배정 진행 현황 체크 후 상태값 변경
						await this.confirmBooking()
					} else {
						// 수납 확인이 안되었음
						if (
							recpInfos &&
							recpInfos.mthd &&
							(recpInfos.mthd === 'AA' || recpInfos.mthd === '11') &&
							(info.statusCode === '108' || info.statusCode === '106')
						) {
							this.$log('은행이체')

							const recpParams = this.$store.getters['recp/recpParams']
							recpParams.zwebRecp = '07' // 서명 후 은행즉시이체는 '04' -> '07'
							recpParams.salesApp = this.$store.getters['common/app'] // 앱 구분자 추가
							recpParams.salesType = this.$store.getters['common/type']
							const bankRes = await bankInstantTransfer(recpParams)
							const BANK_E_RETURN = bankRes.resultObject.data.E_RETURN
							const message = bankRes.resultObject.data.TC_BKACCT[0]
							if (BANK_E_RETURN.TYPE !== 'S') {
								//이체 실패시 거부사유 저장하기 위함
								const res = await fetchWebOrderInfoById(this.mobOrderNo)
								let webParams = res.resultObject
								let order = JSON.parse(webParams.orderInfoJson)
								Object.assign(order.recp.recpInfos, {
									errMsg: BANK_E_RETURN.MESSAGE
								})
								webParams.orderInfoJson = JSON.stringify(order)
								webParams.statusCode = '106'
								await postWebOrderInfoById(this.mobOrderNo, webParams)

								await this.$router.push({
									name: 'recp-cust-uncomplete',
									params: {
										status: '106',
										msg: BANK_E_RETURN.MESSAGE,
										errMsg: BANK_E_RETURN.MESSAGE,
										amt: message.RQAMT,
										div: 'bank'
									}
								})
							} else {
								await this.alimTalk(
									this.isPreContract ? '6' : '2',
									ordNoStr,
									prdNm,
									planDt,
									count
								)
								// 배정 진행 현황 체크
								await this.confirmBooking()
							}
						} else if (
							recpInfos &&
							recpInfos.mthd &&
							(recpInfos.mthd === 'BB' || recpInfos.mthd === '12') &&
							(info.statusCode === '108' || info.statusCode === '106')
						) {
							this.$log('카드이체')

							const recpParams = this.$store.getters['recp/recpParams']
							recpParams.zwebRecp = '07'
							recpParams.salesApp = this.$store.getters['common/app'] // 앱 구분자 추가
							recpParams.salesType = this.$store.getters['common/type']
							const cardRes = await cardTransfer(recpParams)
							const CARD_E_RETURN = cardRes.resultObject.data.E_RETURN
							const message = cardRes.resultObject.data.TC_CARD[0]
							if (CARD_E_RETURN.TYPE !== 'S') {
								//이체 실패시 거부사유 저장하기위함
								const res = await fetchWebOrderInfoById(this.mobOrderNo)
								let webParams = res.resultObject
								let order = JSON.parse(webParams.orderInfoJson)
								Object.assign(order.recp.recpInfos, {
									errMsg: CARD_E_RETURN.MESSAGE
								})
								webParams.orderInfoJson = JSON.stringify(order)
								webParams.statusCode = '106'
								await postWebOrderInfoById(this.mobOrderNo, webParams)

								await this.$router.push({
									name: 'recp-cust-uncomplete',
									params: {
										status: '106',
										msg: CARD_E_RETURN.MESSAGE,
										errMsg: CARD_E_RETURN.MESSAGE,
										amt: message.I_OKAMT,
										div: 'card'
									}
								})
							} else {
								await this.alimTalk(
									this.isPreContract ? '6' : '2',
									ordNoStr,
									prdNm,
									planDt,
									count
								)
								// 배정 진행 현황 체크 후 상태값 변경
								await this.confirmBooking()
							}
						}
					}
				})
				.catch(ERR => {
					const e = ERR.resultObject.data.E_RETURN
					this.$log(e)
					this.$alert({ message: `${e.CODE}: ${e.MESSAGE}` })
				})
		} else {
			await this.alimTalk(
				this.isPreContract ? '6' : '2',
				ordNoStr,
				prdNm,
				planDt,
				count
			)
			// 배정 진행 현황 체크 후 상태값 변경
			await this.confirmBooking()
		}
	},
	methods: {
		/**
		 * 알림톡 발송
		 * @param reqCd : string (계약코드)
		 * @param ordNoStr : array (주문번호)
		 * @param prdNm : string (상품명)
		 * @param planDt : planDt
		 * @param count : number (수량)
		 * @returns {Promise<void>}
		 */
		async alimTalk(reqCd, ordNoStr, prdNm, planDt, count) {
			// 알림톡 발송
			let atParams = {
				reqCd: reqCd, // 1: 계약서발송, 2: 주문완료, 3: 서명완료재진입
				gubun: '1', // 1: 고객직접
				name:
					this.$store.getters['verify/customerType'] === 'L' // 사업자는 사업자명, 개인은 성명
						? this.$store.getters['verify/bizName']
						: this.$store.getters['verify/custName'],
				phone: this.$store.getters['verify/custPhone'],
				orderNo: ordNoStr,
				url: `${process.env.VUE_APP_FRT_URL}/order/confirm`, // 주문확정 링크
				orderInfoId: this.mobOrderNo, // 웹주문번호
				customerNo: this.$store.getters['verify/custNo'],
				codyNo: this.$store.getters['common/codyNo'],
				codyPhoneNo: this.$store.getters['common/codyPhoneNo'],
				goodsNm: prdNm,
				planDt: planDt,
				goodsInfo: count > 0 ? `${prdNm} 외 ${count}대` : `${prdNm}`,
				onceAmt: this.$options.filters.comma(
					this.webOrderSum.totalSumOfRecpAmt
				),
				monthAmt: this.$options.filters.comma(
					this.webOrderSum.totalSumOfMonthAmt
				)
			}

			await sendAlimTalk(atParams)
		},

		// 배정진행
		async confirmBooking() {
			const resWebOrder = await fetchWebOrderInfoById(this.mobOrderNo)
			let webParams = resWebOrder.resultObject
			if (this.isPreContract) {
				webParams.statusCode = '112' // 배정대기
				await postWebOrderInfoById(this.mobOrderNo, webParams)
				this.progress = false
				return
			}

			//region 취소여부 확인
			const orderNoList = []
			for (const deliveryInfo of this.deliveryInfos) {
				orderNoList.push(deliveryInfo.orderNo)
			}

			const res = await checkingStatus({
				saleCd: this.$store.getters['common/codyNo'],
				kunnr: this.$store.getters['verify/custNo'],
				orderNos: orderNoList
			})
			res.resultObject.T_ORDER_STATUS.forEach((v, i) => {
				if (v.ORDER_STATUS === '203') {
					this.deliveryInfos[i].orderCancelYn = true
				} else {
					this.deliveryInfos[i].orderCancelYn = false
				}
			})
			//endregion
			if (this.funeralProducts.length > 0) {
				// 환가전 상조 주문대기(114)로 업데이트
				const resWebOrder = await fetchWebOrderInfoById(this.mobOrderNo)
				let webParams = resWebOrder.resultObject
				webParams.statusCode = '114'
				await postWebOrderInfoById(this.mobOrderNo, webParams)
				this.progress = false
				return
			}
			//endregion

			// deliveryInfos 업데이트
			const deliveryParams = {
				deliveryInfos: [...this.deliveryInfos]
			}

			const result = await updateCheckingSchedule(
				this.mobOrderNo,
				deliveryParams
			)

			if (
				result.resultObject.statusCode === '109' ||
				result.resultObject.statusCode === '111'
			) {
				this.progress = false
			}
		},

		complete() {
			if (window.BMManager) {
				this.$router.push({ name: 'order-status' })
			} else {
				location.href = `${this.cowayHome}`
			}
		},

		async saveWebOrderInfo(infoRes, step, statusCode) {
			const webParams = infoRes.resultObject
			webParams.step = step
			webParams.statusCode = statusCode

			await postWebOrderInfoById(this.mobOrderNo, webParams).then(() => {
				if (statusCode === '109' || statusCode === '111') {
					this.progress = false
				}
			})
		},

		initAppPage() {
			registerCallbackToApp({})
		},
		async createOrderStatusList() {
			const orderNoList = []
			for (const order of this.orderList) {
				orderNoList.push(order.ORDER_NO)
			}

			const response = await checkingStatus({
				saleCd: this.$store.getters['common/codyNo'],
				kunnr: this.$store.getters['verify/custNo'],
				orderNos: orderNoList
			})

			this.orderStatusList = response.resultObject.T_ORDER_STATUS
		},
		async allCanceledOrderChkAndUpdate() {
			const cancelYn = this.orderStatusList.every(v => v.ORDER_STATUS === '203')
			this.$log('all order status cancel : ', cancelYn)
			if (cancelYn) {
				const res = await fetchWebOrderInfoById(this.mobOrderNo)
				const webParams = res.resultObject
				webParams.statusCode = '203'

				await postWebOrderInfoById(this.mobOrderNo, webParams)
			}

			return cancelYn
		},
		// 가이드 화면으로 이동
		setGuideMessage(title, message) {
			const params = {
				title: title,
				msg: message
			}
			this.$store.dispatch('common/setGuideData', params)
			this.$router.push({ name: 'guide' })
		}
	}
}
</script>

<template>
	<div
		class="cw-product-cart"
		:class="{ active: bottomPannel }"
		v-if="hasPrdsAndInit()"
	>
		<div class="product-handle">
			<v-btn icon @click="clickOpen()">
				<v-icon>mdi-chevron-up</v-icon>
			</v-btn>
		</div>

		<!-- 선택상품 썸네일 -->
		<div class="product-thumb">
			<div class="inner">
				<v-btn
					class="btn-img"
					@click="clickOpen()"
					v-for="(prevPrd, i) in prevList"
					:key="`prevPrd-${i}`"
				>
					<img
						:src="`${prevPrd.prd.color_file_path}`"
						:alt="prevPrd.prd.leader_model_nm"
					/>
				</v-btn>
				<v-btn
					class="btn-img"
					@click="clickOpen()"
					v-for="(prevFuneralPrd, i) in prevFuneralList"
					:key="`prevFuneralPrd-${i}`"
				>
					<img
						:src="`${prevFuneralPrd.prd.imagePath}`"
						:alt="prevFuneralPrd.prd.prodNm"
					/>
				</v-btn>
			</div>
		</div>
		<!--// 선택상품 썸네일 -->

		<!-- 선택상품 리스트 -->
		<div class="product-list">
			<div class="itembox" v-for="(prd, i) in prevList" :key="`prd-${i}`">
				<dl>
					<dt>
						<img
							:src="`${prd.prd.color_file_path}`"
							:alt="prd.prd.leader_model_nm"
						/>
					</dt>
					<dd>
						<div class="tit">{{ prd.prd.leader_model_nm }}</div>
						<div class="type">
							<p>
								<span>{{ getSellType(prd.options.sellType) }}</span>
								<em
									>{{ prd.prd.prod_nm }}, {{ prd.prd.erp_cd }}
									<template v-if="prd.prd.kind_val_txt"
										>, {{ prd.prd.kind_val_txt }}</template
									></em
								>
							</p>
						</div>
						<div class="detail">
							<template v-if="prd.options.prdOption1">
								<p>{{ prd.options.prdOption1.text }}</p>
							</template>
							<template v-if="prd.options.freeStand">
								<p>{{ prd.options.freeStand.text }}</p>
							</template>
							<template v-if="prd.options.hangerType">
								<p>{{ prd.options.hangerType.text }}</p>
							</template>
							<template v-if="prd.options.hangerType2">
								<p>{{ prd.options.hangerType2.text }}</p>
							</template>
							<template v-if="prd.options.deliveryType">
								<p>{{ prd.options.deliveryType.text }}</p>
							</template>
							<template v-if="prd.options.filterType">
								<p>{{ prd.options.filterType.text }}</p>
							</template>
							<template v-if="prd.options.filterType2">
								<p>{{ prd.options.filterType2.text }}</p>
							</template>
							<template v-if="prd.options.filterType3">
								<p>{{ prd.options.filterType3.text }}</p>
							</template>
							<template v-if="prd.options.filterType4">
								<p>{{ prd.options.filterType4.text }}</p>
							</template>
						</div>
						<div class="dot">
							<template v-for="(item, idx) in getPrdIncludeFuneralPrdList(prd)">
								<p :key="`item-${idx}`">
									<span>신규결합</span><em>{{ item.prodNm }}</em>
								</p>
							</template>
						</div>
						<v-btn class="btn-del" icon @click="delPrd(prd, i)"
							><v-icon>mdi-close</v-icon>삭제</v-btn
						>
					</dd>
				</dl>
				<div class="info">
					<div class="price">
						<!-- 등록비 -->
						<p
							v-if="
								prd.options.sellType.code === 'Z001' ||
									prd.options.sellType.code === 'Z002'
							"
						>
							<span>등록비</span>
							<em>
								{{ (prd.esProd.REG_PRICE * prd.options.quantity) | comma }}
							</em>
							원
						</p>
						<!--// 등록비 -->
						<!-- 렌타료 -->
						<p
							v-if="
								prd.options.sellType.code === 'Z001' ||
									prd.options.sellType.code === 'Z002'
							"
						>
							<span>렌탈료</span>
							<em>
								{{ (prd.esProd.MONTH_AMT * prd.options.quantity) | comma }}
							</em>
							원
						</p>
						<!--// 렌타료 -->
						<!-- 일시불 -->
						<p
							v-if="
								prd.options.sellType.code === 'Z003' ||
									prd.options.sellType.code === 'Z004' ||
									prd.options.sellType.code === 'Z005'
							"
						>
							<span>일시불</span>
							<em>
								{{ (prd.esProd.PRICE * prd.options.quantity) | comma }}
							</em>
							원
						</p>
						<!-- 일시불 -->
					</div>

					<div class="counter">
						<MNumericUpDown
							v-model="prd.options.quantity"
							:sellType="prd.options.sellType.code"
							:prd="prd"
							:flag="`prd`"
						/>
					</div>
				</div>
			</div>
			<div
				class="itembox"
				v-for="(funeralPrd, i) in prevFuneralList"
				:key="`funeralPrd-${i}`"
			>
				<dl>
					<dt>
						<img
							:src="`${funeralPrd.prd.imagePath}`"
							:alt="funeralPrd.prd.prodNm"
						/>
					</dt>
					<dd>
						<div class="tit">{{ funeralPrd.prd.prodNm }}</div>
						<div class="type">
							<p>
								<em>{{ funeralPrd.prd.prodGb }}</em>
							</p>
						</div>
						<div class="dot">
							<p>
								<span>{{
									funeralPrd.type === 'new' ? '신규결합' : '기존결합'
								}}</span
								><em v-if="funeralPrd.type === 'new'">{{
									displayCombinedPrd(funeralPrd)
								}}</em
								><em v-else-if="funeralPrd.type === 'pre'"
									>{{ funeralPrd.preOrder.GOODS_NM
									}}<i class="tx-gray">
										({{ funeralPrd.preOrder.ORDER_NO }})</i
									></em
								>
							</p>
						</div>
						<v-btn class="btn-del" icon @click="delFuneralPrd(funeralPrd, i)"
							><v-icon>mdi-close</v-icon>삭제</v-btn
						>
					</dd>
				</dl>
				<div class="info">
					<div class="price">
						<!-- 월 납입금 -->
						<p>
							<span>월 납입금</span>
							<em>
								{{
									(funeralPrd.prd.price * funeralPrd.options.quantity) | comma
								}}
							</em>
							원
						</p>
						<!-- 납입 개월수 -->
						<p>
							<span>납입 개월수</span>
							<em>
								{{ funeralPrd.options.paymentMonth }}
							</em>
							개월
						</p>
					</div>

					<div class="counter">
						<MNumericUpDown
							v-model="funeralPrd.options.quantity"
							:flag="`funeral`"
						/>
					</div>
				</div>
			</div>
		</div>
		<!--// 선택상품 리스트 -->
	</div>
</template>
<script>
/**
 * 상품 장바구니
 * @description
 */
import filters from '@/mixins/filters'
import { isProduction } from '@/utils'
import computeds from '@/mixins/computeds'
import { getSellType, hasPrds } from '@/utils/ec'

export default {
	mixins: [filters, computeds],
	props: {
		bottomPannel: {
			type: Boolean
		}
	},
	data() {
		return {
			prevList: [], // 상품 리스트
			prevFuneralList: [],
			isProduction: isProduction(),
			flag: ''
		}
	},
	created() {},
	methods: {
		getSellType,
		hasPrds,

		// 장바구니 펼침, 접힘
		clickOpen() {
			this.$emit('bottomPannel', !this.bottomPannel)
			if (this.bottomPannel) {
				for (let prd of this.prevList) {
					if (this.prevFuneralList && this.prevFuneralList.length > 0) {
						const combinePrds = this.prevFuneralList.filter(
							v => v.oid === prd.oid
						)
						prd = Object.assign(prd, {
							combinedPrd: combinePrds
						})
					}
				}
			}
		},
		// 상품 추가
		hasPrdsAndInit() {
			this.prevList = this.$store.getters['prd/prdList']
			this.prevFuneralList = this.$store.getters['prd/funeralPrdList']
			return this.hasPrds()
		},
		//결합된 환가전 제품
		displayCombinedPrd(funeralPrd) {
			const prd = this.prevList.filter(v => v.oid === funeralPrd.oid)
			return prd[0]?.prd.prod_nm
		},
		// 상품 삭제
		async delPrd(prd, i) {
			if (this.prevFuneralList.some(v => v.oid === prd.oid)) {
				const res = await this.$alert({
					message:
						'상품을 삭제하시면 결합된 제휴상품도 함께 삭제 처리 됩니다. 제휴상품과 함께 삭제하시겠습니까?',
					okText: '확인',
					cancelText: '취소'
				})
				if (res) {
					//환가전 삭제
					this.$store.dispatch('prd/delPrd', { prd, i })
				}
			} else {
				this.$store.dispatch('prd/delPrd', { prd, i })
			}
		},
		delFuneralPrd(prd, i) {
			this.$store.dispatch('prd/delFuneralPrd', { prd, i })
		},
		getPrdIncludeFuneralPrdList(prd) {
			const prdOidequalFuneralPrdList = this.prevFuneralList.filter(
				v => v.oid === prd.oid
			)

			let result = []

			for (const prevfuneral of prdOidequalFuneralPrdList) {
				const existIdx = result.findIndex(
					x => x.oid === prevfuneral.oid && x?.prodNm === prevfuneral.prd.prodNm
				)
				if (existIdx > -1) continue

				const tmp = {
					oid: prevfuneral.oid,
					prodNm: prevfuneral.prd.prodNm
				}
				result.push(tmp)
			}
			return result
		}
	}
}
</script>

<template>
	<div class="order_wrap">
		<v-container class="">
			<MStepper />
			<h1 class="tit_page px-6">고객정보 입력</h1>
			<v-form class="form px-6" ref="selfForm">
				<div class="wrap_input">
					<h3 class="label">고객 구분</h3>
					<div class="type-button expand">
						<v-btn-toggle tile v-model="custType" class="flex-start">
							<v-btn :value="1" text> 개인 </v-btn>
							<v-btn :value="2" text> 개인사업자 </v-btn>
						</v-btn-toggle>
					</div>
				</div>
				<div class="wrap_input">
					<h3 class="label">고객명</h3>
					<h4 class="notice" v-if="custType === 2">
						* 상호명이 아닌 고객 이름 입력
					</h4>
					<v-text-field
						outlined
						dense
						placeholder="이름 입력"
						inputmode="text"
						:maxlength="$Constants.MAX_CUST_NAME_LENGTH"
						v-mask=""
						v-model.trim="name"
						:rules="rules.name"
					/>
				</div>
				<div class="wrap_input">
					<h3 class="label">휴대폰번호</h3>
					<v-text-field
						outlined
						dense
						placeholder="휴대폰번호 11자리 입력"
						inputmode="numeric"
						maxlength="11"
						minlength="10"
						v-mask="['##########', '###########']"
						v-model="phone"
						:rules="rules.phone"
					/>
				</div>
			</v-form>
			<VerifySelfDialog ref="verify-self" />
		</v-container>
		<div class="bottom_box px-6">
			<v-btn
				depressed
				color="primary"
				class="col_1_small"
				@click="next()"
				:disabled="!valid"
			>
				본인인증 요청
			</v-btn>
		</div>

		<div class="u-order-notice active mt-8" v-if="funeralAccessableYn === 'Y'">
			<h4 class="notice-title">
				<span class="size-14">상조 주문 안내 사항</span>
			</h4>
			<ul class="notice-list">
				<li>
					<p>
						상조 상품은 내국인이면서 개인 고객님만 주문 가능합니다.
					</p>
				</li>
				<li>
					<p>
						고객인증을 통해 코웨이 주문과 결합하여 상조 상품을 주문 할 수
						있습니다.
					</p>
				</li>
				<!-- <li>
					<p>
						코웨이 주문과 결합없이 상조 상품만 단독으로 주문하시려면
						<a
							href="#"
							@click.prevent="windowOpen(cowayLifeLink)"
							class="underline"
							>상조 단독 주문하기</a
						>
						통해 주문해주세요.
					</p>
				</li> -->
			</ul>
		</div>

		<!-- UIDEV_220914 -->
		<div
			style="font-size: 13px;position:absolute; right: 0;bottom:0; padding: 0px 10px 10px 0px;"
		>
			Version {{ appVersion }}
		</div>
		<!-- //UIDEV_220914 -->
	</div>
</template>

<script>
import { chkDeptCd } from '@/utils/ec'
import { registerCallbackToApp } from '@/utils/navigation'
import { fetchCody } from '@/apis/order.status.api'
import { sendLmsLinkToCustForVerification } from '@/apis/order.verify.api'
import { phoneNumber, minLength } from '@/utils/rules'
import { getPhone, getName } from '@/utils/ec'
import { getCowayLifeSolutionUserId } from '@/utils/common.js'
import { fetchEncParams } from '@/apis/coway.life.api'

export default {
	name: 'VerifyPage',
	components: {},
	props: {},
	data() {
		return {
			dept_cd: '',
			name: '',
			phone: '',
			custType: '',
			rules: {
				name: [v => (v && v.length <= 40) || '이름을 입력하세요.'],
				phone: [v => minLength(v, 10), v => phoneNumber(v)]
			},
			nameChk: false,
			phoneChk: false,
			valid: false,
			appVersion: '',
			appName: '',
			type: '',
			cowayLifeLink: '',
			funeralAccessableYn: this.$store.getters['common/funeralAccessableYn']
		}
	},
	async created() {
		this.initAppPage()
		this.appVersion = process.env.VUE_APP_VERSION
		this.appName = process.env.VUE_APP_NAME
	},

	async mounted() {
		if (this.$route.query.user_no) {
			let userNo = this.$route.query.user_no
			this.$log('userNo', userNo)

			userNo = userNo.replaceAll(' ', '+')
			userNo = userNo.replaceAll('+', '%2B')

			let cody = await fetchCody(encodeURIComponent(userNo))
			this.$route.query.user_no = cody.resultObject.codyNo
			const {
				user_no: codyNo,
				phone_no: codyPhoneNo,
				dept_cd: deptCd,
				type: type
			} = this.$route.query

			let codyQuery = {}
			if (codyNo) {
				codyQuery.codyNo = codyNo
			} else {
				codyQuery.codyNo = ''
			}

			if (codyPhoneNo) {
				codyQuery.codyPhoneNo = codyPhoneNo
			} else {
				codyQuery.codyPhoneNo = ''
			}

			if (type) {
				codyQuery.type = type
			} else {
				codyQuery.type = ''
			}

			if (deptCd) {
				codyQuery.deptCd = deptCd
			} else {
				codyQuery.deptCd = ''
			}

			this.$store.dispatch('common/setUserInfo', codyQuery)
		}

		sessionStorage.removeItem('customer')
		this.$store.dispatch('verify/setCustomer', {})
		this.type = this.$store.getters['common/type']

		if (this.funeralAccessableYn === 'Y') {
			let encUserId = ''
			let encCodyNo = ''
			const cowayLifeUserId = getCowayLifeSolutionUserId(this.type)
			if (cowayLifeUserId !== '') {
				const codyNo = this.$store.getters['common/codyNo']
				const resEnc = await fetchEncParams({
					userId: cowayLifeUserId,
					codyNo: codyNo
				})
				encUserId = resEnc.resultObject.userId
				encCodyNo = resEnc.resultObject.codyNo
			}
			const closeUrl = `${window.location.protocol}//${window.location.host}/order/coway-life-solution?type=close`
			this.cowayLifeLink = `${
				process.env.VUE_APP_API_WRMS
			}/DS/DS_WRMS_CUST_CERT.do?sellerNo=${encodeURIComponent(
				encCodyNo
			)}&usrId=${encodeURIComponent(encUserId)}&successUrl=${encodeURIComponent(
				closeUrl
			)}`
		}
	},

	methods: {
		chkDeptCd,
		getName,
		getPhone,

		async next() {
			const codyNo = this.$store.getters['common/codyNo']
			let custTypeSelected = this.custType === 1 ? 'P' : 'L'

			// 코디번호가 없으면 알림
			if (!codyNo || codyNo === '' || codyNo === null) {
				this.$alert({
					message:
						'판매인 정보가 확인되지 않습니다. <br />안드로이드 웹뷰 업데이트 후 이용해주세요. <br />자세한 방법은 지국 행정매니저에게 문의해주세요.'
				})
				return
			} else {
				this.nextPage(custTypeSelected)
			}
		},

		// 인증발송 메세지 발송 여부 팝업 노출
		async nextPage(custTypeSelected) {
			this.$store.dispatch('verify/setCustomer', { custTypeSelected })
			//코디 대행 잠정 숨김
			this.$store.dispatch('verify/setCustomerSelf', true)
			if (!this.$refs.selfForm.validate()) {
				return
			}
			const options = {
				title: '다음 고객에게 고객인증을 위한 메시지를 발송합니다.',
				guide: '고객정보를 다시 한 번 확인해주세요',
				contents: {
					고객구분: custTypeSelected === 'P' ? '개인' : '개인 사업자',
					고객명: this.getName(this.name),
					휴대폰번호: this.getPhone(this.phone)
				},
				okText: '발송',
				cancelText: '취소'
			}
			const res = await this.$refs['verify-self'].open(options)
			this.$nextTick(async () => {
				if (res) this.send(custTypeSelected)
			})
		},

		// 고객 인증 문자 발송 후 모바일 진행현황 이동
		send(custTypeSelected) {
			let params = {
				name: this.name,
				phoneNo: this.phone,
				custType: custTypeSelected,
				codyNo: this.$store.getters['common/codyNo'],
				codyPhoneNo: this.$store.getters['common/codyPhoneNo'],
				url: `${process.env.VUE_APP_FRT_URL}/order/verify/cust/self`,
				codyMatch: '',
				type: this.$store.getters['common/type'],
				app: this.$store.getters['common/app']
			}
			sendLmsLinkToCustForVerification(params).then(res => {
				const result = res.resultObject
				// 디버깅용
				this.$log(
					'url with userKey:',
					`${process.env.VUE_APP_FRT_URL}/order/verify/cust/self?userKey=${result.userKey}&id=${result.id}`
				)
				this.$router.push({
					name: 'order-status',
					query: {
						dept_cd: params.dept_cd,
						type: result.type
					}
				})
			})
		},

		// 애플리케이션 브릿지
		initAppPage() {
			registerCallbackToApp({})
		},
		windowOpen(url) {
			window.open(url, '_blank')
		}
	},

	watch: {
		custType() {
			if (
				this.custType !== undefined &&
				this.custType !== '' &&
				this.phone.length > 0 &&
				this.$refs.selfForm.validate()
			) {
				this.valid = true
			} else {
				this.valid = false
			}
		},
		name() {
			if (
				this.phone.length > 0 &&
				this.custType !== undefined &&
				this.custType !== '' &&
				this.$refs.selfForm.validate()
			) {
				this.valid = true
			} else {
				this.valid = false
			}
		},
		phone() {
			if (
				this.custType !== undefined &&
				this.custType !== '' &&
				this.$refs.selfForm.validate()
			) {
				this.valid = true
			} else {
				this.valid = false
			}
		}
	}
}
</script>

<style scoped lang="scss"></style>

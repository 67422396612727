<template>
	<div :class="['u-dialog-tabs', 'expand', addHeaderClass ? 'header' : '']">
		<v-tabs grow v-model="tabActiveView">
			<v-tab
				v-for="tab of tabMenuTitle"
				:key="tab.id"
				@click="handleStatusTabActive(tab.id)"
				class="overflow-x-auto"
				:style="tab.isShow ? '' : 'display: none'"
			>
				{{ tab.name }}
			</v-tab>
		</v-tabs>

		<v-tabs-items touchless v-model="tabActiveView">
			<!-- 소개 -->
			<v-tab-item>
				<div class="tab-inner">
					<div class="tab-contetns">
						<h3 class="order-title">상품 유형</h3>
						<div class="order-img">
							<img src="/img/img_life_product.png" alt="상품유형 이미지" />
						</div>
						<div class="u-btn-flexbox">
							<div class="bottom_box">
								<v-btn
									color="primary"
									class="col_1_small"
									@click.prevent="cowayLsWindowOpen('599')"
								>
									599 상세 확인<i class="ico"></i>
								</v-btn>
								<v-btn
									color="primary"
									class="col_1_small"
									@click.prevent="cowayLsWindowOpen('499')"
								>
									499 상세 확인<i class="ico"></i>
								</v-btn>
							</div>
						</div>
					</div>
					<div class="tab-contetns">
						<h3 class="order-title">주문 가능 유형</h3>
						<div class="order-img mtm-8">
							<img src="/img/img_life_order.png" alt="주문가능유형 이미지" />
						</div>
					</div>
					<div class="tab-contetns">
						<div class="bottom_box mt-8">
							<v-btn
								outlined
								class="col_1_small clear"
								@click="handleStatusTabActive(3)"
								>상조 주문 FAQ 확인
							</v-btn>
						</div>
						<ul class="flex-btns">
							<li>
								<button type="button" @click="handleStatusTabActive(1)">
									<strong>신규결합</strong>
									<p class="sub">주문방법</p>
									<i class="ico"></i>
								</button>
							</li>
							<li>
								<button type="button" @click="handleStatusTabActive(2)">
									<strong>기존결합</strong>
									<p class="sub">주문방법</p>
									<i class="ico"></i>
								</button>
							</li>
							<!-- <li>
								<button type="button" @click="handleStatusTabActive(3)">
									<strong>상조단독</strong>
									<p class="sub">주문방법</p>
									<i class="ico"></i>
								</button>
							</li> -->
						</ul>
					</div>
				</div>
			</v-tab-item>
			<!-- //소개 -->

			<!-- 신규결합 -->
			<v-tab-item>
				<div class="tab-inner">
					<div class="order-icons">
						<span class="img">
							<img src="/img/img_state_01.png" alt="신규" class="state" />
							<img src="/img/img_coway_ci.png" alt="iamges" />
						</span>
						<span class="plus">+</span>
						<span class="img">
							<img src="/img/img_coway_img.png" alt="iamges" />
						</span>
					</div>
					<div class="order-desc">코웨이 신규 + 상조 결합 방법</div>
					<div class="order-swiper">
						<v-carousel height="446" v-if="tabActiveView === 1">
							<v-carousel-item>
								<div class="swiper-title">
									<h4>STEP 1</h4>
									<strong>모바일 주문 화면 > 일반 주문하기 접속</strong>
									<p>※ 사전계약 주문은 상조 결합 불가</p>
								</div>
								<div class="swiper-image">
									<img
										src="/img/img_life_order_1_1.png"
										alt="신규결합 이미지1"
									/>
								</div>
							</v-carousel-item>
							<v-carousel-item>
								<div class="swiper-title">
									<h4>STEP 2</h4>
									<strong>
										신규 주문할 코웨이 상품 담은 후,
										<br />상품 추가하기 선택
									</strong>
								</div>
								<div class="swiper-image">
									<img
										src="/img/img_life_order_1_2.png"
										alt="신규결합 이미지2"
									/>
								</div>
							</v-carousel-item>
							<v-carousel-item>
								<div class="swiper-title">
									<h4>STEP 3</h4>
									<strong>
										주문할 상조 상품 검색 후
										<br />상품 선택
									</strong>
								</div>
								<div class="swiper-image">
									<img
										src="/img/img_life_order_1_3.png"
										alt="신규결합 이미지3"
									/>
								</div>
							</v-carousel-item>
							<v-carousel-item>
								<div class="swiper-title">
									<h4>STEP 4</h4>
									<strong class="underline">
										상조 상품 상세 > 제휴 결합 선택 >
										<br />
										<em>‘지금 함께 담은 코웨이 상품과 결합’</em>
										&nbsp;선택
									</strong>
								</div>
								<div class="swiper-image">
									<img
										src="/img/img_life_order_1_4.png"
										alt="신규결합 이미지4"
									/>
								</div>
							</v-carousel-item>
							<v-carousel-item>
								<div class="swiper-title">
									<h4>STEP 5</h4>
									<strong>
										주문 정보 입력 및 코웨이 계약서 발송
										<br />이후 ‘이어서 진행’ 선택
									</strong>
								</div>
								<div class="swiper-image">
									<img
										src="/img/img_life_order_1_5.png"
										alt="신규결합 이미지5"
									/>
								</div>
							</v-carousel-item>
							<v-carousel-item>
								<div class="swiper-title">
									<h4>STEP 6</h4>
									<strong>
										상조 주문 정보 (고객/상품/결제정보) <br />입력 후 주문접수
										진행
									</strong>
								</div>
								<div class="swiper-image">
									<img
										src="/img/img_life_order_1_6.png"
										alt="신규결합 이미지6"
									/>
								</div>
							</v-carousel-item>
							<v-carousel-item>
								<div class="swiper-title">
									<h4>STEP 7</h4>
									<strong>코웨이 신규 + 상조 결합 주문 완료!</strong>
									<p>※ 주문현황 각 탭에서 진행상태 확인 가능</p>
								</div>
								<div class="swiper-image">
									<img
										src="/img/img_life_order_1_7.png"
										alt="신규결합 이미지7"
									/>
								</div>
							</v-carousel-item>
						</v-carousel>
					</div>
				</div>
			</v-tab-item>
			<!-- //신규결합 -->

			<!-- 기존결합 -->
			<v-tab-item>
				<div class="tab-inner">
					<div class="order-icons">
						<span class="img">
							<img src="/img/img_state_02.png" alt="기존" class="state" />
							<img src="/img/img_coway_ci.png" alt="iamges" />
						</span>
						<span class="plus">+</span>
						<span class="img">
							<img src="/img/img_coway_img.png" alt="iamges" />
						</span>
					</div>
					<div class="order-desc">코웨이 기존계정 + 상조 결합 방법</div>
					<div class="order-swiper">
						<v-carousel height="446" v-if="tabActiveView === 2">
							<v-carousel-item>
								<div class="swiper-title">
									<h4>STEP 1</h4>
									<strong>모바일 주문 화면 > 일반 주문하기 접속</strong>
									<p></p>
								</div>
								<div class="swiper-image">
									<img
										src="/img/img_life_order_1_1.png"
										alt="기존결합 이미지1"
									/>
								</div>
							</v-carousel-item>
							<v-carousel-item>
								<div class="swiper-title">
									<h4>STEP 2</h4>
									<strong>
										주문할 상조 상품 검색 후
										<br />상품 선택
									</strong>
								</div>
								<div class="swiper-image">
									<img
										src="/img/img_life_order_2_2.png"
										alt="기존결합 이미지2"
									/>
								</div>
							</v-carousel-item>
							<v-carousel-item>
								<div class="swiper-title">
									<h4>STEP 3</h4>
									<strong class="underline">
										상조 상품 상세 > 제휴 결합 선택 >
										<br />
										<em>‘현재 사용 중인 기존계정과 결합’</em>
										&nbsp;선택
									</strong>
								</div>
								<div class="swiper-image">
									<img
										src="/img/img_life_order_2_3.png"
										alt="기존결합 이미지3"
									/>
								</div>
							</v-carousel-item>
							<v-carousel-item>
								<div class="swiper-title">
									<h4>STEP 4</h4>
									<strong>
										결합할 기존 계정 선택
									</strong>
									<p>※ 계정 당 상조 상품 최대 3개 결합 가능</p>
								</div>
								<div class="swiper-image">
									<img
										src="/img/img_life_order_2_4.png"
										alt="기존결합 이미지4"
									/>
								</div>
							</v-carousel-item>
							<v-carousel-item>
								<div class="swiper-title">
									<h4>STEP 5</h4>
									<strong>
										상조 주문 정보 (고객/상품/결제정보)<br />입력 후 주문접수
										진행
									</strong>
								</div>
								<div class="swiper-image">
									<img
										src="/img/img_life_order_2_5.png"
										alt="기존결합 이미지5"
									/>
								</div>
							</v-carousel-item>
							<v-carousel-item>
								<div class="swiper-title">
									<h4>STEP 6</h4>
									<strong>
										코웨이 기존 + 상조 상품 결합 주문 완료!
									</strong>
									<p>※ 주문현황 ‘상조’ 탭에서 진행상태 확인 가능</p>
								</div>
								<div class="swiper-image">
									<img
										src="/img/img_life_order_2_6.png"
										alt="기존결합 이미지6"
									/>
								</div>
							</v-carousel-item>
						</v-carousel>
					</div>
				</div>
			</v-tab-item>
			<!-- //기존결합 -->

			<!-- 상조단독 -->
			<!-- <v-tab-item>
				<div class="tab-inner">
					<div class="order-icons">
						<span class="img">
							<img src="/img/img_coway_img.png" alt="iamges" />
						</span>
					</div>
					<div class="order-desc">상조 상품 단독 주문 방법</div>
					<div class="order-swiper">
						<v-carousel height="446" v-if="tabActiveView === 3">
							<v-carousel-item>
								<div class="swiper-title">
									<h4>STEP 1</h4>
									<strong>
										모바일 주문 화면 > 화면 하단
										<br />‘상조(단독) 주문하기’선택
									</strong>
								</div>
								<div class="swiper-image">
									<img
										src="/img/img_life_order_3_1.png"
										alt="상조단독 이미지1"
									/>
								</div>
							</v-carousel-item>
							<v-carousel-item>
								<div class="swiper-title">
									<h4>STEP 2</h4>
									<strong class="underline">
										주문할 상조 상품 선택
										<br />
										<p>
											※ 단독 주문 시,
											<em>라이프온리 499 상품만 가능</em>
										</p>
									</strong>
								</div>
								<div class="swiper-image">
									<img
										src="/img/img_life_order_3_2.png"
										alt="상조단독 이미지2"
									/>
								</div>
							</v-carousel-item>
							<v-carousel-item>
								<div class="swiper-title">
									<h4>STEP 3</h4>
									<strong>
										상조 상품 주문 정보 (고객/상품/결제 정보) <br />입력 후
										주문접수 진행
									</strong>
								</div>
								<div class="swiper-image">
									<img
										src="/img/img_life_order_3_3.png"
										alt="상조단독 이미지3"
									/>
								</div>
							</v-carousel-item>
							<v-carousel-item>
								<div class="swiper-title">
									<h4>STEP 4</h4>
									<strong>
										상조 상품 단독 주문 완료
									</strong>
									<p>※ 주문현황 ‘상조’ 탭에서 진행상태 확인 가능</p>
								</div>
								<div class="swiper-image">
									<img
										src="/img/img_life_order_3_4.png"
										alt="상조단독 이미지4"
									/>
								</div>
							</v-carousel-item>
						</v-carousel>
					</div>
				</div>
			</v-tab-item> -->
			<!-- //상조단독 -->

			<!-- FAQ -->
			<v-tab-item>
				<div class="tab-inner">
					<div class="order-icons">
						<span class="img">
							<img src="/img/ico_faq_01.png" alt="iamges" />
						</span>
					</div>
					<div class="order-desc bld">FAQ</div>
					<div class="order-faq">
						<v-expansion-panels multiple v-model="openedPanel">
							<v-expansion-panel>
								<v-expansion-panel-header>
									<h4>상조 상품 가입 가능한 고객 조건이 있나요?</h4>
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									상조 상품 가입은 개인고객만 가능합니다.
									<br />1. 미성년자 불가 <br />2. 외국인/법인 불가
								</v-expansion-panel-content>
							</v-expansion-panel>

							<v-expansion-panel>
								<v-expansion-panel-header>
									<h4>주문 시, 상품 종류와 수량에 제한이 있나요?</h4>
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									결합 주문 가능한 상품 종류 및 수량 제한은 다음과 같습니다.<br />-
									코웨이라이프 499, 코웨이라이프 599 주문 가능 <br />-계정당
									상조 상품 최대 3구좌 결합 가능 <br />- 단, 한 번 결합 완료 된
									계정은 결합된 상조 상품 수량 상관 없이 이후 추가 결합 불가
								</v-expansion-panel-content>
							</v-expansion-panel>

							<v-expansion-panel>
								<v-expansion-panel-header>
									<h4>상조 결합 주문 시, 제한 조건이 있나요?</h4>
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									상조 결합 주문 시 제한 조건은 다음과 같습니다.
									<br />1. 기존 결합 시, 일시불 계정은 상조 결합 불가 (신규
									주문하는 코웨이 일시불 계정과는 가능) <br />2. 리퍼브, 멤버십,
									직원산매, 전환판매, 온라인 전용, 단체 주문 계정 결합 불가
								</v-expansion-panel-content>
							</v-expansion-panel>

							<v-expansion-panel>
								<v-expansion-panel-header>
									<h4>상조 결합 시 할인 혜택은 어떻게 되나요?</h4>
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									상조 결합 시 할인 혜택은 다음과 같습니다.
									<br />1. 코웨이라이프 499, 라이프온리 499 <br />- 월
									13,300원/60개월 할인 (코웨이 렌탈료에서 할인) <br />2.
									코웨이라이프 599 상품 <br />- 월 16,600원/60개월 할인 (코웨이
									렌탈료에서 할인)
									<br />
									<br />※ 단, 일시불 구매 계정과 결합 시 <br />- 코웨이 렌탈료가
									아닌 상조 월 납입금에서 동일한 금액만큼 할인
									<br />
									<br />※ 결합 혜택 지원금이 렌탈료보다 클 경우, 상조 결합혜택
									지원 기간(60회차) 종료 익월에 고객에게 현금으로 지원
								</v-expansion-panel-content>
							</v-expansion-panel>

							<v-expansion-panel>
								<v-expansion-panel-header>
									<h4>사전계약 시 상조 결합 주문이 가능한가요?</h4>
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									불가능합니다. 해당 사전계약의 순주문이 확정된 이후 '기존 결합
									주문'으로 진행해야 합니다.
								</v-expansion-panel-content>
							</v-expansion-panel>
							<v-expansion-panel>
								<v-expansion-panel-header>
									<h4>상조 결합 주문 시 배정은 어떻게 되나요?</h4>
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									코웨이 계약 서명 완료 + 상조 계약 서명/1회차 납부 완료 시 배정
									요청이 가능합니다.
									<br />
									<br />단, 주문 접수일로부터 30일 이내 코웨이 제품 미설치 시
									결합된 상조 주문은 자동 취소됩니다. (코웨이 주문은 유지)
								</v-expansion-panel-content>
							</v-expansion-panel>
							<v-expansion-panel>
								<v-expansion-panel-header>
									<h4>상조 결합 주문이 자동 취소됐어요.</h4>
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									상조 결합 주문 자동 취소 사유는 다음과 같습니다.
									<br />1. 코웨이 30일 이내 미설치 <br />- 상조 주문 자동취소
									<br />- 결합 주문인 경우, 코웨이 주문은 유지
									<br />
									<br />2. 상조 서명/납부 5일 이내 미완료 <br />- 상조 주문
									자동취소 <br />- 결합 주문인 경우, 코웨이 주문은 유지
									<br />
									<br />3. 결합한 코웨이 계정 삭제/해약 <br />- 결합된 상조
									주문도 자동 취소
								</v-expansion-panel-content>
							</v-expansion-panel>
							<v-expansion-panel>
								<v-expansion-panel-header>
									<h4>
										고객님이 상조 주문 서명까지 완료했는데, 순주문 처리가 안되고
										있어요.
									</h4>
								</v-expansion-panel-header>
								<v-expansion-panel-content>
									아래 두 가지 경우를 확인해주세요.
									<br />
									<br />1. 상조 1회차 금액 납부 미진행 <br />- 고객 서명
									완료했으나, 1회차 수납금 미납부한 경우
									<br />
									<br />2. 코웨이 제품 미설치 <br />- 코웨이 제품 설치
									완료(순주문 확정)되어야 상조 순주문도 확정
								</v-expansion-panel-content>
							</v-expansion-panel>
						</v-expansion-panels>
					</div>
				</div>
			</v-tab-item>
			<!-- //FAQ -->
		</v-tabs-items>
	</div>
</template>

<script>
export default {
	name: 'CowayLifeSolutionGuideTab',
	props: {
		tabId: { type: Number, default: 0 },
		pageFlag: { type: String, default: 'dialog' }
	},
	data() {
		return {
			tabMenuTitle: [
				{ id: 0, name: '소개', isShow: true },
				{ id: 1, name: '신규결합', isShow: true },
				{ id: 2, name: '기존결합', isShow: true },
				// { id: 3, name: '상조단독', isShow: true },
				{ id: 3, name: 'FAQ', isShow: true }
			],
			tabActiveView: 0,
			redirectUrl: '',
			openedPanel: [],
			addHeaderClass: false
		}
	},
	watch: {
		tabId() {
			this.handleStatusTabActive(this.tabId)
		}
	},
	mounted() {
		this.$log('route', this.$route)
		this.$log(document.getElementsByClassName('digital-sales-header'))
		if (
			document.getElementsByClassName('digital-sales-header')[0]?.style
				?.display === 'none' &&
			this.pageFlag === 'page'
		) {
			this.addHeaderClass = true
		} else {
			this.addHeaderClass = false
		}

		this.handleStatusTabActive(this.tabId)
	},
	methods: {
		handleStatusTabActive(tabId) {
			this.tabActiveView = tabId
			if (tabId === 3) {
				this.openedPanel = []
			}
			let title = '주문 가이드'
			this.$emit('titleChange', title, tabId)
		},
		cowayLsWindowOpen(flag) {
			this.$log('window.navigator.userAgent', window.navigator.userAgent)
			const agent = window.navigator.userAgent
			let uri = '/service/'
			if (flag === '499') {
				uri += process.env.VUE_APP_DEV_MODE === 'production' ? '2' : '1'
			} else if (flag === '599') {
				uri += process.env.VUE_APP_DEV_MODE === 'production' ? '3' : '2'
			}

			const callUrl = `${process.env.VUE_APP_FRT_WRMS}${uri}`
			if (agent.includes('iPhone', 'iPad')) {
				const param = {
					functionName: 'openInAppBrowser',
					openFrom: '4',
					url: callUrl
				}
				window.webkit.messageHandlers.cowayNativeCall.postMessage(param)
			} else if (agent.includes('Android')) {
				window.coway.openInAppBrowser('4', callUrl)
			} else {
				window.open(callUrl, '_blank')
			}
		}
	}
}
</script>

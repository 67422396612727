<template>
	<v-dialog v-model="dialog" width="500">
		<v-card v-if="prd.webProd === 'Y'">
			<img
				class="arrow_zoom"
				@click="dialog = false"
				:src="`${prd.imagePath}`"
				:alt="prd.prodNm"
			/>
		</v-card>
		<v-card v-else>
			<img
				class="arrow_zoom"
				@click="dialog = false"
				:src="`${prd.color_file_path}`"
				:alt="prd.leader_model_nm"
			/>
		</v-card>
	</v-dialog>
</template>
<script>
/**
 * @descriptdion 이미지 상세보기 모달
 */
import computeds from '@/mixins/computeds'
import { isProduction } from '@/utils'
export default {
	mixins: [computeds],
	data: () => ({
		dialog: false,
		prd: {},
		isProduction: isProduction()
	}),
	methods: {
		open(prd) {
			this.dialog = true
			this.prd = prd
		}
	}
}
</script>

<template>
	<div></div>
</template>

<script>
import { passInfoEncrypt } from '@/apis/order.status.api'
import { fetchEncParams } from '@/apis/coway.life.api'
export default {
	name: 'CowayLifeSolution',
	data() {
		return {
			cowayLifeSolutionItem: JSON.parse(
				sessionStorage.getItem('cowayLifeSolution')
			),
			mobNo: '',
			userId: '',
			encUserId: '',
			funeralProductsParam: {},
			openerMessageSendChk: false
		}
	},
	async mounted() {
		if (!this.cowayLifeSolutionItem) {
			window.close()
			return
		}
		this.mobNo = this.cowayLifeSolutionItem.mobOrderNo || ''
		this.userId = this.cowayLifeSolutionItem.userId || ''

		if (this.userId !== '') {
			const encUserId = await fetchEncParams({
				userId: this.userId
			})
			this.encUserId = encUserId.resultObject.userId
		}

		this.fullPathCompare(this.$route.query)
		const retUrl = `${window.location.protocol}//${window.location.host}/order/coway-life-solution?mobNo=${this.mobNo}`
		const closeUrl = `${window.location.protocol}//${window.location.host}/order/coway-life-solution?type=close`
		window.addEventListener('message', async event => {
			// 상조 결합
			if (event?.data?.funeralProductsParam) {
				this.funeralProductsParam = event.data.funeralProductsParam
				const res = await passInfoEncrypt(this.mobNo)
				const cowayLifeSolutionInfo = res.resultObject

				const body = document.querySelector('body')
				body.insertAdjacentHTML(
					'beforeend',
					`<form id="frm" name="frm"
								action="${process.env.VUE_APP_API_WRMS}/DS/DS_WRMS_ORD_REG.do"
								method="post" target="_self">
								<input type="hidden" id="sellerNo" name="sellerNo" value="${cowayLifeSolutionInfo.codyNo}" />
								<input type="hidden" id="ordChnCd" name="ordChnCd" value="CM" />
								<input type="hidden" id="webOrdNo" name="webOrdNo" value="${cowayLifeSolutionInfo.webOrderNo}" />
								<input type="hidden" id="mobNo" name="mobNo" value="${cowayLifeSolutionInfo.phoneNo}" />
								<input type="hidden" id="successUrl" name="successUrl" value="${retUrl}" />
								<input type="hidden" id="ci" name="ci" value="${cowayLifeSolutionInfo.ci}" />
								<input type="hidden" id="cond" name="cond">
								<input type="hidden" id="payInfo" name="payInfo">
								<input type="hidden" id="rcivInfo" name="rcivInfo">
								<input type="hidden" id="usrId" name="usrId" value="${this.encUserId}" />
						</form>`
				)
				document.getElementById('cond').value = JSON.stringify(
					this.funeralProductsParam.cond
				)
				if (
					this.funeralProductsParam.payInfo &&
					this.funeralProductsParam.payInfo.fdrmSetlMthCd
				) {
					this.funeralProductsParam.payInfo.mobNo =
						cowayLifeSolutionInfo.phoneNo

					const encCardNo = await fetchEncParams({
						cardBankNo: this.funeralProductsParam.payInfo.cardBankNo
					})
					this.funeralProductsParam.payInfo.cardBankNo =
						encCardNo.resultObject.cardBankNo

					document.getElementById('payInfo').value = JSON.stringify(
						this.funeralProductsParam.payInfo
					)
				}
				if (
					this.funeralProductsParam.rcivInfo &&
					this.funeralProductsParam.rcivInfo.fdrmSetlMthCd
				) {
					this.funeralProductsParam.rcivInfo.mobNo =
						cowayLifeSolutionInfo.phoneNo

					const encCardNo = await fetchEncParams({
						cardNo: this.funeralProductsParam.rcivInfo.cardNo
					})
					this.funeralProductsParam.rcivInfo.cardNo =
						encCardNo.resultObject.cardNo

					document.getElementById('rcivInfo').value = JSON.stringify(
						this.funeralProductsParam.rcivInfo
					)
				}
				document.getElementById('frm').submit()
			}
			// 상조 주문상세
			else if (event?.data?.funeralDetail) {
				const res = await passInfoEncrypt(this.mobNo)
				const successUrl = event.data.funeralDetail.closeUpdate
					? retUrl
					: closeUrl
				const cowayLifeSolutionInfo = res.resultObject
				const body = document.querySelector('body')
				body.insertAdjacentHTML(
					'beforeend',
					`<form id="frm" name="frm"
								action="${process.env.VUE_APP_API_WRMS}/DS/DS_WRMS_ORD_DTL_LIST.do"
								method="get" target="_self">
								<input type="hidden" id="successUrl" name="successUrl" value="${successUrl}" />
								<input type="hidden" id="sellerNo" name="sellerNo" value="${cowayLifeSolutionInfo.codyNo}" />
								<input type="hidden" id="webOrdNo" name="webOrdNo" value="${cowayLifeSolutionInfo.mobOrderNo}" />
								<input type="hidden" id="usrId" name="usrId" value="${this.encUserId}" />
						</form>`
				)
				document.getElementById('frm').submit()
			}
			// 상조 단품 (임시주문번호)
			else if (event?.data?.funeralOnlyOrderParam) {
				const funeralOnlyOrderParam = event.data.funeralOnlyOrderParam
				const encParam = await fetchEncParams({
					encSallerNo: funeralOnlyOrderParam.sellerNo
				})
				funeralOnlyOrderParam.encSallerNo = encParam.resultObject.encSallerNo
				const body = document.querySelector('body')
				body.insertAdjacentHTML(
					'beforeend',
					`<form id="frm" name="frm"
								action="${process.env.VUE_APP_API_WRMS}/DS/DS_WRMS_ORD_REG.do"
								method="post" target="_self">
								<input type="hidden" id="successUrl" name="successUrl" value="${retUrl}" />
								<input type="hidden" id="sellerNo" name="sellerNo" value="${funeralOnlyOrderParam.encSallerNo}" />
								<input type="hidden" id="ordChnCd" name="ordChnCd" value="CM" />
								<input type="hidden" id="tmpOrdNo" name="tmpOrdNo" value="${funeralOnlyOrderParam.tmpOrdNo}" />
								<input type="hidden" id="usrId" name="usrId" value="${this.encUserId}" />
						</form>`
				)
				document.getElementById('frm').submit()
			}
			// 상조 단독 주문상세
			else if (event?.data?.funeralOnlyOrderDetailParam) {
				const funeralOnlyOrderDetailParam =
					event.data.funeralOnlyOrderDetailParam
				const encParam = await fetchEncParams({
					encSallerNo: funeralOnlyOrderDetailParam.sellerNo,
					encOrdNo: funeralOnlyOrderDetailParam.ordNo
				})
				funeralOnlyOrderDetailParam.encSallerNo =
					encParam.resultObject.encSallerNo
				funeralOnlyOrderDetailParam.encOrdNo = encParam.resultObject.encOrdNo
				const body = document.querySelector('body')
				body.insertAdjacentHTML(
					'beforeend',
					`<form id="frm" name="frm"
								action="${process.env.VUE_APP_API_WRMS}/DS/DS_WRMS_ORD_DTL_LIST.do"
								method="get" target="_self">
								<input type="hidden" id="successUrl" name="successUrl" value="${retUrl}" />
								<input type="hidden" id="sellerNo" name="sellerNo" value="${funeralOnlyOrderDetailParam.encSallerNo}" />
								<input type="hidden" id="ordNo" name="ordNo" value="${funeralOnlyOrderDetailParam.encOrdNo}" />
								<input type="hidden" id="usrId" name="usrId" value="${this.encUserId}" />
						</form>`
				)
				document.getElementById('frm').submit()
			}
		})
		if (!this.openerMessageSendChk) {
			window.opener?.postMessage('cowayLifePopLoad', '*')
			this.openerMessageSendChk = true
		}
	},
	methods: {
		async fullPathCompare(query) {
			if (query.mobNo) {
				const params = {
					mobNo: query.mobNo
				}

				if (window.opener && window.opener.$updateCowayLifeSolution) {
					window.opener.$updateCowayLifeSolution(params)
				}
				window.close()
			} else if (query.type === 'close') {
				window.close()
			}
		}
	}
}
</script>

<style scoped lang="scss"></style>

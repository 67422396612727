<template>
	<div class="cw-app-wrapper">
		<CowayLifeSolutionGuideTab
			:tabId="tabId"
			:pageFlag="`page`"
			@titleChange="titleChange"
		></CowayLifeSolutionGuideTab>
	</div>
</template>

<script>
/**
 * 코웨이 라이프 솔루션 가이드 페이지
 */
import { registerCallbackToApp } from '@/utils/navigation'
import CowayLifeSolutionGuideTab from '@/views/common/CowayLifeSolutionGuideTab'

export default {
	name: 'CowayLifeSolutionGuide',
	components: {
		CowayLifeSolutionGuideTab
	},
	data() {
		return {
			tabId: 0
		}
	},
	created() {
		this.initAppPage()
	},
	mounted() {
		if (this.$route.query.tabId && !isNaN(Number(this.$route.query.tabId))) {
			this.tabId = Number(this.$route.query.tabId)
		}
	},
	methods: {
		initAppPage(param) {
			const title = param ? { title: param } : {}
			registerCallbackToApp(title)
		},
		titleChange(paramTitle) {
			document.querySelector('html').scrollTo(0, 0)
			registerCallbackToApp({ title: paramTitle })
		}
	}
}
</script>

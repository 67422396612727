/**
 * 코웨이라이프(상조) API
 */

import api from '@/apis/config'
const { get, put, post } = api

/**
 * 코웨이라이프(상조) 주문 삭제
 */
export const deleteCowayLifeOrderInfo = async mobOrderNo => {
	const response = await put(`/v1/12/01/${mobOrderNo}`)
	return response
}

/**
 * 상조 단독 주문 상태 조회
 * I_SALES_CD: 판매인번호
 */
export const fetchAidOnlyList = async params => {
	const response = await post('/v1/12/02', {
		...params
	})
	return response
}

/**
 * 기존 계정 상조 결합 리스트
 * I_CONTRACT_CUST_ID:계약처고객번호 or I_ORDER_NO: 고객주문번호
 */
export const fetchAidPreOrdNumList = async params => {
	const response = await post('/v1/12/03', {
		...params
	})
	return response
}

/**
 * 기존 계정 상조 결합 가능 여부 확인
 * I_ORDER_NO: 고객주문번호, I_STEP: 'A' 기존주문 'B' 신규주문
 */
export const fetchAidOrderCheck = async params => {
	const response = await post('/v1/12/04', {
		...params
	})
	return response
}

/**
 * 신규 상품 상조 결합 가능 여부
 * I_KUNNR: 고객 번호, I_SALE_CD :판매인사번, IT_PROD 구조체
 */
export const fetchProdListCheck = async params => {
	const response = await post('/v1/12/05', {
		...params
	})
	return response
}

// SAP주문 번호를 통한 상조 주문 정보 조회
export const fetchAidOrderForOrderNo = async params => {
	const response = await post('/v1/12/06', {
		...params
	})
	return response
}

// 파라미터 암호화
export const fetchEncParams = async params => {
	const response = await post('/v1/12/07', {
		...params
	})
	return response
}

/**
 * 상조 단독 주문 리스트 조회
 */
export const fetchWrmsOnlyOrderList = async userNo => {
	const response = await get(`/v1/12/08/${userNo}`)
	return response
}

/**
 * ACUPI 상조 동의 내용 업데이트
 */
export const updateAcupiFuneralAgree = async mobOrderNo => {
	const response = await get(`v1/12/09/${mobOrderNo}`)
	return response
}

<template>
	<v-dialog v-model="dialog" fullscreen>
		<div class="u-dialog-wrap">
			<div class="u-dialog-header">
				<h2 class="heading">{{ getPrdName(pay.esProd.PRODH) }}</h2>
				<v-btn depressed icon plain class="btn-close" @click="close"
					><v-icon dark>
						mdi-window-close
					</v-icon></v-btn
				>
			</div>

			<div class="u-dialog-container expand">
				<v-form ref="instEnvInfoForm" @submit.prevent="saveEnvInfo">
					<!-- 정수기 -->
					<template v-if="isWaterPurifier(pay.options.prdha)">
						<section class="u-select-group">
							<article class="u-select-block">
								<div class="type-button">
									<h3 class="label">타사제품 반환여부</h3>
									<v-btn-toggle
										class="flex-start"
										v-model="returnOtherSelected"
									>
										<v-btn
											text
											v-for="(item, i) in returnOtherItems"
											:key="i"
											:disabled="defaultReturnBtn && item.code === 'N'"
										>
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>

							<article
								class="u-select-block"
								v-if="['Y', 'Z'].includes(returnOtherPromotionChk())"
							>
								<div class="type-dropdown">
									<h3 class="label">타사제품정보</h3>
									<dl class="u-grid-sheet">
										<dt>
											<h4 class="label sm">제조사</h4>
										</dt>
										<dd>
											<div class="pd-0 col-12">
												<MSelectBottom
													class="mt-2"
													title="제조사 선택"
													v-model="manuFacCode"
													:items="getIndemnifyData('6100')"
													:onceNo="manuFacCode"
													@item-selected="handleManuFacSelected"
												>
													<template v-if="manuFac === undefined">
														<span class="btn_tit">제조사 선택</span>
														<span class="btn_value"></span>
													</template>
													<template v-else>
														<span class="btn_value expand">
															<b class="primary--text">{{
																manuFacDisplayText
															}}</b>
														</span>
													</template>
												</MSelectBottom>
											</div>
										</dd>
									</dl>
									<dl class="u-grid-sheet">
										<dt>
											<h4 class="label sm">설치/제조 년월</h4>
										</dt>
										<dd>
											<div class="pd-0 col-7 grid">
												<MSelectBottom
													title="설치 / 제조 년 선택"
													v-model="makeDateYearCode"
													:disabled="disabled"
													:onceNo="makeDateYearCode"
													:items="makeDateYearItems"
													@item-selected="handleItemMakeYearSelected"
													class="mt-2"
												>
													<template v-if="makeDateYear === undefined">
														<span class="btn_tit">년도</span>
														<span class="btn_value"></span>
													</template>
													<template v-else>
														<span class="btn_value expand">
															<b class="primary--text">{{
																makeDateYearDisplayText
															}}</b>
														</span>
													</template>
												</MSelectBottom>
											</div>
											<div class="pd-0 col-5 grid ml-1">
												<MSelectGridBottom
													title="설치 / 제조 월 선택"
													v-model="makeDateMonthCode"
													:items="makeDateMonthItems"
													:disabled="
														makeDateYearCode !== undefined &&
															makeDateYearCode === '6'
													"
													:selectedYear="makeDateYearDisplayText"
													@item-selected="handleItemMakeMonthSelected"
													class="mt-2"
												>
													<template v-if="makeDateMonth === undefined">
														<span class="btn_tit">월</span>
														<span class="btn_value"></span>
													</template>
													<template v-else>
														<span class="btn_value expand">
															<b class="primary--text">{{
																makeDateMonthDisplayText
															}}</b>
														</span>
													</template>
												</MSelectGridBottom>
											</div>
										</dd>
									</dl>
									<dl class="u-grid-sheet">
										<dt>
											<h4 class="label sm">제품형태</h4>
										</dt>
										<dd>
											<div class="pd-0 col-12">
												<MSelectBottom
													class="mt-2"
													title="제품형태 선택"
													v-model="prdFormCode"
													:items="getIndemnifyData('6102')"
													:onceNo="prdFormCode"
													@item-selected="handleprdFormSelected"
												>
													<template v-if="prdForm === undefined">
														<span class="btn_tit">제품형태 선택</span>
														<span class="btn_value"></span>
													</template>
													<template v-else>
														<span class="btn_value expand">
															<b class="primary--text">{{
																prdFormDisplayText
															}}</b>
														</span>
													</template>
												</MSelectBottom>
											</div>
										</dd>
									</dl>
									<dl class="u-grid-sheet">
										<dt>
											<h4 class="label sm">제품종류</h4>
										</dt>
										<dd>
											<div class="pd-0 col-12">
												<MSelectBottom
													class="mt-2"
													title="제품종류 선택"
													v-model="prdTypeCode"
													:items="getIndemnifyData('6103')"
													:onceNo="prdTypeCode"
													@item-selected="handleprdTypeSelected"
												>
													<template v-if="prdType === undefined">
														<span class="btn_tit">제품종류 선택</span>
														<span class="btn_value"></span>
													</template>
													<template v-else>
														<span class="btn_value expand">
															<b class="primary--text">{{
																prdTypeDisplayText
															}}</b>
														</span>
													</template>
												</MSelectBottom>
											</div>
										</dd>
									</dl>
									<dl class="u-grid-sheet">
										<dt>
											<h4 class="label sm">설치형태</h4>
										</dt>
										<dd>
											<div class="pd-0 col-12">
												<MSelectBottom
													class="mt-2"
													title="설치형태 선택"
													v-model="instFormCode"
													:items="getIndemnifyData('6104')"
													:onceNo="instFormCode"
													@item-selected="handleinstFormSelected"
												>
													<template v-if="instForm === undefined">
														<span class="btn_tit">설치형태 선택</span>
														<span class="btn_value"></span>
													</template>
													<template v-else>
														<span class="btn_value expand">
															<b class="primary--text">{{
																instFormDisplayText
															}}</b>
														</span>
													</template>
												</MSelectBottom>
											</div>
										</dd>
									</dl>
									<dl class="u-grid-sheet" v-if="waterMark">
										<dt>
											<h4 class="label sm">물마크 번호</h4>
										</dt>
										<dd>
											<div class="pd-0 col-12">
												<v-text-field
													dense
													outlined
													ref="waterMarkNumber"
													class="mt-2"
													inputmode="numeric"
													maxlength="8"
													minlength="8"
													v-mask="'########'"
													v-model="number"
													:rules="rules.number"
													placeholder="물마크 번호를 입력해 주세요."
												/>
											</div>
										</dd>
									</dl>
								</div>
							</article>

							<article class="u-select-block">
								<div class="type-dropdown">
									<h3 class="label">다중시설</h3>
									<MSelectBottom
										class="btn_row"
										title="다중시설 선택"
										v-model="multipleFacCode"
										:items="multipleFacItems"
										:onceNo="multipleFacCode"
										@item-selected="handleMultipleFacSelected"
									>
										<template v-if="multipleFac === undefined">
											<span class="btn_tit">다중시설 선택</span>
											<span class="btn_value"></span>
										</template>
										<template v-else>
											<span class="btn_tit">다중시설</span>
											<span class="btn_value primary--text">
												<b>{{ multipleFacDisplayText }}</b>
											</span>
										</template>
									</MSelectBottom>
								</div>
							</article>
							<article class="u-select-block">
								<div class="type-button">
									<h3 class="label">수질</h3>
									<v-btn-toggle
										class="flex-start"
										v-model="waterQualitySelected"
									>
										<v-btn text v-for="(item, i) in waterQualityItems" :key="i">
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>
							<article class="u-select-block">
								<div class="type-button">
									<h3 class="label">수도와의 거리</h3>
									<v-btn-toggle
										class="flex-start"
										v-model="waterDistanceSelected"
									>
										<v-btn
											text
											v-for="(item, i) in waterDistanceItems"
											:key="i"
										>
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>
							<article
								class="u-select-block"
								v-if="
									['112653'].includes(pay.esProd.GOODS_CD.replace(/(^0+)/, ''))
								"
							>
								<div class="type-button">
									<h3 class="label">커피머신 연결 선택 여부</h3>
									<v-btn-toggle
										class="flex-start"
										v-model="machineConnectSelected"
									>
										<v-btn
											text
											v-for="(item, i) in machineConnectItems"
											:key="i"
										>
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>
							<article
								class="u-select-block"
								v-if="
									builtinGoodsCd.includes(
										pay.esProd.GOODS_CD.replace(/(^0+)/, '')
									)
								"
							>
								<div class="type-button">
									<h3 class="label">빌트인 제품 설치전 고객 필수 동의 여부</h3>
									<p class="dot">
										<span
											>임의로 응답할 경우 제품 설치가 지연되거나 취소될 수
											있습니다.</span
										>
									</p>
								</div>
							</article>
							<article
								class="u-select-block"
								v-if="
									builtinGoodsCd.includes(
										pay.esProd.GOODS_CD.replace(/(^0+)/, '')
									)
								"
							>
								<div class="type-button">
									<h3 class="label light">
										설치 시 500원 동전 크기의 타공 진행에 대한 동의
									</h3>
									<v-btn-toggle
										tile
										class="flex-start"
										v-model="thinkXSelected"
									>
										<v-btn text v-for="(item, i) in thinkXItems" :key="i">{{
											item.text
										}}</v-btn>
									</v-btn-toggle>
								</div>
							</article>

							<article
								class="u-select-block"
								v-if="
									builtinGoodsCd.includes(
										pay.esProd.GOODS_CD.replace(/(^0+)/, '')
									)
								"
							>
								<div class="type-button">
									<h3 class="label light">
										싱크대 하부장 공간 미확보 시, 설치 불가에 대한 동의
									</h3>
									<v-btn-toggle
										tile
										class="flex-start"
										v-model="think2XSelected"
									>
										<v-btn text v-for="(item, i) in think2XItems" :key="i">{{
											item.text
										}}</v-btn>
									</v-btn-toggle>
								</div>
							</article>
						</section>
					</template>
					<!-- 공기청정기 -->
					<!-- 무릎사우나기 -->
					<template
						v-else-if="
							isAirPurifier(pay.options.prdha) || isKnee(pay.options.prdha)
						"
					>
						<section class="u-select-group">
							<article class="u-select-block">
								<div class="type-button">
									<h3 class="label">타사제품 반환여부</h3>
									<v-btn-toggle
										class="flex-start"
										v-model="returnOtherSelected"
									>
										<v-btn
											text
											v-for="(item, i) in returnOtherItems"
											:key="i"
											:disabled="defaultReturnBtn && item.code === 'N'"
										>
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>
							<article
								class="u-select-block"
								v-if="
									installTypePrdOrderTypeChk(pay) &&
										instType &&
										instType === 'X' &&
										returnOther &&
										returnOther.code === 'N' &&
										!['Y', 'Z'].includes(returnOtherPromotionChk())
								"
							>
								<div class="type-button">
									<h3 class="label">
										설치방식
										<v-btn icon class="mark" @click="popInstallShow = true">
										</v-btn>
									</h3>
									<v-btn-toggle
										class="flex-start"
										v-model="installTypeSelected"
									>
										<v-btn text v-for="(item, i) in installTypeItems" :key="i">
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
									<v-dialog width="312" v-model="popInstallShow">
										<template v-slot:activator="{ on, attrs }">
											<v-btn icon v-bind="attrs" v-on="on">
												<em class="ir">팝업 열기</em>
											</v-btn>
										</template>
										<section class="u-confirm-wrap">
											<article class="panels">
												<v-card-title>설치방식 안내</v-card-title>
												<v-card-text>
													<ul class="u-confirm-text">
														<li>
															<h5>방문설치</h5>
															<p>
																고객 집에 방문하여
																<strong>기사 설치</strong>
															</p>
														</li>
														<li>
															<h5>단순배송</h5>
															<p>
																기사가 문 앞에 상품 배송하면
																<strong>고객 직접 설치</strong>
															</p>
														</li>
													</ul>
												</v-card-text>
											</article>
											<article class="bottom">
												<v-card-actions>
													<div class="col">
														<v-btn
															class="btn-confirm"
															@click="popInstallShow = false"
														>
															닫기
														</v-btn>
													</div>
												</v-card-actions>
											</article>
										</section>
									</v-dialog>
								</div>
							</article>
							<!-- 공기청정기 -->
							<article
								class="u-select-block"
								v-if="
									isAirPurifier(pay.options.prdha) &&
										['Y', 'Z'].includes(returnOtherPromotionChk())
								"
							>
								<div class="type-dropdown">
									<h3 class="label">타사제품정보</h3>
									<dl class="u-grid-sheet">
										<dt>
											<h4 class="label sm">제조사</h4>
										</dt>
										<dd>
											<div class="pd-0 col-12">
												<MSelectBottom
													class="mt-2"
													title="제조사 선택"
													v-model="manuFacCode"
													:items="getIndemnifyData('6201')"
													:onceNo="manuFacCode"
													@item-selected="handleManuFacSelected"
												>
													<template v-if="manuFac === undefined">
														<span class="btn_tit">제조사 선택</span>
														<span class="btn_value"></span>
													</template>
													<template v-else>
														<span class="btn_value expand">
															<b class="primary--text">{{
																manuFacDisplayText
															}}</b>
														</span>
													</template>
												</MSelectBottom>
											</div>
										</dd>
									</dl>
									<dl class="u-grid-sheet">
										<dt>
											<h4 class="label sm">설치/제조 년월</h4>
										</dt>
										<dd>
											<div class="pd-0 col-7 grid">
												<MSelectBottom
													title="설치 / 제조 년 선택"
													v-model="makeDateYearCode"
													:onceNo="makeDateYearCode"
													:items="makeDateYearItems"
													@item-selected="handleItemMakeYearSelected"
													class="mt-2"
												>
													<template v-if="makeDateYear === undefined">
														<span class="btn_tit">년도</span>
														<span class="btn_value"></span>
													</template>
													<template v-else>
														<span class="btn_value expand">
															<b class="primary--text">{{
																makeDateYearDisplayText
															}}</b>
														</span>
													</template>
												</MSelectBottom>
											</div>
											<div class="pd-0 col-5 grid ml-1">
												<MSelectGridBottom
													title="설치 / 제조 월 선택"
													v-model="makeDateMonthCode"
													:disabled="
														makeDateYearCode !== undefined &&
															makeDateYearCode === '6'
													"
													:items="makeDateMonthItems"
													:selectedYear="makeDateYearDisplayText"
													@item-selected="handleItemMakeMonthSelected"
													class="mt-2"
												>
													<template v-if="makeDateMonth === undefined">
														<span class="btn_tit">월</span>
														<span class="btn_value"></span>
													</template>
													<template v-else>
														<span class="btn_value expand">
															<b class="primary--text">{{
																makeDateMonthDisplayText
															}}</b>
														</span>
													</template>
												</MSelectGridBottom>
											</div>
										</dd>
									</dl>
									<dl class="u-grid-sheet">
										<dt>
											<h4 class="label sm">제품형태</h4>
										</dt>
										<dd>
											<div class="pd-0 col-12">
												<MSelectBottom
													class="mt-2"
													title="제품형태 선택"
													v-model="prdFormCode"
													:items="getIndemnifyData('6202')"
													:onceNo="prdFormCode"
													@item-selected="handleprdFormSelected"
												>
													<template v-if="prdForm === undefined">
														<span class="btn_tit">제품형태 선택</span>
														<span class="btn_value"></span>
													</template>
													<template v-else>
														<span class="btn_value expand">
															<b class="primary--text">{{
																prdFormDisplayText
															}}</b>
														</span>
													</template>
												</MSelectBottom>
											</div>
										</dd>
									</dl>
									<dl class="u-grid-sheet">
										<dt>
											<h4 class="label sm">제품종류</h4>
										</dt>
										<dd>
											<div class="pd-0 col-12">
												<MSelectBottom
													class="mt-2"
													title="제품종류 선택"
													v-model="prdTypeCode"
													:items="getIndemnifyData('6203')"
													:onceNo="prdTypeCode"
													@item-selected="handleprdTypeSelected"
												>
													<template v-if="prdType === undefined">
														<span class="btn_tit">제품종류 선택</span>
														<span class="btn_value"></span>
													</template>
													<template v-else>
														<span class="btn_value expand">
															<b class="primary--text">{{
																prdTypeDisplayText
															}}</b>
														</span>
													</template>
												</MSelectBottom>
											</div>
										</dd>
									</dl>
									<dl class="u-grid-sheet">
										<dt>
											<h4 class="label sm">설치형태</h4>
										</dt>
										<dd>
											<div class="pd-0 col-12">
												<MSelectBottom
													class="mt-2"
													title="설치형태 선택"
													v-model="instFormCode"
													:items="getIndemnifyData('6204')"
													:onceNo="instFormCode"
													@item-selected="handleinstFormSelected"
												>
													<template v-if="instForm === undefined">
														<span class="btn_tit">설치형태 선택</span>
														<span class="btn_value"></span>
													</template>
													<template v-else>
														<span class="btn_value expand">
															<b class="primary--text">{{
																instFormDisplayText
															}}</b>
														</span>
													</template>
												</MSelectBottom>
											</div>
										</dd>
									</dl>
									<dl class="u-grid-sheet">
										<dt>
											<h4 class="label sm">CA인증필증</h4>
										</dt>
										<dd>
											<div class="pd-0 col-12">
												<v-text-field
													dense
													outlined
													ref="caNumber"
													class="mt-2"
													inputmode="text"
													minlength="10"
													maxlength="10"
													v-mask="'XXXXXXXXXX'"
													v-model="caNum"
													:rules="rules.caNum"
													@keyup="preventKor"
													@focusout="preventKor"
													placeholder="CA인증필증을 입력해 주세요."
												/>
											</div>
										</dd>
									</dl>
								</div>
							</article>
							<!--// 공기청정기 -->
						</section>
					</template>
					<!-- 연수기 -->
					<template v-else-if="isWaterSoftener(pay.options.prdha)">
						<section class="u-select-group">
							<article class="u-select-block">
								<div class="type-button">
									<h3 class="label">타사제품 반환여부</h3>
									<v-btn-toggle
										class="flex-start"
										v-model="returnOtherSelected"
									>
										<v-btn
											text
											v-for="(item, i) in returnOtherItems"
											:key="i"
											:disabled="defaultReturnBtn && item.code === 'N'"
										>
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>
							<article class="u-select-block">
								<div class="type-button">
									<h3 class="label">수질</h3>
									<p class="warn">
										지하수에서는 지정/특수(1개월주기)만 설치가 가능합니다.
									</p>
									<v-btn-toggle
										class="flex-start"
										v-model="waterQualitySelected"
									>
										<v-btn
											text
											v-for="(item, i) in waterQualityItems"
											:key="i"
											:disabled="waterChk(item)"
										>
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>
							<article class="u-select-block">
								<div class="type-button">
									<h3 class="label">벽 종류</h3>
									<p class="warn">
										플라스틱 벽일때는 벽걸이 설치가 불가능합니다.
									</p>

									<v-btn-toggle class="flex-start" v-model="wallTypeSelected">
										<v-btn text v-for="(item, i) in wallTypeItems" :key="i">
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>
							<article class="u-select-block">
								<div class="type-button">
									<h3 class="label">샤워기 종류</h3>
									<v-btn-toggle
										class="flex-start"
										v-model="showerHeadTypeSelected"
									>
										<v-btn
											text
											v-for="(item, i) in showerHeadTypeItems"
											:key="i"
										>
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>
						</section>
					</template>
					<!-- 비데 -->
					<template v-else-if="isBidet(pay.options.prdha)">
						<section class="u-select-group">
							<article class="u-select-block">
								<div class="type-button">
									<h3 class="label">타사제품 반환여부</h3>
									<v-btn-toggle
										class="flex-start"
										v-model="returnOtherSelected"
									>
										<v-btn
											text
											v-for="(item, i) in returnOtherItems"
											:key="i"
											:disabled="defaultReturnBtn && item.code === 'N'"
										>
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>
							<article class="u-select-block">
								<div class="type-button">
									<h3 class="label">화장실 내부 전기콘센트 유무</h3>
									<v-btn-toggle class="flex-start" v-model="socketYnCode">
										<v-btn text v-for="(item, i) in socketYnItems" :key="i">
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>
							<article class="u-select-block" v-if="hasSocketLoc">
								<div class="type-button">
									<h3 class="label">전기콘센트 위치</h3>
									<v-btn-toggle class="flex-start" v-model="socketLocCode">
										<v-btn
											text
											v-for="(item, i) in socketLocItems"
											:key="i"
											:disabled="
												!noneSocket()
													? ['01', '02'].includes(item.code)
													: ['03', '04'].includes(item.code)
											"
										>
											<template v-if="item.code === '01' || item.code === '02'">
												<div v-html="item.text.replace(' ', '<br />')"></div>
											</template>
											<template v-else>
												{{ item.text }}
											</template>
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>
							<article
								class="u-select-block"
								v-if="['Y', 'Z'].includes(returnOtherPromotionChk())"
							>
								<div class="type-dropdown">
									<h3 class="label">타사제품정보</h3>
									<dl class="u-grid-sheet">
										<dt>
											<h4 class="label sm">제조사</h4>
										</dt>
										<dd>
											<div class="pd-0 col-12">
												<MSelectBottom
													class="mt-2"
													title="제조사 선택"
													v-model="manuFacCode"
													:items="getIndemnifyData('6301')"
													:onceNo="manuFacCode"
													@item-selected="handleManuFacSelected"
												>
													<template v-if="manuFac === undefined">
														<span class="btn_tit">제조사 선택</span>
														<span class="btn_value"></span>
													</template>
													<template v-else>
														<span class="btn_value expand">
															<b class="primary--text">{{
																manuFacDisplayText
															}}</b>
														</span>
													</template>
												</MSelectBottom>
											</div>
										</dd>
									</dl>
									<dl class="u-grid-sheet">
										<dt>
											<h4 class="label sm">설치/제조 년월</h4>
										</dt>
										<dd>
											<div class="pd-0 col-7 grid">
												<MSelectBottom
													title="설치 / 제조 년 선택"
													v-model="makeDateYearCode"
													:onceNo="makeDateYearCode"
													:items="makeDateYearItems"
													@item-selected="handleItemMakeYearSelected"
													class="mt-2"
												>
													<template v-if="makeDateYear === undefined">
														<span class="btn_tit">년도</span>
														<span class="btn_value"></span>
													</template>
													<template v-else>
														<span class="btn_value expand">
															<b class="primary--text">{{
																makeDateYearDisplayText
															}}</b>
														</span>
													</template>
												</MSelectBottom>
											</div>
											<div class="pd-0 col-5 grid ml-1">
												<MSelectGridBottom
													title="설치 / 제조 월 선택"
													v-model="makeDateMonthCode"
													:disabled="
														makeDateYearCode !== undefined &&
															makeDateYearCode === '6'
													"
													:items="makeDateMonthItems"
													:selectedYear="makeDateYearDisplayText"
													@item-selected="handleItemMakeMonthSelected"
													class="mt-2"
												>
													<template v-if="makeDateMonth === undefined">
														<span class="btn_tit">월</span>
														<span class="btn_value"></span>
													</template>
													<template v-else>
														<span class="btn_value expand">
															<b class="primary--text">{{
																makeDateMonthDisplayText
															}}</b>
														</span>
													</template>
												</MSelectGridBottom>
											</div>
										</dd>
									</dl>

									<dl class="u-grid-sheet">
										<dt>
											<h4 class="label sm">제품종류</h4>
										</dt>
										<dd>
											<div class="pd-0 col-12">
												<MSelectBottom
													class="mt-2"
													title="제품종류 선택"
													v-model="prdTypeCode"
													:items="getIndemnifyData('6302')"
													:onceNo="prdTypeCode"
													@item-selected="handleprdTypeSelected"
												>
													<template v-if="prdType === undefined">
														<span class="btn_tit">제품종류 선택</span>
														<span class="btn_value"></span>
													</template>
													<template v-else>
														<span class="btn_value expand">
															<b class="primary--text">{{
																prdTypeDisplayText
															}}</b>
														</span>
													</template>
												</MSelectBottom>
											</div>
										</dd>
									</dl>
								</div>
							</article>
						</section>
					</template>
					<!-- 매트리스 -->
					<template v-else-if="isMattress(pay.options.prdha)">
						<section class="u-select-group">
							<article class="u-select-block">
								<div class="type-button">
									<h3 class="label">방문일자 변경 가능 여부</h3>
									<v-btn-toggle class="flex-start" v-model="visitSelected">
										<v-btn text v-for="(item, i) in visitItems" :key="i">
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>
							<article class="u-select-block">
								<div class="type-button">
									<h3 class="label">폐기제품 내림 여부</h3>
									<v-btn-toggle class="flex-start" v-model="wasteSelected">
										<v-btn text v-for="(item, i) in wasteItems" :key="i">
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>

							<article class="u-select-block" v-if="!returnOtherPromotionCode">
								<div class="type-dropdown">
									<h3 class="label">폐기할 자사제품 주문번호</h3>
									<PopOrderNumBottomSheet
										title="주문번호 선택"
										:disabled="wasteSelected !== 0"
										:items="disusePrdItems"
										v-model="disusePrdSelected"
										:disuseList="disuseList"
										@item-selected="handledisusePrdSelected"
									>
										<template>
											주문번호 선택
											<span class="btn_value">
												<b class="primary--text">{{
													disusePrd ? disusePrd.ORDER_NO : ''
												}}</b>
											</span>
										</template>
									</PopOrderNumBottomSheet>
								</div>
							</article>

							<article class="u-select-block" v-if="returnOtherPromotionCode">
								<div class="type-dropdown">
									<h3 class="label">
										타사보상 프로모션으로 사용할 기존 멤버십
									</h3>
									<PreMembershipBottomSheet
										title="멤버십 선택"
										:items="otherPreMemItems"
										:disabled="otherPreMemItems.length <= 0"
										v-model="otherPreMemSelected"
										:preSet="returnOtherPreSet"
										:preMemList="otherPreMemList"
										:returnOtherPromotionCode="returnOtherPromotionCode"
										@item-selected="handlepreOtherMemSelected"
									>
										<template>
											멤버십 선택
											<span class="btn_value">
												<b class="primary--text">{{
													otherPreMem
														? otherPreMem.ORDER_NO
														: otherPreMemItems.length === 0
														? '기존 가입 멤버십 없음'
														: ''
												}}</b>
											</span>
										</template>
									</PreMembershipBottomSheet>
								</div>
							</article>

							<article class="u-select-block">
								<div class="type-dropdown">
									<h3 class="label">기존 멤버십 해지</h3>
									<PreMembershipBottomSheet
										title="멤버십 선택"
										:items="preMemItems"
										:disabled="preMemItems.length <= 0"
										v-model="preMemSelected"
										:preSet="preSet"
										:preMemList="preMemList"
										:otherPreMem="otherPreMem"
										@item-selected="handlepreMemSelected"
									>
										<template>
											멤버십 선택
											<span class="btn_value">
												<b class="primary--text">{{
													preMem
														? preMem.ORDER_NO
														: preMemItems.length === 0
														? '기존 가입 멤버십 없음'
														: ''
												}}</b>
											</span>
										</template>
									</PreMembershipBottomSheet>
								</div>
								<div class="u-app-notice" v-if="returnOtherPromotionCode">
									<p>
										<em>
											타사보상 용으로 선택한 기존 멤버십만 해지 가능하며, 해지를
											원치 않을 경우 미선택 해 주세요.
										</em>
									</p>
								</div>
							</article>

							<article class="u-select-block">
								<div class="type-dropdown">
									<h3 class="label">추가내림 서비스</h3>
									<MultipleBottomSheets
										class="mt-2"
										title="추가내림 서비스"
										v-model="addServiceTypeCode"
										:items="getIndemnifyData('9910')"
										:detailItemCode="9900"
										:disabled="
											moveServiceTypeCode !== undefined &&
												moveServiceTypeCode !== ''
										"
										:selectedItem="addServiceType"
										@item-selected="handleAddServiceTypeSelected"
									>
										<template>
											대상 선택
											<span class="btn_value">
												<b class="primary--text">{{
													addServiceTypeDisplayText
														? addServiceTypeDisplayText
														: ''
												}}</b>
											</span>
										</template>
									</MultipleBottomSheets>
								</div>
							</article>

							<article class="u-select-block">
								<div class="type-dropdown">
									<h3 class="label">이동 서비스</h3>
									<MultipleBottomSheets
										class="mt-2"
										title="이동 서비스"
										v-model="moveServiceTypeCode"
										:items="getIndemnifyData('9910')"
										:detailItemCode="9900"
										:disabled="
											addServiceTypeCode !== undefined &&
												addServiceTypeCode !== ''
										"
										:selectedItem="moveServiceType"
										@item-selected="handleMoveServiceTypeSelected"
									>
										<template>
											대상 선택
											<span class="btn_value">
												<b class="primary--text">{{
													moveServiceTypeDisplayText
														? moveServiceTypeDisplayText
														: ''
												}}</b>
											</span>
										</template>
									</MultipleBottomSheets>
								</div>
							</article>

							<article class="u-select-block">
								<div class="type-button">
									<h3 class="label">엘리베이터 사용</h3>
									<v-btn-toggle class="flex-start" v-model="elevatorSelected">
										<v-btn text v-for="(item, i) in elevatorItems" :key="i">
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>
							<article class="u-select-block">
								<div class="type-button">
									<h3 class="label">사다리차 필요 여부</h3>
									<v-btn-toggle class="flex-start" v-model="ladderCarSelected">
										<v-btn text v-for="(item, i) in ladderCarItems" :key="i">
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>
							<article
								class="u-select-block"
								v-if="
									['4100398', '4100399', '4100500', '4100498'].includes(
										pay.esProd.GOODS_CD.replace(/(^0+)/, '')
									)
								"
							>
								<div class="type-button">
									<h3 class="label">진입 창문 개방시 너비</h3>
									<v-btn-toggle
										class="flex-start"
										v-model="windowWidthSelected"
									>
										<v-btn text v-for="(item, i) in windowWidthItems" :key="i">
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>
							<article
								class="u-select-block"
								v-if="
									['4100398', '4100399', '4100500', '4100498'].includes(
										pay.esProd.GOODS_CD.replace(/(^0+)/, '')
									)
								"
							>
								<div class="type-button">
									<h3 class="label">문 여닫힘 확인</h3>
									<v-btn-toggle class="flex-start" v-model="doorSlideSelected">
										<v-btn text v-for="(item, i) in doorSlideItems" :key="i">
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>
							<article
								class="u-select-block"
								v-if="
									['4100398', '4100399', '4100500', '4100498'].includes(
										pay.esProd.GOODS_CD.replace(/(^0+)/, '')
									)
								"
							>
								<div class="type-button">
									<h3 class="label">붙박이장 여닫힘 확인</h3>
									<v-btn-toggle class="flex-start" v-model="wardrobeSelected">
										<v-btn text v-for="(item, i) in wardrobeItems" :key="i">
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>
							<article
								class="u-select-block"
								v-if="
									['4100398', '4100399'].includes(
										pay.esProd.GOODS_CD.replace(/(^0+)/, '')
									)
								"
							>
								<div class="type-button">
									<h3 class="label">
										자사 LK 매트리스와 타사 프레임 조합 이상 유무
									</h3>
									<v-btn-toggle class="flex-start" v-model="lkMatSelected">
										<v-btn text v-for="(item, i) in lkMatItems" :key="i">
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>
							<article
								class="u-select-block"
								v-if="
									['4100500', '4100498'].includes(
										pay.esProd.GOODS_CD.replace(/(^0+)/, '')
									)
								"
							>
								<div class="type-button">
									<h3 class="label">
										자사 LK 프레임과 타사매트리스 조합 이상 유무
									</h3>
									<v-btn-toggle class="flex-start" v-model="lkFrameSelected">
										<v-btn text v-for="(item, i) in lkFrameItems" :key="i">
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>

							<article class="u-select-block" v-if="returnOtherPromotionCode">
								<div class="type-dropdown">
									<h3 class="label">타사제품정보</h3>
									<dl class="u-grid-sheet">
										<dt>
											<h4 class="label sm">제조사</h4>
										</dt>
										<dd>
											<div class="pd-0 col-12">
												<MSelectBottom
													class="mt-2"
													title="제조사 선택"
													v-model="manuFacCode"
													:items="getIndemnifyData('6401')"
													:onceNo="manuFacCode"
													@item-selected="handleManuFacSelected"
												>
													<template v-if="manuFac === undefined">
														<span class="btn_tit">제조사 선택</span>
														<span class="btn_value"></span>
													</template>
													<template v-else>
														<span class="btn_value expand">
															<b class="primary--text">{{
																manuFacDisplayText
															}}</b>
														</span>
													</template>
												</MSelectBottom>
											</div>
										</dd>
									</dl>
									<dl class="u-grid-sheet">
										<dt>
											<h4 class="label sm">설치/제조 년월</h4>
										</dt>
										<dd>
											<div class="pd-0 col-7 grid">
												<MSelectBottom
													title="설치 / 제조 년 선택"
													v-model="makeDateYearCode"
													:disabled="disabled"
													:onceNo="makeDateYearCode"
													:items="makeTenDateYearItems"
													@item-selected="handleItemMakeYearSelected"
													class="mt-2"
												>
													<template v-if="makeDateYear === undefined">
														<span class="btn_tit">년도</span>
														<span class="btn_value"></span>
													</template>
													<template v-else>
														<span class="btn_value expand">
															<b class="primary--text">{{
																makeDateYearDisplayText
															}}</b>
														</span>
													</template>
												</MSelectBottom>
											</div>
											<div class="pd-0 col-5 grid ml-1">
												<MSelectGridBottom
													title="설치 / 제조 월 선택"
													v-model="makeDateMonthCode"
													:items="makeDateMonthItems"
													:disabled="
														makeDateYearCode !== undefined &&
															makeDateYearCode === '10'
													"
													:selectedYear="makeDateYearDisplayText"
													@item-selected="handleItemMakeMonthSelected"
													class="mt-2"
												>
													<template v-if="makeDateMonth === undefined">
														<span class="btn_tit">월</span>
														<span class="btn_value"></span>
													</template>
													<template v-else>
														<span class="btn_value expand">
															<b class="primary--text">{{
																makeDateMonthDisplayText
															}}</b>
														</span>
													</template>
												</MSelectGridBottom>
											</div>
										</dd>
									</dl>
								</div>
							</article>
						</section>
					</template>
					<!-- 의류청정기-->
					<template v-else-if="isClothesPurifier(pay.options.prdha)">
						<section class="u-select-group">
							<article class="u-select-block">
								<div class="type-button">
									<h3 class="label">방문일자 변경 가능 여부</h3>
									<v-btn-toggle class="flex-start" v-model="visitSelected">
										<v-btn text v-for="(item, i) in visitItems" :key="i">
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>
							<article class="u-select-block">
								<div class="type-button">
									<h3 class="label">엘리베이터 사용</h3>
									<v-btn-toggle class="flex-start" v-model="elevatorSelected">
										<v-btn text v-for="(item, i) in elevatorItems" :key="i">
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>
							<article class="u-select-block">
								<div class="type-button">
									<h3 class="label">사다리차 필요 여부</h3>
									<v-btn-toggle class="flex-start" v-model="ladderCarSelected">
										<v-btn text v-for="(item, i) in ladderCarItems" :key="i">
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>
						</section>
					</template>
					<!--  안마의자 -->
					<template v-else-if="isChair(pay.options.prdha)">
						<article class="u-select-block" v-if="showRtnBtn">
							<div class="type-button">
								<h3 class="label">타사제품 반환여부</h3>
								<v-btn-toggle class="flex-start" v-model="returnOtherSelected">
									<v-btn text v-for="(item, i) in returnOtherItems" :key="i">
										{{ item.text }}
									</v-btn>
								</v-btn-toggle>
							</div>
						</article>
						<section class="u-select-group">
							<article class="u-select-block">
								<div class="type-button">
									<h3 class="label">방문일자 변경 가능 여부</h3>
									<v-btn-toggle class="flex-start" v-model="visitSelected">
										<v-btn text v-for="(item, i) in visitItems" :key="i">
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>
							<article class="u-select-block" v-if="!home2PrdCdChk(pay)">
								<div class="type-button">
									<h3 class="label">폐기제품 내림 여부</h3>
									<v-btn-toggle class="flex-start" v-model="wasteSelected">
										<v-btn text v-for="(item, i) in wasteItems" :key="i">
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>

							<article class="u-select-block" v-if="!home2PrdCdChk(pay)">
								<div class="type-dropdown">
									<h3 class="label">폐기할 자사제품 주문번호</h3>
									<PopOrderNumBottomSheet
										title="주문번호 선택"
										:disabled="wasteSelected !== 0"
										:items="disusePrdItems"
										:disuseList="disuseList"
										v-model="disusePrdSelected"
										@item-selected="handledisusePrdSelected"
									>
										<template>
											주문번호 선택
											<span class="btn_value">
												<b class="primary--text">{{
													disusePrd ? disusePrd.ORDER_NO : ''
												}}</b>
											</span>
										</template>
									</PopOrderNumBottomSheet>
								</div>
							</article>

							<article class="u-select-block">
								<div class="type-dropdown">
									<h3 class="label">추가내림 서비스</h3>
									<MultipleBottomSheets
										class="mt-2"
										title="추가내림 서비스"
										v-model="addServiceTypeCode"
										:items="getIndemnifyData('9910')"
										:detailItemCode="9900"
										:disabled="
											moveServiceTypeCode !== undefined &&
												moveServiceTypeCode !== ''
										"
										:selectedItem="addServiceType"
										@item-selected="handleAddServiceTypeSelected"
									>
										<template>
											대상 선택
											<span class="btn_value">
												<b class="primary--text">{{
													addServiceTypeDisplayText
														? addServiceTypeDisplayText
														: ''
												}}</b>
											</span>
										</template>
									</MultipleBottomSheets>
								</div>
							</article>

							<article class="u-select-block">
								<div class="type-dropdown">
									<h3 class="label">이동 서비스</h3>
									<MultipleBottomSheets
										class="mt-2"
										title="이동 서비스"
										v-model="moveServiceTypeCode"
										:items="getIndemnifyData('9910')"
										:detailItemCode="9900"
										:disabled="
											addServiceTypeCode !== undefined &&
												addServiceTypeCode !== ''
										"
										:selectedItem="moveServiceType"
										@item-selected="handleMoveServiceTypeSelected"
									>
										<template>
											대상 선택
											<span class="btn_value">
												<b class="primary--text">{{
													moveServiceTypeDisplayText
														? moveServiceTypeDisplayText
														: ''
												}}</b>
											</span>
										</template>
									</MultipleBottomSheets>
								</div>
							</article>

							<article class="u-select-block">
								<div class="type-button">
									<h3 class="label">엘리베이터 사용</h3>
									<v-btn-toggle class="flex-start" v-model="elevatorSelected">
										<v-btn text v-for="(item, i) in elevatorItems" :key="i">
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>
							<article class="u-select-block">
								<div class="type-button">
									<h3 class="label">사다리차 필요 여부</h3>
									<v-btn-toggle class="flex-start" v-model="ladderCarSelected">
										<v-btn text v-for="(item, i) in ladderCarItems" :key="i">
											{{ item.text }}
										</v-btn>
									</v-btn-toggle>
								</div>
							</article>
							<article
								class="u-select-block"
								v-if="['Y', 'Z'].includes(returnOtherPromotionChk())"
							>
								<div class="type-dropdown">
									<h3 class="label">타사제품정보</h3>
									<dl class="u-grid-sheet">
										<dt>
											<h4 class="label sm">제조사</h4>
										</dt>
										<dd>
											<div class="pd-0 col-12">
												<MSelectBottom
													class="mt-2"
													title="제조사 선택"
													v-model="manuFacCode"
													:items="getIndemnifyData('6303')"
													:onceNo="manuFacCode"
													@item-selected="handleManuFacSelected"
												>
													<template v-if="manuFac === undefined">
														<span class="btn_tit">제조사 선택</span>
														<span class="btn_value"></span>
													</template>
													<template v-else>
														<span class="btn_value expand">
															<b class="primary--text">{{
																manuFacDisplayText
															}}</b>
														</span>
													</template>
												</MSelectBottom>
											</div>
										</dd>
									</dl>
									<dl class="u-grid-sheet">
										<dt>
											<h4 class="label sm">제품종류</h4>
										</dt>
										<dd>
											<div class="pd-0 col-12">
												<MSelectBottom
													class="mt-2"
													title="제품종류 선택"
													v-model="prdTypeCode"
													:items="getIndemnifyData('6304')"
													:onceNo="prdTypeCode"
													@item-selected="handleprdTypeSelected"
												>
													<template v-if="prdType === undefined">
														<span class="btn_tit">제품종류 선택</span>
														<span class="btn_value"></span>
													</template>
													<template v-else>
														<span class="btn_value expand">
															<b class="primary--text">{{
																prdTypeDisplayText
															}}</b>
														</span>
													</template>
												</MSelectBottom>
											</div>
										</dd>
									</dl>
								</div>
							</article>
						</section>
					</template>
					<!-- 족욕기 -->
					<template v-else-if="isFootBath(pay.options.prdha)">
						<div class="wrap_input">
							<h3 class="label">방문일자 변경 가능 여부</h3>
							<v-btn-toggle
								color="primary"
								class="toggle_btn justify-start"
								v-model="visitSelected"
							>
								<v-btn text v-for="(item, i) in visitItems" :key="i">
									{{ item.text }}
								</v-btn>
							</v-btn-toggle>
						</div>
					</template>
					<!-- 쇼파 -->
					<template v-else>
						<div class="wrap_input">
							<h3 class="label">방문일자 변경 가능 여부</h3>
							<v-btn-toggle
								color="primary"
								class="toggle_btn justify-start"
								v-model="visitSelected"
							>
								<v-btn text v-for="(item, i) in visitItems" :key="i">
									{{ item.text }}
								</v-btn>
							</v-btn-toggle>
						</div>
					</template>
				</v-form>
			</div>
		</div>

		<div class="cw-app-fixed">
			<div class="u-btns-group">
				<div class="column">
					<v-btn class="u-btn-text" depressed large @click="close()">
						이전
					</v-btn>
				</div>
				<div class="column">
					<v-btn class="u-btn-active" depressed large @click="saveEnvInfo()">
						입력완료
					</v-btn>
				</div>
			</div>
		</div>
	</v-dialog>
</template>
<script>
import moment from 'moment'
import {
	getPrdName,
	isWaterPurifier,
	isAirPurifier,
	isWaterSoftener,
	isBidet,
	isMattress,
	isChair,
	isClothesPurifier,
	isFootBath,
	isKnee
} from '@/utils/ec'
import { getIndemnifyData } from '@/utils/common'
import { required, upperCaseAlphaNumeric } from '@/utils/rules'
import { disusePrd, preMem, fetchCheckOtherRtnNum } from '@/apis/order.inst.api'
export default {
	props: {
		no: Number,
		pay: Object
	},
	data() {
		return {
			preSet: -1,
			returnOtherPreSet: -1,
			number: '',
			caNum: '',
			playYn: false,
			rules: {
				required: [v => required(v)],
				number: [
					// v => this.onlyNumber(v),
					v =>
						(v !== '00000000' && v.length === 8) ||
						'NO. 뒤 8자리 숫자를 입력해 주세요'
				],
				caNum: [
					v => upperCaseAlphaNumeric(v, 'CA뒤 10자리'),
					v =>
						(v !== '0000000000' && v.length === 10) ||
						'CA뒤 10자리 영문 대문자 및 숫자를 입력해 주세요.'
				]
			},
			dialog: false,
			returnOtherItems: [
				{ code: 'N', text: '미반환' },
				{ code: 'Y', text: '반환' }
			],
			installTypeItems: [
				{ code: '02', text: '방문설치' },
				{ code: '01', text: '단순배송' }
			],
			disusePrdItems: [],
			preMemItems: [],
			otherPreMemItems: [],
			manuFacItems: [],
			prdFormItems: [],
			prdTypeItems: [],
			instFormItems: [],
			//설치/제조 월
			makeDateMonthItems: [
				{ code: '1', text: '1월' },
				{ code: '2', text: '2월' },
				{ code: '3', text: '3월' },
				{ code: '4', text: '4월' },
				{ code: '5', text: '5월' },
				{ code: '6', text: '6월' },
				{ code: '7', text: '7월' },
				{ code: '8', text: '8월' },
				{ code: '9', text: '9월' },
				{ code: '10', text: '10월' },
				{ code: '11', text: '11월' },
				{ code: '12', text: '12월' }
			],
			makeDateYearItems: [
				{ code: '0', text: `${moment(new Date()).format('YYYY')}년` },
				{ code: '1', text: `${moment(new Date()).format('YYYY') - 1}년` },
				{ code: '2', text: `${moment(new Date()).format('YYYY') - 2}년` },
				{ code: '3', text: `${moment(new Date()).format('YYYY') - 3}년` },
				{ code: '4', text: `${moment(new Date()).format('YYYY') - 4}년` },
				{ code: '5', text: `${moment(new Date()).format('YYYY') - 5}년` },
				{
					code: '6',
					text: `${moment(new Date()).format('YYYY') - 6}년 이전`
				}
			],
			makeTenDateYearItems: [
				{ code: '0', text: `${moment(new Date()).format('YYYY')}년` },
				{ code: '1', text: `${moment(new Date()).format('YYYY') - 1}년` },
				{ code: '2', text: `${moment(new Date()).format('YYYY') - 2}년` },
				{ code: '3', text: `${moment(new Date()).format('YYYY') - 3}년` },
				{ code: '4', text: `${moment(new Date()).format('YYYY') - 4}년` },
				{ code: '5', text: `${moment(new Date()).format('YYYY') - 5}년` },
				{ code: '6', text: `${moment(new Date()).format('YYYY') - 6}년` },
				{ code: '7', text: `${moment(new Date()).format('YYYY') - 7}년` },
				{ code: '8', text: `${moment(new Date()).format('YYYY') - 8}년` },
				{ code: '9', text: `${moment(new Date()).format('YYYY') - 9}년` },
				{
					code: '10',
					text: `${moment(new Date()).format('YYYY') - 10}년 이전`
				}
			],
			multipleFacItems: [
				{ code: '', text: '대상 아님' },
				{ code: '1', text: 'PC방' },
				{ code: '10', text: '실내 주차장' },
				{ code: '11', text: '어린이집(국공립, 법인, 직장, 민간)' },
				{ code: '12', text: '여객터미널' },
				{ code: '13', text: '의료기관' },
				{ code: '14', text: '장례식장' },
				{ code: '15', text: '전시시설' },
				{ code: '16', text: '지하도 상가' },
				{ code: '17', text: '지하역사' },
				{ code: '18', text: '철도역사 및 대합실' },
				{ code: '19', text: '항만시설 및 대합실' },
				{ code: '2', text: '공항시설 및 대합실' },
				{ code: '20', text: '학원' },
				{ code: '3', text: '노인요양시설' },
				{ code: '4', text: '도서관' },
				{ code: '5', text: '목욕장' },
				{ code: '7', text: '박물관' },
				{ code: '8', text: '산후조리원' },
				{ code: '9', text: '실내 영화관' }
			],
			waterQualityItems: [
				{ code: '01', text: '상수도' },
				{ code: '02', text: '지하수' }
			],
			waterDistanceItems: [
				{ code: '01', text: '15m 이내' },
				{ code: '02', text: '15m 이상' }
			],
			wallTypeItems: [
				{ code: '4', text: '일반' },
				{ code: '1', text: '플라스틱' }
			],
			showerHeadTypeItems: [
				{ code: '2', text: '해바라기' },
				{ code: '3', text: '전신' }
			],
			socketYnItems: [
				{ code: '01', text: '있음 (220V)' },
				{ code: '02', text: '있음 (110V)' },
				{ code: '03', text: '없음' }
			],
			socketLocItems: [
				{ code: '01', text: '변기 옆/뒤' },
				{ code: '02', text: '변기 건너편' },
				{ code: '03', text: '욕실외부' },
				{ code: '04', text: '없음' }
			],
			visitItems: [
				{ code: 'Y', text: '가능' },
				{ code: 'N', text: '불가' }
			],
			wasteItems: [
				{ code: '01', text: '자사제품' },
				{ code: '02', text: '타사제품' },
				{ code: '03', text: '없음' }
			],
			wasteReturnOtherItems: [
				{ code: '02', text: '있음' },
				{ code: '03', text: '없음' }
			],
			elevatorItems: [
				{ code: '01', text: '10인승 이상' },
				{ code: '02', text: '10인승 미만' },
				{ code: '03', text: '없음' }
			],
			ladderCarItems: [
				{ code: '02', text: '불필요' },
				{ code: '01', text: '필요' }
			],
			windowWidthItems: [
				{ code: '01', text: '폭 2m 이상' },
				{ code: '02', text: '폭 2m 미만' },
				{ code: '03', text: '창문으로 진입 불가' }
			],
			doorSlideItems: [
				{ code: '01', text: '이상없음' },
				{ code: '02', text: '공간부족' }
			],
			wardrobeItems: [
				{ code: '01', text: '이상없음' },
				{ code: '02', text: '공간부족' }
			],
			lkMatItems: [
				{ code: '01', text: '이상없음' },
				{ code: '02', text: '프레임사이즈 안맞음' }
			],
			lkFrameItems: [
				{ code: '01', text: '이상없음' },
				{ code: '02', text: '매트리스사이즈 안맞음' }
			],
			machineConnectItems: [
				{ code: 'N', text: '불필요' },
				{ code: 'Y', text: '필요' }
			],
			thinkXItems: [{ code: 'Y', text: '동의' }],
			think2XItems: [{ code: 'Y', text: '동의' }],
			builtinGoodsCd: [
				'113232',
				'113337',
				'113338',
				'113339',
				'113340',
				'113341',
				'113233',
				'113343',
				'113344',
				'113345',
				'113346',
				'113347'
			],
			payOptions: [],
			disuseList: [],
			preMemList: [],
			otherPreMemList: [],
			disusePrdSelected: undefined,
			preMemSelected: undefined,
			otherPreMemSelected: undefined,
			machineConnectSelected: undefined,
			thinkXSelected: undefined,
			think2XSelected: undefined,
			returnOtherSelected: undefined,
			installTypeSelected: undefined,
			waterQualitySelected: undefined,
			waterDistanceSelected: undefined,
			wallTypeSelected: undefined,
			showerHeadTypeSelected: undefined,
			visitSelected: undefined,
			wasteSelected: undefined,
			elevatorSelected: undefined,
			ladderCarSelected: undefined,
			multipleFac: undefined,
			manuFac: undefined,
			makeDateMonth: undefined,
			makeDateYear: undefined,
			prdForm: undefined,
			prdType: undefined,
			instForm: undefined,
			multipleFacCode: undefined,
			manuFacCode: undefined,
			makeDateMonthCode: undefined,
			makeDateYearCode: undefined,
			prdFormCode: undefined,
			prdTypeCode: undefined,
			instFormCode: undefined,
			socketYnCode: undefined,
			socketLocCode: undefined,
			memberships: undefined,
			membershipCodes: undefined,
			elevatorCode: undefined,
			manuFacDisplayText: '',
			makeDateYearDisplayText: '',
			makeDateMonthDisplayText: '',
			prdFormDisplayText: '',
			prdTypeDisplayText: '',
			instFormDisplayText: '',
			multipleFacDisplayText: '',
			socketYnDisplayText: '',
			socketLocDisplayText: '',
			membershipDisplayText: '',
			elevatorDisplayText: '',
			showMembership: false,
			membershipItems: undefined, // 기존멤버십
			hasSocketLoc: true,
			windowWidthSelected: undefined,
			doorSlideSelected: undefined,
			wardrobeSelected: undefined,
			lkMatSelected: undefined,
			lkFrameSelected: undefined,
			bidetReturnBtn: false,
			defaultReturnBtn: false,
			disabled: false,
			waterMark: false,
			popInstallShow: false,
			instType: '',
			showRtnBtn: false,

			addServiceType: undefined,
			addServiceTypeDisplayText: '',
			addServiceTypeCode: undefined,

			moveServiceType: undefined,
			moveServiceTypeDisplayText: '',
			moveServiceTypeCode: undefined
		}
	},
	watch: {
		async machineConnect(val) {
			if (val.code === 'Y') {
				await this.$alert({
					message:
						'① 정수기 1.5m 이내만 설치 가능<br />② 에스프레소 머신과는 연결 불가<br />③ 정수기와 커피머신 사이에 벽이<br />있을 시 설치 불가(난공사불가)<br />④ 동파위험이 있는 곳에 설치 불가',
					okText: '확인'
				})
			}
		},
		async waste(val) {
			if (val && val.code === '01') {
				if (this.membershipItems && this.membershipItems.length > 0) {
					this.showMembership = true
				} else {
					this.showMembership = false
					this.memberships = undefined
				}
			} else {
				this.showMembership = false
				this.memberships = undefined
				this.disusePrdSelected = undefined
			}
		},
		async socketYnCode(newValue, oldValue) {
			//콘센트 유무가 없음에서 있음으로 바뀔때 콘센트 위치 undefiend처리
			if (newValue === 2) {
				if (oldValue == 0 || oldValue == 1 || oldValue == undefined) {
					this.socketLocCode = undefined
				}
			} else {
				if (oldValue == 2) {
					this.socketLocCode = undefined
				}
			}
		},
		async disusePrd(val) {
			if (val) {
				const preMem = this.preMemItems.findIndex(
					x => x.ORDER_NO === val.ORDER_NO
				)
				if (preMem !== -1) {
					this.$alert({
						message:
							'선택한 상품에 가입되어 있는 멤버십은 자동으로 해지 신청됩니다.'
					})
					this.preMemSelected = preMem
					this.preSet = preMem
				} else {
					this.preSet = preMem
				}
			} else {
				this.preSet = -1
			}
		}
	},
	computed: {
		returnOther() {
			const options = this.returnOtherItems
			return options ? options[this.returnOtherSelected] : undefined
		},
		installType() {
			const options = this.installTypeItems
			return options ? options[this.installTypeSelected] : undefined
		},
		waterQuality() {
			const options = this.waterQualityItems
			return options ? options[this.waterQualitySelected] : undefined
		},
		waterDistance() {
			const options = this.waterDistanceItems
			return options ? options[this.waterDistanceSelected] : undefined
		},
		machineConnect() {
			const options = this.machineConnectItems
			return options ? options[this.machineConnectSelected] : undefined
		},
		thinkX() {
			const options = this.thinkXItems
			return options ? options[this.thinkXSelected] : undefined
		},
		think2X() {
			const options = this.think2XItems
			return options ? options[this.think2XSelected] : undefined
		},
		wallType() {
			const options = this.wallTypeItems
			return options ? options[this.wallTypeSelected] : undefined
		},
		showerHeadType() {
			const options = this.showerHeadTypeItems
			return options ? options[this.showerHeadTypeSelected] : undefined
		},
		visit() {
			const options = this.visitItems
			return options ? options[this.visitSelected] : undefined
		},
		waste() {
			const options = this.wasteItems
			return options ? options[this.wasteSelected] : undefined
		},
		ladderCar() {
			const options = this.ladderCarItems
			return options ? options[this.ladderCarSelected] : undefined
		},
		elevator() {
			this.$log('elevatorItems')
			const options = this.elevatorItems
			return options ? options[this.elevatorSelected] : undefined
		},
		windowWidth() {
			const options = this.windowWidthItems
			return options ? options[this.windowWidthSelected] : undefined
		},
		doorSlide() {
			const options = this.doorSlideItems
			return options ? options[this.doorSlideSelected] : undefined
		},
		wardrobe() {
			const options = this.wardrobeItems
			return options ? options[this.wardrobeSelected] : undefined
		},
		lkMat() {
			const options = this.lkMatItems
			return options ? options[this.lkMatSelected] : undefined
		},
		lkFrame() {
			const options = this.lkFrameItems
			return options ? options[this.lkFrameSelected] : undefined
		},
		socketLoc() {
			const options = this.socketLocItems
			return options ? options[this.socketLocCode] : undefined
		},
		socketYn() {
			const options = this.socketYnItems
			return options ? options[this.socketYnCode] : undefined
		},
		disusePrd() {
			const options = this.disusePrdItems
			return options ? options[this.disusePrdSelected] : undefined
		},
		preMem() {
			const options = this.preMemItems
			return options ? options[this.preMemSelected] : undefined
		},
		otherPreMem() {
			const options = this.otherPreMemItems
			return options ? options[this.otherPreMemSelected] : undefined
		},
		returnOtherPromotionCode() {
			let promotionInfo = []
			if (
				this.payOptions?.promotion === 'A350' &&
				this.payOptions?.ET_ZSDS5204
			) {
				promotionInfo = this.payOptions.ET_ZSDS5204.filter(
					x => x.PRM_CODE === this.payOptions.promotion
				)
			}
			return promotionInfo.length > 0 ? true : false
		}
	},
	methods: {
		getIndemnifyData,
		getPrdName,
		isWaterPurifier,
		isAirPurifier,
		isWaterSoftener,
		isBidet,
		isMattress,
		isChair,
		isClothesPurifier,
		isFootBath,
		isKnee,
		preventKor($event) {
			const regex = /[^0-9a-zA-Z]/g
			const value = $event.target.value

			const filterValue = value.toUpperCase().replace(regex, '')
			$event.target.value = filterValue
			this.caNum = filterValue
		},
		returnOtherPromotionChk() {
			this.$log('설치정보 타사보상====>>>', this.payOptions)
			// [선택한 프로모션이 있고 프로모션 리스트가 있는 경우
			if (this.payOptions.promotion !== '' && this.payOptions.ET_ZSDS5204) {
				// 선택한 프로모션 코드에 대한 정보를 추출
				const promotionInfo = this.payOptions.ET_ZSDS5204.filter(
					x => x.PRM_CODE === this.payOptions.promotion
				)
				const result = promotionInfo[0]

				this.$log('선택한 프로모션 정보 ====>>>', result)

				if (result) {
					if (result.OTHER_RTN_YN === 'Y') {
						this.defaultReturnBtn = true
					}
					return result.OTHER_RTN_YN
				} else {
					return ''
				}
			}
		},
		returnOtherChairChk() {
			if (
				this.payOptions.promotion !== '' &&
				this.payOptions.ET_ZSDS5204?.length > 0
			) {
				// 선택한 프로모션 코드에 대한 정보를 추출
				const promotionInfo = this.payOptions.ET_ZSDS5204.filter(
					x => x.PRM_CODE === this.payOptions.promotion
				)
				const result = promotionInfo[0]
				if (result) {
					const goodsCd = result.GOODS_CD.replace(/(^0+)/, '')
					const prdha = this.pay.options.prdha
					if (
						isChair(prdha) &&
						[
							'4100717',
							'4100718',
							'4100719',
							'4100720',
							'4100725',
							'4100726',
							'4100727'
						].includes(goodsCd) &&
						['A302', 'A304'].includes(result.PRM_CODE)
					) {
						this.showRtnBtn = true
					}
				}
			}
		},
		waterMarkChk() {
			this.payOptions.ET_ZSDS5204.forEach(v => {
				if (v.WMART_YN === 'Y') {
					this.waterMark = true
				}
			})
		},

		waterChk(item) {
			//pay.options.mngType 있을때
			if (this.pay.options.mngType) {
				//지정특수 일때만 활성화(false)
				if (this.pay.options.mngType.code !== '3' && item.code === '02') {
					return true
				} else {
					return false
				}
			} else {
				if (this.pay.options.membershipType) {
					if (
						this.pay.options.membershipType.code === '03' &&
						item.code === '02'
					) {
						return false
					} else if (
						this.pay.options.membershipType.code !== '03' &&
						item.code === '02'
					) {
						return true
					} else {
						return false
					}
				} else {
					return false
				}
			}
		},
		open(type, envInfo, pay, disuseList, preMemList, otherPreMemList) {
			this.payOptions = pay.payOptions
			this.disuseList = disuseList
			this.preMemList = preMemList
			this.otherPreMemList = otherPreMemList

			this.dialog = true
			if (envInfo) {
				this.$log(JSON.stringify(envInfo))
				// 재진입
				this.number = envInfo.number
				this.caNum = envInfo.caNum
				this.returnOtherSelected = envInfo.returnOtherSelected
				this.installTypeSelected = envInfo.installTypeSelected
				this.waterQualitySelected = envInfo.waterQualitySelected
				this.waterDistanceSelected = envInfo.waterDistanceSelected
				this.machineConnectSelected = envInfo.machineConnectSelected
				this.thinkXSelected = envInfo.thinkXSelected
				this.think2XSelected = envInfo.think2XSelected
				this.wallTypeSelected = envInfo.wallTypeSelected
				this.showerHeadTypeSelected = envInfo.showerHeadTypeSelected
				this.visitSelected = envInfo.visitSelected
				this.wasteSelected = envInfo.wasteSelected
				this.ladderCarSelected = envInfo.ladderCarSelected
				this.multipleFacCode = envInfo.multipleFacCode
				this.manuFacCode = envInfo.manuFacCode
				this.makeDateMonthCode = envInfo.makeDateMonthCode
				this.makeDateYearCode = envInfo.makeDateYearCode
				this.prdFormCode = envInfo.prdFormCode
				this.prdTypeCode = envInfo.prdTypeCode
				this.instFormCode = envInfo.instFormCode
				this.socketLocCode = envInfo.socketLocCode
				this.socketYnCode = envInfo.socketYnCode
				this.membershipCodes = envInfo.membershipCodes
				this.elevatorCode = envInfo.elevatorCode
				this.lkMatSelected = envInfo.lkMatSelected
				this.lkFrameSelected = envInfo.lkFrameSelected
				this.elevatorSelected = envInfo.elevatorSelected
				this.windowWidthSelected = envInfo.windowWidthSelected
				this.doorSlideSelected = envInfo.doorSlideSelected
				this.wardrobeSelected = envInfo.wardrobeSelected

				this.disusePrdSelected = envInfo.disusePrdSelected

				this.preMemSelected = envInfo.preMemSelected
				this.otherPreMemSelected = envInfo.otherPreMemSelected
				this.addServiceType = envInfo.addServiceType
				this.addServiceTypeCode = envInfo.addServiceTypeCode
				this.moveServiceType = envInfo.moveServiceType
				this.moveServiceTypeCode = envInfo.moveServiceTypeCode
			} else {
				this.default(this.pay.options.prdha, type)
			}
		},
		close() {
			this.dialog = false
		},
		async saveEnvInfo() {
			let isValid = true
			const prdha = this.pay.options.prdha
			const goodsCd = this.pay.esProd.GOODS_CD.replace(/(^0+)/, '')
			if (this.isWaterPurifier(prdha)) {
				// 정수기
				if (
					this.returnOtherSelected === undefined ||
					this.multipleFacCode === undefined ||
					this.waterQualitySelected === undefined ||
					this.waterDistanceSelected === undefined ||
					(['112653'].includes(goodsCd) &&
						this.machineConnectSelected === undefined)
				) {
					isValid = false
				}
				if (['Y', 'Z'].includes(this.returnOtherPromotionChk())) {
					if (
						this.manuFacCode === undefined ||
						(this.makeDateYearCode !== '6' &&
							this.makeDateMonthCode === undefined) ||
						this.makeDateYearCode === undefined ||
						this.prdFormCode === undefined ||
						this.prdTypeCode === undefined ||
						this.instFormCode === undefined ||
						(this.waterMark &&
							this.number === '' &&
							!this.$refs['instEnvInfoForm'].validate()) ||
						this.multipleFacCode === undefined
					) {
						isValid = false
					} else {
						if (this.waterMark && !this.$refs['instEnvInfoForm'].validate()) {
							return
						}
					}
				}
				if (this.builtinGoodsCd.includes(goodsCd)) {
					if (
						this.thinkXSelected === undefined ||
						this.think2XSelected === undefined
					) {
						isValid = false
					}
				}
			} else if (this.isAirPurifier(prdha)) {
				// 공기청정기
				const promotionCheck =
					this.returnOther &&
					this.returnOther.code === 'N' &&
					['Y', 'Z'].includes(this.returnOtherPromotionChk())
				if (this.returnOtherSelected === undefined) {
					isValid = false
				}

				if (
					this.installTypePrdOrderTypeChk(this.pay) &&
					this.instType === 'X'
				) {
					if (
						this.returnOtherSelected ===
							this.returnOtherItems.findIndex(v => v.code === 'Y') ||
						promotionCheck
					) {
						this.installTypeSelected = this.installTypeItems.findIndex(
							v => v.code === '02'
						)
					}

					if (this.installTypeSelected === undefined) {
						isValid = false
					}
				}
				if (['Y', 'Z'].includes(this.returnOtherPromotionChk())) {
					if (
						this.manuFacCode === undefined ||
						(this.makeDateYearCode !== '6' &&
							this.makeDateMonthCode === undefined) ||
						this.makeDateYearCode === undefined ||
						this.prdFormCode === undefined ||
						this.prdTypeCode === undefined ||
						this.instFormCode === undefined ||
						(this.caNum === '' && !this.$refs['instEnvInfoForm'].validate())
					) {
						isValid = false
					} else {
						if (!this.$refs['instEnvInfoForm'].validate()) {
							return
						}
					}
				}
			} else if (this.isWaterSoftener(prdha)) {
				// 연수기
				if (
					this.returnOtherSelected === undefined ||
					this.waterQualitySelected === undefined ||
					this.wallTypeSelected === undefined ||
					this.showerHeadTypeSelected === undefined
				) {
					isValid = false
				}
			} else if (this.isBidet(prdha)) {
				// 비데
				if (
					this.returnOtherSelected === undefined ||
					this.socketYnCode === undefined ||
					(this.socketYnCode !== '03' && this.socketLocCode === undefined)
				) {
					isValid = false
				}
				if (['Y', 'Z'].includes(this.returnOtherPromotionChk())) {
					if (
						this.manuFacCode === undefined ||
						(this.makeDateYearCode !== '6' &&
							this.makeDateMonthCode === undefined) ||
						this.makeDateYearCode === undefined ||
						this.prdTypeCode === undefined
					) {
						isValid = false
					}
				}
			} else if (this.isMattress(prdha)) {
				// 매트리스
				if (
					this.visitSelected === undefined ||
					this.wasteSelected === undefined ||
					this.elevatorSelected === undefined ||
					this.ladderCarSelected === undefined ||
					(['4100398', '4100399', '4100500', '4100498'].includes(goodsCd) &&
						this.windowWidthSelected === undefined) ||
					(['4100398', '4100399', '4100500', '4100498'].includes(goodsCd) &&
						this.doorSlideSelected === undefined) ||
					(['4100398', '4100399', '4100500', '4100498'].includes(goodsCd) &&
						this.wardrobeSelected === undefined) ||
					(['4100398', '4100399'].includes(goodsCd) &&
						this.lkMatSelected === undefined) ||
					(['4100500', '4100498'].includes(goodsCd) &&
						this.lkFrameSelected === undefined)
				) {
					isValid = false
				} else {
					if (this.wasteSelected === 0) {
						// 자사제품일 때 멤버십
						if (
							!this.returnOtherPromotionCode &&
							this.disusePrdSelected === undefined
						) {
							isValid = false
						}
					}
				}

				if (this.returnOtherPromotionCode) {
					if (
						this.manuFacCode === undefined ||
						(this.makeDateYearCode !== '10' &&
							this.makeDateMonthCode === undefined) ||
						this.makeDateYearCode === undefined ||
						this.otherPreMemSelected === undefined
					) {
						isValid = false
					}
				}
			} else if (this.isChair(prdha)) {
				// 안마의자
				if (['Y', 'Z'].includes(this.returnOtherPromotionChk())) {
					if (
						this.manuFacCode === undefined ||
						this.prdTypeCode === undefined
					) {
						isValid = false
					}
				}
				if (
					//방문일자 변경 가능 여부
					this.visitSelected === undefined ||
					//엘리베이터 사용
					this.elevatorSelected === undefined ||
					//사다리차 필요 여부
					this.ladderCarSelected === undefined ||
					this.returnOtherSelected === undefined
				) {
					isValid = false
				}
				if (this.wasteSelected === undefined) {
					isValid = false
				} else {
					// 자사제품일 때 멤버십
					if (
						this.wasteSelected === 0 &&
						this.disusePrdSelected === undefined
					) {
						isValid = false
					}
				}
			} else if (this.isClothesPurifier(prdha)) {
				// 의류청정기
				if (
					this.visitSelected === undefined ||
					this.elevatorSelected === undefined ||
					this.ladderCarSelected === undefined
				) {
					isValid = false
				}
			} else if (this.isFootBath(prdha)) {
				if (this.visitSelected === undefined) {
					isValid = false
				}
			} else {
				if (this.visitSelected === undefined) {
					isValid = false
				}
			}
			if (!isValid) {
				this.$alert({
					message: '설치환경정보를 입력해 주세요.'
				})
				return false
			}

			if (this.number.length > 0 || this.caNum.length > 0) {
				const params = {
					gubun: this.waterMark ? 'Y' : 'C',
					otherRtnNo: this.waterMark ? this.number : this.caNum
				}
				const result = await fetchCheckOtherRtnNum(params)
				let rtn = false
				if (result.resultObject.data.E_CHK === 'Y') {
					let msg = `이미 접수 된 ${
						this.waterMark ? '물마크 번호로' : 'CA인증필증으로'
					} 확인되어, 해당 번호로 주문 진행 시 변칙의심 모니터링 대상이 될 수 있습니다. 입력된 정보로 주문을 진행하시겠습니까?`

					await this.$confirm({
						message: msg,
						okText: '확인',
						cancelText: '취소'
					}).then(res => {
						rtn = !res
						if (rtn) {
							this.waterMark
								? this.$refs.waterMarkNumber.focus()
								: this.$refs.caNumber.focus()
						}
					})
				}
				// 자사 물마크번호 사용시 주문접수 제한 (CWMORDER-35)
				else if (this.waterMark && result.resultObject.data.E_CHK === 'A') {
					rtn = true
					await this.$alert({
						message:
							'검사필증 No.(입력한 물마크 번호)는 자사 제품의 검사필증번호입니다. 확인 후 다시 입력해주세요.'
					})
					this.$refs.waterMarkNumber.focus()
				}
				if (rtn) return false
			}

			let env = {
				number: this.number,
				caNum: this.caNum,
				multipleFac: this.multipleFac,
				manuFac: this.manuFac,
				makeDateMonth: this.makeDateMonth,
				makeDateYear: this.makeDateYear,
				prdForm: this.prdForm,
				prdType: this.prdType,
				instForm: this.instForm,
				socketYn: this.socketYn,
				socketLoc: this.socketLoc,
				memberships: this.memberships,
				elevator: this.elevator,
				returnOther: this.returnOther,
				installType: this.installType,
				waterQuality: this.waterQuality,
				waterDistance: this.waterDistance,
				wallType: this.wallType,
				showerHeadType: this.showerHeadType,
				visit: this.visit,
				waste: this.waste,
				ladderCar: this.ladderCar,
				addServiceType: this.addServiceType,
				moveServiceType: this.moveServiceType,

				returnOtherSelected: this.returnOtherSelected,
				installTypeSelected: this.installTypeSelected,
				waterQualitySelected: this.waterQualitySelected,
				waterDistanceSelected: this.waterDistanceSelected,
				machineConnectSelected: this.machineConnectSelected,
				machineConnect: this.machineConnect,
				thinkXSelected: this.thinkXSelected,
				think2XSelected: this.think2XSelected,
				thinkX: this.thinkX,
				think2X: this.think2X,
				wallTypeSelected: this.wallTypeSelected,
				showerHeadTypeSelected: this.showerHeadTypeSelected,
				visitSelected: this.visitSelected,
				wasteSelected: this.wasteSelected,
				ladderCarSelected: this.ladderCarSelected,

				multipleFacCode: this.multipleFacCode,
				manuFacCode: this.manuFacCode,
				makeDateMonthCode: this.makeDateMonthCode,
				makeDateYearCode: this.makeDateYearCode,
				prdFormCode: this.prdFormCode,
				prdTypeCode: this.prdTypeCode,
				instFormCode: this.instFormCode,
				socketYnCode: this.socketYnCode,
				socketLocCode: this.socketLocCode,
				membershipCodes: this.membershipCodes,
				elevatorCode: this.elevatorCode,
				windowWidth: this.windowWidth,
				windowWidthSelected: this.windowWidthSelected,

				doorSlide: this.doorSlide,
				doorSlideSelected: this.doorSlideSelected,

				wardrobe: this.wardrobe,
				wardrobeSelected: this.wardrobeSelected,

				lkMat: this.lkMat,
				lkMatSelected: this.lkMatSelected,

				lkFrame: this.lkFrame,
				lkFrameSelected: this.lkFrameSelected,

				elevatorSelected: this.elevatorSelected,

				disusePrd: this.disusePrd,
				disusePrdSelected: this.disusePrdSelected,

				preMemSelected: this.preMemSelected,
				preMem: this.preMem,

				otherPreMemSelected: this.otherPreMemSelected,
				otherPreMem: this.otherPreMem,

				addServiceTypeCode: this.addServiceTypeCode,
				moveServiceTypeCode: this.moveServiceTypeCode
			}
			this.$emit('save', { no: this.no, envInfo: env, isValid })
			this.dialog = false
		},
		handleManuFacSelected(item) {
			this.manuFac = item
			this.manuFacDisplayText = item.text
		},
		// 설치/제조 년
		handleItemMakeYearSelected(item) {
			this.makeDateYear = item
			this.makeDateYearDisplayText = item.text
			if (this.makeDateMonthCode) {
				this.makeDateMonth = undefined
				this.makeDateMonthCode = undefined
			}
		},
		// 설치/제조 월
		handleItemMakeMonthSelected(item) {
			this.makeDateMonth = item
			this.makeDateMonthDisplayText = item.text
		},
		handleprdFormSelected(item) {
			this.prdForm = item
			this.prdFormDisplayText = item.text
		},
		handleprdTypeSelected(item) {
			this.prdType = item
			this.prdTypeDisplayText = item.text
		},
		handleinstFormSelected(item) {
			this.instForm = item
			this.instFormDisplayText = item.text
		},
		handleMultipleFacSelected(item) {
			this.multipleFac = item
			this.multipleFacDisplayText = item.text
		},
		handleSocketYnSelected(item) {
			this.socketYn = item
			this.socketYnDisplayText = item.text
			// 없음을 선택할 경우 [8]콘텐츠 위치 숨김처리
			if (item.code === '03') {
				// this.$refs[`socketLoc${this.no}`].onChange(3)
				this.hasSocketLoc = false
				this.socketLocCode = undefined
				this.socketLoc = undefined
				this.socketLocDisplayText = ''
			} else {
				this.hasSocketLoc = true
			}
		},
		handleSocketLocSelected(item) {
			this.socketLoc = item
			this.socketLocDisplayText = item.text
		},
		handleMembershipSelected(items) {
			this.memberships = items
			this.membershipDisplayText = items
				.map(function(item) {
					return item.MAKTX
				})
				.join(', ')
			this.$log(JSON.stringify(this.memberships))
		},
		handleElevatorSelected(item) {
			this.elevator = item
			this.elevatorDisplayText = item.text
		},
		handleAddServiceTypeSelected(item) {
			this.addServiceType = item
			this.addServiceTypeCode = item.code
			this.addServiceTypeDisplayText = item.text

			// 이동 서비스 초기화
			if (item && item.code !== '') this.moveOrAddServiceTypeInit('move')
		},
		handleMoveServiceTypeSelected(item) {
			this.moveServiceType = item
			this.moveServiceTypeCode = item.code
			this.moveServiceTypeDisplayText = item.text

			// 추가 서비스 초기화
			if (item && item.code !== '') this.moveOrAddServiceTypeInit('add')
		},
		noneSocket() {
			if (this.socketYnCode === 2) {
				return false
			} else {
				return true
			}
		},

		/**
		 * 설치환경정보 자동셋팅값
		 */
		async default(prdha, type) {
			//매트리스
			if (isMattress(prdha)) {
				const goodsCd = this.pay.esProd.GOODS_CD.replace(/(^0+)/, '')
				const kunnr = this.$store.getters['verify/custNo']
				const param = {
					I_CONTRACT_CUST_ID: kunnr,
					I_GOODS_CD: goodsCd
				}
				const res = await disusePrd(
					this.$store.getters['verify/mobOrderNo'],
					param
				)
				const memParam = {
					I_CONTRACT_CUST_ID: kunnr
				}
				let memRes = await preMem(
					this.$store.getters['verify/mobOrderNo'],
					memParam
				)

				this.disusePrdItems = res.resultObject.data.ET_LIST
				this.preMemItems = memRes.resultObject.data.ET_1843

				//타사보상 프로모션으로 사용한 기존 멤버십 items
				if (this.returnOtherPromotionCode) {
					this.wasteItems = this.wasteReturnOtherItems
					this.returnOtherSelected = 0

					Object.assign(memParam, { I_PRM_CODE: this.payOptions.promotion })
					memRes = await preMem(
						this.$store.getters['verify/mobOrderNo'],
						memParam
					)
					this.otherPreMemItems = memRes.resultObject.data.ET_1843
				}

				this.elevatorSelected = 0
				this.ladderCarSelected = 0

				if (['4100398', '4100399', '4100500', '4100498'].includes(goodsCd)) {
					this.elevatorItems = [
						{ code: '01', text: '17인승 이상' },
						{ code: '02', text: '17인승 미만' },
						{ code: '03', text: '없음' }
					]
					this.windowWidthSelected = 0
					this.elevatorSelected = undefined
					this.doorSlideSelected = 0
					this.wardrobeSelected = 0
				}
				if (['4100398', '4100399'].includes(goodsCd)) {
					this.lkMatSelected = 0
				}
				if (['4100500', '4100498'].includes(goodsCd)) {
					this.lkFrameSelected = 0
				}

				this.moveOrAddServiceTypeInit('all')
			}
			//정수기
			if (isWaterPurifier(prdha)) {
				this.waterMarkChk()
				const goodsCd = this.pay.esProd.GOODS_CD.replace(/(^0+)/, '')
				if (this.returnOtherPromotionChk() === 'Y') {
					this.returnOtherSelected = 1
				} else {
					this.returnOtherSelected = 0
				}
				this.waterQualitySelected = 0

				this.waterDistanceSelected = 0
				if (['112653'].includes(goodsCd)) {
					this.machineConnectSelected = 0
				}
			}
			//공기청정기
			if (isAirPurifier(prdha)) {
				if (this.returnOtherPromotionChk() === 'Y') {
					this.returnOtherSelected = 1
				} else {
					this.returnOtherSelected = 0
				}
				this.instType = type
			}
			//의류청정기
			if (isClothesPurifier(prdha)) {
				this.elevatorSelected = 0
				this.ladderCarSelected = 0
			}

			//안마의자
			if (isChair(prdha)) {
				// 타사보상 프로모션 체크
				this.returnOtherChairChk()

				this.elevatorSelected = 0
				this.ladderCarSelected = 0
				this.returnOtherSelected = 0

				const goodsCd = this.pay.esProd.GOODS_CD.replace(/(^0+)/, '')
				const kunnr = this.$store.getters['verify/custNo']

				if (this.home2PrdCdChk(this.pay)) this.wasteSelected = 2

				const param = {
					I_CONTRACT_CUST_ID: kunnr,
					I_GOODS_CD: goodsCd
				}
				const res = await disusePrd(
					this.$store.getters['verify/mobOrderNo'],
					param
				)
				this.disusePrdItems = res.resultObject.data.ET_LIST

				this.moveOrAddServiceTypeInit('all')
			}

			//연수기
			if (isWaterSoftener(prdha)) {
				if (this.returnOtherPromotionChk() === 'Y') {
					this.returnOtherSelected = 1
				} else {
					this.returnOtherSelected = 0
				}

				this.waterQualitySelected = 0
				this.wallTypeSelected = 0
			}
			//비데
			if (isBidet(prdha)) {
				if (this.returnOtherPromotionChk() === 'Y') {
					this.returnOtherSelected = 1
				} else {
					this.returnOtherSelected = 0
				}
				this.socketYnCode = 0
				this.socketLocCode = 0
			}
		},

		handledisusePrdSelected(val) {
			this.disusePrdSelected = val
		},
		handlepreMemSelected(val) {
			if (this.preMemSelected === val) {
				this.preMemSelected = undefined
			} else {
				this.preMemSelected = val
			}
		},
		handlepreOtherMemSelected(val) {
			if (this.otherPreMemSelected === val) {
				this.otherPreMemSelected = undefined
			} else {
				this.otherPreMemSelected = val
			}
			// 기존 멤버십 주문번호는 초기화
			this.preMemSelected = undefined
		},
		preMemText() {
			if (this.preMemItems.length <= 0) {
				return '기존 가입 멤버십 없음'
			} else {
				return ''
			}
		},
		moveOrAddServiceTypeInit(gubun) {
			const initData = this.getIndemnifyData('9910').filter(v => v.code === '')
			if (gubun === 'move') {
				this.moveServiceType = initData[0]
				this.moveServiceTypeCode = initData[0].code
				this.moveServiceTypeDisplayText = initData[0].text
			} else if (gubun === 'add') {
				this.addServiceType = initData[0]
				this.addServiceTypeCode = initData[0].code
				this.addServiceTypeDisplayText = initData[0].text
			} else if (gubun === 'all') {
				this.moveServiceType = initData[0]
				this.moveServiceTypeCode = initData[0].code
				this.moveServiceTypeDisplayText = initData[0].text
				this.addServiceType = initData[0]
				this.addServiceTypeCode = initData[0].code
				this.addServiceTypeDisplayText = initData[0].text
			}
		},
		installTypePrdOrderTypeChk(pay) {
			const typeCheck1 = ['Z001', 'Z003', 'Z004'].includes(pay.esProd.ORD_TYPE)
			const typeCheck2 =
				pay.esProd.ORD_TYPE === 'Z002' && pay.options.returnPromotionApplied
			const typeCheck3 =
				pay.esProd.ORD_TYPE === 'Z002' && pay.options.preOrder?.RTN_YN === 'M'

			return typeCheck1 || typeCheck2 || typeCheck3
		},
		home2PrdCdChk(pay) {
			const goodsCd = pay.esProd.GOODS_CD.replace(/(^0+)/, '')
			return ['6099809', '6099805'].includes(goodsCd)
		}
	}
}
</script>

<style scoped lang="scss"></style>

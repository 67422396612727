<template>
	<div :class="['cw-app-wrapper', 'promotion', 'order_wrap']">
		<!-- 상단 스태퍼 -->
		<div class="cw-app-module">
			<MStepper />
		</div>
		<!--// 상단 스태퍼 -->

		<!--contents-->
		<div class="cw-app-container">
			<!-- 상단 타이틀, 옵션 초기화 -->
			<div class="tit_area">
				<h2 class="tit_page">상품선택</h2>
			</div>
			<!--// 상단 타이틀, 옵션 초기화 -->

			<!-- 선택한 상품 정보 -->
			<div class="detail_item">
				<div class="flex_row">
					<div class="pic_area">
						<img :src="`${prd.imagePath}`" :alt="prd.prodNm" />
					</div>
					<div class="txt_area">
						<h3 class="tit">{{ prd.prodNm }}</h3>
						<p class="sub_tit">{{ prd.prodGb }}</p>
					</div>
				</div>

				<div class="count_box">
					<div class="mb-0">
						<dl class="price_area">
							<dt>월 납입금</dt>
							<dd>{{ (prd.price * prdQuantity) | comma }}원</dd>
						</dl>
						<dl class="price_area" v-if="webProdPaymentMonthList.length > 0">
							<dt>납입 개월수</dt>
							<dd>
								{{ webProdPaymentMonthList && webProdPaymentMonthList[0].text }}
							</dd>
						</dl>
					</div>
				</div>
				<!-- 결합 Tip -->
				<div class="u-item-tips expand">
					<p class="txt">
						<span>결합 Tip</span>
					</p>
					<p class="dot">
						코웨이 신규 주문 및 기존 사용 중인 계정과 결합이 가능합니다.
					</p>
					<p class="dot">
						코웨이 계정 1개당 최대 3개의 상조 주문이 결합 가능합니다.
					</p>
					<div class="btn">
						<v-btn
							text
							class="underline"
							@click="openPopCowayLifeSolutionGuide()"
							>자세한 안내 보기</v-btn
						>
					</div>
				</div>
				<!--// 결합 Tip -->
			</div>
			<!--// 선택한 상품 정보 -->

			<v-expansion-panels focusable v-model="openedPanel">
				<!-- 제휴 결합 선택 -->
				<v-expansion-panel class="accodion_select">
					<v-expansion-panel-header
						><h3 class="sub_tit">제휴 결합 선택</h3>
						<p class="selected blue-primary">
							{{ funeralCombineItem && funeralCombineItem.text }}
						</p>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-btn-toggle
							class="toggle_row"
							v-model="funeralCombineItemSelected"
						>
							<v-btn
								v-for="(item, i) in funeralCombineItems"
								:class="item.code === 'new' ? 'btn_white' : 'btn_white life'"
								large
								:key="i"
								:disabled="item.code === 'pre' && !isPreOrder"
								@click="funeralCombineEvent(item, i)"
							>
								{{ item.text }}
								<p class="warn" v-if="item.code === 'pre' && !isPreOrder">
									결합 가능한 기존 계정 없음
								</p>
							</v-btn>
						</v-btn-toggle>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<!-- 제휴 결합 선택 -->
				<!-- 기존 계정 선택-->
				<v-expansion-panel class="accodion_select" v-if="preOrderOpen">
					<v-expansion-panel-header>
						<h3 class="sub_tit">기존계정 선택</h3>
						<p class="selected blue-primary">
							{{ preOrder && preOrder.ORDER_NO }}
						</p>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<p class="notice">
							<i class="ico"></i>잔여 약정기간이 많이 남은 계정부터 선택하는
							것을 권장합니다. <br />
							<i class="ico"></i>결합혜택 지원 기간(60개월)동안 제휴 결합을
							유지해야, 모든 혜택을 받을 수 있습니다.
						</p>
						<v-btn-toggle
							class="toggle_row"
							v-model="preOrderSelected"
							:mandatory="preOrder ? true : false"
						>
							<v-btn
								class="btn_white list"
								large
								v-for="(preOrderItem, preOrderIdx) in preOrderItems"
								:key="preOrderIdx"
								:disabled="
									preOrderItem.combineAble === 0 || preOrderItem.TYPE === 'F'
								"
								@click="preOrderEvent(preOrderItem, preOrderIdx)"
							>
								<dl>
									<dt>주문번호</dt>
									<dd>{{ preOrderItem.ORDER_NO }}</dd>
								</dl>
								<dl>
									<dt>렌탈</dt>
									<dd>{{ preOrderItem.GOODS_NM }}</dd>
								</dl>
								<dl>
									<dt>설치일자</dt>
									<dd>{{ preOrderItem.FIRST_INST_DT }}</dd>
								</dl>
								<dl>
									<dt>잔여 약정기간(소유권이전)</dt>
									<dd>{{ preOrderItem.REMAIN_MONTH }}개월</dd>
								</dl>
								<dl>
									<dt>월 렌탈료</dt>
									<dd>{{ preOrderItem.MONTH_AMT | comma }}원</dd>
								</dl>
								<dl>
									<dt>설치주소</dt>
									<dd>{{ preOrderItem.ADDR1 }} {{ preOrderItem.ADDR2 }}</dd>
								</dl>
								<dl
									v-if="
										preOrderItem.combineAble === 0 || preOrderItem.TYPE === 'F'
									"
								>
									<dt>결합 불가 사유</dt>
									<dd class="warn" v-if="preOrderItem.TYPE === 'F'">
										{{ preOrderItem.MESSAGE }}
									</dd>
									<dd class="warn" v-else-if="preOrderItem.combineAble === 0">
										이미 결합 된 계정
									</dd>
								</dl>
								<dl v-else>
									<dt>결합 가능</dt>
									<dd>{{ preOrderItem.combineAble }}개</dd>
								</dl>
							</v-btn>
						</v-btn-toggle>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<!--// 기존 계정 선택 -->
				<!-- 결합할 상품 선택-->
				<v-expansion-panel class="accodion_select" v-if="newOrderOpen">
					<v-expansion-panel-header>
						<h3 class="sub_tit">결합할 상품 선택</h3>
						<p class="selected blue-primary">
							{{ newOrder && newOrder.prd.prod_nm }}
						</p>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-btn-toggle
							class="toggle_row"
							v-model="newOrderSelected"
							:mandatory="newOrder ? true : false"
						>
							<v-btn
								class="btn_white list"
								large
								v-for="(newOrderItem, newOrderIdx) in newOrderItems"
								:key="newOrderIdx"
								:disabled="
									newOrderItem.combineAble === 0 ||
										newOrderItem.combineYn === 'N'
								"
								@click="newOrderEvent(newOrderItem, newOrderIdx)"
							>
								<dl>
									<dt>상품명</dt>
									<dd>{{ newOrderItem.prd.leader_model_nm }}</dd>
								</dl>
								<dl>
									<dt>모델명</dt>
									<dd>{{ newOrderItem.prd.prod_nm }}</dd>
								</dl>
								<dl>
									<dt>판매구분</dt>
									<dd>{{ getSellType(newOrderItem.options.sellType) }}</dd>
								</dl>
								<dl
									v-if="['Z001', 'Z002'].includes(newOrderItem.esProd.ORD_TYPE)"
								>
									<dt>약정기간</dt>
									<dd>
										{{ newOrderItem.esProd.USE_DUTY_YEAR }}년({{
											newOrderItem.esProd.USE_DUTY_MONTH
										}}개월)
									</dd>
								</dl>
								<dl
									v-if="['Z001', 'Z002'].includes(newOrderItem.esProd.ORD_TYPE)"
								>
									<dt>월 렌탈료</dt>
									<dd>{{ newOrderItem.esProd.MONTH_AMT | comma }}원</dd>
								</dl>
								<dl
									v-if="
										['Z003', 'Z004', 'Z005'].includes(
											newOrderItem.esProd.ORD_TYPE
										)
									"
								>
									<dt>상품가격</dt>
									<dd>{{ newOrderItem.esProd.PRICE | comma }}원</dd>
								</dl>
								<dl>
									<dt>결합 가능</dt>
									<dd v-if="newOrderItem.combineYn === 'N'" class="warn">
										{{
											newOrderItem.combineNMsg
												? newOrderItem.combineNMsg
												: '결합불가상품'
										}}
									</dd>
									<dd v-else-if="newOrderItem.combineAble > 0">
										{{ newOrderItem.combineAble }}개
									</dd>
									<dd v-else class="warn">{{ newOrderItem.combineAble }}개</dd>
								</dl>
							</v-btn>
						</v-btn-toggle>
					</v-expansion-panel-content>
				</v-expansion-panel>
				<!--// 결합할 상품 선택 -->
			</v-expansion-panels>
			<!-- 제휴상품 수량 선택 -->
			<div class="u-numeric-block" v-if="prdQuantitySelectOpen">
				<h3 class="sub_tit">제휴 상품 수량 선택</h3>
				<MNumericUpDown
					v-model="prdQuantity"
					:combineAble="combineAble"
					:flag="flag"
				/>
			</div>

			<!--// 제휴상품 수량 선택 -->
		</div>

		<!-- 하단 버튼 -->
		<div class="cw-app-fixed">
			<div class="u-btns-group">
				<div class="column">
					<v-btn class="u-btn-text" depressed large @click="reset()">
						재검색
					</v-btn>
				</div>
				<div class="column">
					<v-btn
						class="u-btn-active"
						depressed
						large
						@click="addPrd()"
						:disabled="isDisableBtn"
					>
						상품 담기
					</v-btn>
				</div>
			</div>
		</div>
		<!--// 하단 버튼 -->
		<PopCowayLifeSolutionGuide ref="popCowayLifeSolutionGuide" />
	</div>
</template>

<script>
import filters from '@/mixins/filters'
import computeds from '@/mixins/computeds'
import { mapActions } from 'vuex'
import { fetchWebProdItemOptionList } from '@/apis/order.prd.api'
import {
	fetchAidPreOrdNumList,
	fetchProdListCheck
} from '@/apis/coway.life.api'
import { mapGetters } from 'vuex'
import { registerCallbackToApp } from '@/utils/navigation'
import { getSellType } from '@/utils/ec'
import PopCowayLifeSolutionGuide from '@/views/common/PopCowayLifeSolutionGuide'

export default {
	name: 'FuneralPrdDetail',
	mixins: [filters, computeds],
	components: {
		PopCowayLifeSolutionGuide
	},
	created() {
		this.initAppPage()
	},
	data() {
		return {
			prdQuantity: 1, // 상품 수량
			openedPanel: 0,
			prd: {},
			initPrdPrice: 0, // 일시불 초기 값, 초기화 시 사용 금액
			// 웹 아이템 관련 정보
			webProdPaymentMonthList: [],
			//제휴 결합 선택
			funeralCombineItem: {},
			funeralCombineItems: [
				{ code: 'new', text: '지금 함께 담은 코웨이 상품과 결합' },
				{ code: 'pre', text: '현재 사용 중인 기존계정과 결합' }
			],
			funeralCombineItemSelected: undefined,
			preOrderItems: [],
			preOrderSelected: undefined, // 기존주문번호 선택
			preOrder: '', // 기존주문번호,
			preOrderOpen: false,
			newOrderItems: [],
			newOrderSelected: undefined, // 신규주문 선택
			newOrder: '', // 신규주문,
			newOrderOpen: false,
			prdQuantitySelectOpen: false,
			oid: undefined,
			combineAble: 0,
			flag: 'detail',
			preCount: 0,
			isPreOrder: true
		}
	},
	computed: {
		...mapGetters({ verifyCustNo: 'verify/custNo' }),
		isDisableBtn() {
			// TODO 유효성 검사
			this.$log('버튼활성화')
			let result = true
			if (
				(this.funeralCombineItem.code === 'new' && this.newOrder) ||
				(this.funeralCombineItem.code === 'pre' && this.preOrder)
			) {
				result = false
			}

			return result
		}
	},
	async mounted() {
		this.prd = this.$route.params.prd
		this.initPrdPrice = this.prd?.price || 0
		await this.resetOption()
		await this.combineAbleCountCheck()
		const itemList = []
		this.newOrderItems.forEach(async v => {
			itemList.push({ MATNR: v.erpCd })
		})
		await fetchProdListCheck({
			I_SALE_CD: this.$store.getters['common/codyNo'],
			I_KUNNR: this.$store.getters['verify/custNo'],
			IT_PROD: itemList
		})
			.then(res => {
				const returnProd = res.resultObject.IT_RETURN_PROD
				this.newOrderItems.forEach(x => {
					x.combineNMsg = ''
					if (
						returnProd.length > 0 &&
						returnProd.some(v => v.MATNR === x.erpCd)
					) {
						// 렌탈일 경우 소유권도래기간 체크
						const ownerMonth = x.esProd?.OWNER_MONTH
						if (x.esProd.CONTRACT_ID === '2' && Number(ownerMonth) < 60) {
							x.combineYn = 'N'
							x.combineNMsg = '결합불가 - 소유권도래기간 5년 이내 상품'
						}
						// 기존계정의 제휴결합할인 승계일 때
						else if (x.isRerental === 'Y') {
							x.combineYn = 'N'
							x.combineNMsg = '결합불가 - 기존계정의 제휴결합할인 승계 예정'
						} else {
							x.combineYn = 'Y'
						}
					} else {
						x.combineYn = 'N'
					}
				})
			})
			.finally({})

		if (this.preOrderItems && this.preOrderItems.length < 1) {
			this.isPreOrder = false
		}

		const res = await fetchWebProdItemOptionList(this.prd.id)

		res.resultObject.result.forEach(v => {
			if (v.groupCd === 'payment_month') {
				this.webProdPaymentMonthList.push({ code: v.cd, text: v.cdNm })
			}
		})
	},

	methods: {
		...mapActions({ showSnackbar: 'common/showSnackbar' }),
		getSellType,

		reset() {
			this.$router.push('/order/prd/search')
		},

		async initAppPage() {
			registerCallbackToApp(
				{
					cbPhysicalBack: 'cbPhysicalBack'
				},
				this
			)
		},

		async cbPhysicalBack() {
			const ok = await this.$alert({
				message: '상품 검색 화면으로 이동하시겠습니까?',
				okText: '확인',
				cancelText: '취소'
			})
			if (ok) this.$router.push('/order/prd/search')
		},

		// 상품 추가
		async addPrd() {
			this.$store.dispatch('prd/setPrdChk', true)
			this.next()
		},

		// 해당 지국에서 관리되는 계정
		next() {
			let prd = {
				oid: crypto.randomUUID(),
				type: this.funeralCombineItem.code,
				preOrder: {},
				options: {
					quantity: this.prdQuantity, // 상품 수량
					paymentMonth: this.webProdPaymentMonthList[0].code
				},
				prd: this.prd,
				oidPrdIndex: 0,
				funeralPrdOid: crypto.randomUUID()
			}
			// 신규결합
			if (this.funeralCombineItem.code === 'new') {
				prd.oid = this.newOrder.oid
				prd.oidPrdIndex = this.newOrder.prdIdx
				prd.sellType = this.newOrder.options.sellType.code
			} else {
				prd.preOrder = this.preOrder
			}
			this.$store.dispatch('prd/addFuneralPrd', prd)
			this.reset()
		},

		// 제휴 결합 선택 이벤트
		async funeralCombineEvent(item, index) {
			//초기화
			await this.resetOption()

			if (item.code === 'new') {
				if (this.newOrderItems.length > 0) {
					this.newOrderOpen = true

					if (!this.newOrderItems.some(v => v.combineAble !== 0)) {
						this.displayAlert()
					} else {
						const result = this.newOrderItems
							.filter(v => v.combineAble !== 0)
							.every(
								x =>
									x.options.preOrder &&
									x.options.preOrder.AID_TYPE.length > 0 &&
									x.esProd.ORD_TYPE === 'Z002'
							)
						if (result) this.displayAlert()
					}
				} else {
					this.displayAlert()
				}
			} else if (item.code === 'pre') {
				if (this.preOrderItems && this.preOrderItems.length > 0) {
					this.preOrderOpen = true
				}
			}

			this.funeralCombineItem = item
			this.funeralCombineItemSelected = index
			this.openedPanel += 1
		},
		async displayAlert() {
			await this.$alert({
				message:
					'결합 가능한 상품이 담겨 있지 않습니다.<br /> 재검색 버튼을 눌러 코웨이 제품을 먼저 담아주세요.',
				okText: '확인'
			})
			return
		},
		//기존 주문번호 목록 불러오기
		async preOrderList() {
			const res = await fetchAidPreOrdNumList({
				I_CONTRACT_CUST_ID: this.$store.getters['verify/custNo']
			})
			const result = res.resultObject.E_RETURN
			if (result.TYPE === 'T') {
				this.preOrderItems = res.resultObject.T_LIST
			}
		},
		/**
		 * 기존주문번호 선택
		 */
		async preOrderEvent(item, index) {
			this.prdQuantity = 1
			this.prdQuantitySelectOpen = true
			this.preOrder = item
			this.preOrderSelected = index
			this.openedPanel = undefined
			this.combineAble = item.combineAble
		},
		//신규 주문 이벤트
		async newOrderEvent(item, index) {
			this.prdQuantitySelectOpen = true
			this.prdQuantity = 1
			this.combineAble = item.combineAble

			this.oid = item.oid
			this.newOrder = item
			this.newOrderSelected = index
			this.openedPanel = undefined
		},
		//초기화
		async resetOption() {
			this.preOrderOpen = false
			this.newOrderOpen = false

			this.preOrder = undefined
			this.newOrder = undefined

			this.preOrderSelected = undefined
			this.newOrderSelected = undefined
			this.funeralCombineItemSelected = undefined

			// this.preOrderItems = []
			// this.newOrderItems = []

			this.prdQuantitySelectOpen = false
			this.funeralCombineItem = {}
		},
		async combineAbleCountCheck() {
			// 결합 가능 개수 체크(신규)
			const funeralPrdList = this.$store.getters['prd/funeralPrdList']
			const MAX_CNT = 3
			let tempOrderItems = this.$store.getters['prd/prdList']

			tempOrderItems.forEach(v => {
				let i = 0
				for (i; i < v.options.quantity; i++) {
					let cnt = 0

					if (funeralPrdList && funeralPrdList.length > 0) {
						const funeralList = funeralPrdList.filter(x => x.oid === v.oid)
						const funeral = funeralList.filter(x => x.oidPrdIndex === i)
						funeral.forEach(x => {
							cnt += x.options.quantity
						})
					}
					const tmp = JSON.parse(JSON.stringify(v))
					tmp.options.quantity = 1
					tmp.combineAble = MAX_CNT - cnt
					tmp.prdIdx = i
					tmp.erpCd = v.prd.erp_cd
					tmp.isRerental =
						v.options.preOrder &&
						v.options.preOrder.AID_TYPE.length > 0 &&
						v.esProd.ORD_TYPE === 'Z002'
							? 'Y'
							: 'N'
					this.newOrderItems.push({ ...tmp })
				}
			})
			await this.preOrderList()

			// 결합 가능 개수 체크(기존계정)
			if (funeralPrdList && funeralPrdList.length > 0) {
				this.preOrderItems.forEach(z => {
					z.combineAble = MAX_CNT
					funeralPrdList.forEach(v => {
						let cnt = 0
						const funeralList = funeralPrdList.filter(
							x => x.preOrder.ORDER_NO === v.preOrder.ORDER_NO
						)

						funeralList.forEach(x => {
							cnt += x.options.quantity
						})

						if (z.ORDER_NO === v.preOrder.ORDER_NO) {
							z.combineAble = MAX_CNT - cnt
						}
					})
				})
			} else {
				this.preOrderItems.forEach(v => {
					v.combineAble = MAX_CNT
				})
			}
			this.sort()
		},
		//결합 상품 정렬
		sort() {
			// 결합 가능한 상품이 상단에 위치하도록 정렬
			this.preOrderItems
				.sort((a, b) => b.REMAIN_MONTH - a.REMAIN_MONTH)
				.sort(
					(a, b) =>
						(a.TYPE === 'F') - (b.TYPE === 'F') ||
						(a.combineAble === 0) - (b.combineAble === 0)
				)
			this.preOrderItems = this.preOrderItems.sort((a, b) => {
				if (
					a.TYPE === 'T' &&
					b.TYPE === 'T' &&
					a.combineAble !== 0 &&
					b.combineAble !== 0 &&
					a.REMAIN_MONTH === b.REMAIN_MONTH
				) {
					if (a.MONTH_AMT < b.MONTH_AMT) return 1
					if (a.MONTH_AMT > b.MONTH_AMT) return -1
					return 0
				}
			})

			this.newOrderItems
				.sort(
					(a, b) =>
						(a.combineAble === 0) - (b.combineAble === 0) ||
						(a.combineYn === 'N') - (b.combineYn === 'N')
				)
				.sort((a, b) => (a.isRerental === 'Y') - (b.isRerental === 'Y'))
		},
		openPopCowayLifeSolutionGuide() {
			this.$refs.popCowayLifeSolutionGuide.open(1)
		}
	}
}
</script>

import api from '@/apis/config'
const { post, get } = api

/**
 * 상품목록 조회
 * @param {*} params
 */
export const fetchPrds = async params => {
	const response = await post('/v1/02/01', {
		...params
	})
	return response
}

/**
 * 구매조건에 따른 선택사항 조회 - 상세 진입시
 * @param {*} params
 */
export const fetchPrdOpts01 = async params => {
	const response = await post('/v1/02/02/01', {
		...params
	})
	return response
}

/**
 * 구매조건에 따른 선택사항 조회 - 판매구분 선택시
 * @param {*} params
 */
export const fetchPrdOpts02 = async params => {
	const response = await post('/v1/02/02/02', {
		...params
	})
	return response
}

/**
 * 구매조건에 따른 선택사항 조회 - 관리유형 & (의무사용기간(약정기간)) 선택시
 * @param {*} params
 */
export const fetchPrdOpts03 = async params => {
	const response = await post('/v1/02/02/03', {
		...params
	})
	return response
}

/**
 * 이관신청 검증
 * @param {*} params
 */
export const postValidTransfer = async params => {
	const response = await post('/v1/02/03', {
		...params
	})
	return response
}

/**
 * 이관신청
 * @param {*} params
 */
export const postTransfer = async params => {
	const response = await post('/v1/02/04', {
		...params
	})
	return response
}

/**
 * 반납접수
 * @param {*} params
 */
export const returnReception = async params => {
	const response = await post('/v1/02/05', {
		...params
	})
	return response
}

/**
 * 홈체험 주문이력 리스트 조회
 * @param {String} customerNo: 고객번호
 */
export const fetchHomePreOrderList = async customerNo => {
	const response = await get(`/v1/02/07/${customerNo}`)
	return response
}

/**
 * 웹 상품 옵션 리스트 조회
 * @param {String} prodId: 웹 상품ID
 */
export const fetchWebProdItemOptionList = async prodId => {
	const response = await get(`/v1/02/08/${prodId}`)
	return response
}

<template>
	<div class="cw-app-wrapper">
		<div class="cw-app-module">
			<MStepper />
		</div>
		<div class="cw-app-container">
			<div class="pre-subs-box01">
				<h3>전자계약서 발송</h3>
				<div class="pre-notice-wrap" v-if="isCowayLifeSolution">
					<h4>코웨이 주문의 계약서 입니다.</h4>
					<p>
						제휴 주문(코웨이라이프)은 코웨이 전자계약서 발송을 완료한 뒤 이어서
						진행할 수 있습니다.
					</p>
				</div>
				<p class="mb-4" v-else>
					주문 고객님에게 주문 확인 및 계약서 서명을 위한
					<br />알림을 발송 합니다. 계약서와 주문내역을 확인 후 계약서 발송을
					눌러주세요.
				</p>
				<v-btn
					large
					default
					class="btn_secondary"
					:disabled="false"
					@click="signature()"
					v-if="custSelf"
				>
					<!-- v-show="['development'].includes(isDebugLabel)" -->
					계약서 미리보기
				</v-btn>
			</div>

			<div class="pre-subs-box03">
				<h4>주문내역</h4>
				<em>
					총<i>{{ orderList.length }}</i
					>개</em
				>
			</div>

			<div class="pre-subs-box05">
				<v-expansion-panels focusable v-model="panel" multiple>
					<v-expansion-panel v-for="(ord, i) in orderList" :key="i">
						<v-expansion-panel-header>
							<h5>
								주문<em>{{ i + 1 }}</em
								>.<i
									:class="{
										'ft-orange': canceledOrderChk(i) === false
									}"
									v-if="ord.CONTRACT_ID == '1'"
									>{{
										ord.GOODS_NM
											? ord.GOODS_NM
											: deliveryInfos[i].leaderModelNm
											? deliveryInfos[i].leaderModelNm
											: deliveryInfos[i].prodNm
									}}
									/ {{ ord.E_SALE_GUBUN }}</i
								>
								<i
									:class="{
										'ft-orange': canceledOrderChk(i) === false
									}"
									v-else
									>{{
										ord.GOODS_NM
											? ord.GOODS_NM
											: deliveryInfos[i].leaderModelNm
											? deliveryInfos[i].leaderModelNm
											: deliveryInfos[i].prodNm
									}}
									/ {{ ord.E_SALE_GUBUN }}({{
										ord.DUTY_USE_MONTH
											? ord.DUTY_USE_MONTH
											: getUseDutyMonth(ord)
									}})</i
								>
							</h5>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<dl>
								<dt>주문번호</dt>
								<dd>{{ ord.ORDER_NO }}</dd>
							</dl>
							<dl v-if="ord.funeralProdNm">
								<dt>제휴결합</dt>
								<dd>
									{{ ord.funeralProdNm }}
									<div class="btns" v-if="ord.funeralProdDetail">
										<v-btn class="btn-xs-size" @click="cowayLifeSolutionDetail"
											>주문상세</v-btn
										>
									</div>
								</dd>
							</dl>
							<dl>
								<dt>구매방식</dt>
								<dd>{{ ord.E_SALE_GUBUN }}</dd>
							</dl>
							<dl v-if="ord.GOODS_AMT > 0 && canceledOrderChk(i)">
								<dt>수납정보</dt>
								<dd>
									{{ ord.GOODS_AMT | comma }} 원 <br />{{
										ord.T_A0401_PAYMENT_LIST.filter(x => x.GUBUN === '2')[0]
											? ord.T_A0401_PAYMENT_LIST.filter(x => x.GUBUN === '2')[0]
													.CARDCOM_NM
											: ''
									}}
									{{
										getMaskedAccNo(
											ord.T_A0401_PAYMENT_LIST.filter(x => x.GUBUN === '2')[0]
												? ord.T_A0401_PAYMENT_LIST.filter(
														x => x.GUBUN === '2'
												  )[0].CARD_NO
												: ''
										)
									}}
								</dd>
							</dl>

							<dl v-if="ord.MONTH_AMT > 0 && canceledOrderChk(i)">
								<dt>정기결제</dt>
								<dd>
									{{ ord.MONTH_AMT | comma }} 원/월
									{{ ord.funeralProdNm ? '(제휴결합 할인 미반영 금액)' : ''
									}}<br />{{ ord.CARDCOM_NM ? ord.CARDCOM_NM : '' }}
									{{ ord.CARD_NO ? getMaskedAccNo(ord.CARD_NO) : '' }}
								</dd>
							</dl>
							<dl v-if="canceledOrderChk(i)">
								<dt>설치주소</dt>
								<dd>{{ ord.CITY1 }} {{ ord.STREET }}</dd>
							</dl>
							<dl v-if="canceledOrderChk(i)">
								<dt>설치일시</dt>
								<dd v-if="isPreContract" class="ft-blue">
									{{
										deliveryInfos[i].workerType !== 'EXPRESS'
											? ord.E_YYYY_MM + ' 배정 진행 가능'
											: '택배 배송'
									}}
								</dd>
								<dd v-else>
									{{
										deliveryInfos[i].workerType !== 'EXPRESS'
											? dateToBookingText({
													bookingDate: deliveryInfos[i].bookingDate,
													bookingSlot: deliveryInfos[i].bookingSlot,
													bookingSlotTime: deliveryInfos[i].bookingSlotTime
											  })
											: '택배 배송'
									}}
								</dd>
							</dl>
							<dl v-if="canceledOrderChk(i) === false">
								<dt>주문상태</dt>
								<dd>주문삭제</dd>
							</dl>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</div>
		</div>
		<div class="cw-app-fixed">
			<div class="u-btns-group">
				<div class="column">
					<v-btn
						large
						default
						class="u-btn-active"
						:disabled="false"
						@click="send()"
						v-if="custSelf"
					>
						계약서 발송
					</v-btn>
					<v-btn color="primary" class="btn_col2 col-12" @click="send()" v-else>
						인증번호 발송
					</v-btn>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {
	fetchEContract,
	sendAlimTalk,
	fetchCreateEcCookie
} from '@/apis/order.ec.api'
import { paymentInfo } from '@/apis/order.recp.api'
import { dateToBookingText } from '@/utils/schedule.js'
import { fetchCustSetup } from '@/apis/order.verify.api'
import {
	checkingStatus,
	fetchWebOrderInfoById,
	mOrderInfoByEtc,
	postWebOrderInfoById,
	postFuneralServiceInfoById,
	getFuneralProcessCheck
} from '@/apis/order.status.api'
import filters from '@/mixins/filters'
import { registerCallbackToApp, closePopView } from '@/utils/navigation'
import {
	getPhone,
	getMaskedAccNo,
	getPrdName,
	getSellType,
	getMessageByStatusCode
} from '@/utils/ec'
import {
	getFuneralSendOrderListParam,
	getFuneralSendPayInfoParam,
	getFuneralSendRcivInfoParam,
	getCowayLifeSolutionUserId
} from '@/utils/common.js'
import { notEqualIgnoreMasking, isParameterEmpty } from '@/utils/common'
import { fetchAidOrderCheck } from '@/apis/coway.life.api'
export default {
	mixins: [filters],
	props: {},
	data() {
		return {
			custSelf: false,
			name: '',
			phone: '',
			orderNo: '',
			validDate: '',
			panel: [],
			orderList: [],
			deliveryInfos: [],
			webOrderSum: {},
			mobOrderNo: this.$store.getters['verify/mobOrderNo'],
			orderStatusList: [],
			isPreContract: this.$store.state['verify'].customer.orderType === 'C',
			isCowayLifeSolution: false,
			funeralProducts: this.$store.getters['ec/funeralProducts'] || []
		}
	},
	created() {
		this.initAppPage()
		// window.$updateCowayLifeSolution = this.updateCowayLifeSolution.bind(this)
	},
	computed: {
		isDebugLabel() {
			return process.env.NODE_ENV
		}
	},
	async mounted() {
		if (this.isParameterEmpty(this.mobOrderNo)) return

		this.custSelf = this.$store.getters['verify/custSelf']
		this.phone = this.$store.getters['verify/custPhone']
		this.deliveryInfos = this.$store.getters['schedule/deliveryInfos']

		// 주문 이어서 하기시 store 값이 존재할 경우 sessionStorage에 store 저장
		if (this.custSelf) {
			sessionStorage.removeItem('store')
			sessionStorage.setItem('store', JSON.stringify(this.$store.state))
			this.$log(`주문번호: ${this.$store.getters['verify/mobOrderNo']}`)
		} else {
			// 계약서 미리보기 후 재진입시 sessionStorage 내 store 값 가져옴
			const storageStore = sessionStorage.getItem('store')
			this.$store.replaceState(JSON.parse(storageStore))
			this.custSelf = this.$store.getters['verify/custSelf']
		}

		let customerType = (this.name = this.$store.getters['verify/customerType'])
		if (customerType === 'L') {
			this.name = this.$store.getters['verify/bizName']
		} else {
			this.name = this.$store.getters['verify/custName']
		}

		// 계약확인 조회
		const res = await fetchEContract({
			mobOrderNo: this.mobOrderNo
		})
		this.orderList = res.resultObject.data[0].setup

		// [BEGIN] deliveryInfos PRODH9 저장
		// PRODH9 값은 MB2 계약확인 RFC를 통해 가져오므로
		// 최초 진입 시 deliveryInfos에 저장
		let deliveryInfosUpdateCheck = false
		this.deliveryInfos.forEach(v => {
			if (!v.PRODH9) {
				v.PRODH9 = this.orderList.filter(
					x => x.ORDER_NO === v.orderNo
				)[0].PRODH9
				deliveryInfosUpdateCheck = true
			}
		})

		const info = await fetchWebOrderInfoById(this.mobOrderNo)
		if (deliveryInfosUpdateCheck) {
			await this.$store.dispatch(
				'schedule/setDeliveryInfos',
				this.deliveryInfos
			)

			const webParams = res.resultObject
			webParams.step = '06' // 전자계약
			webParams.statusCode = '108' // 서명대기
			webParams.orderInfoJson = JSON.stringify(
				Object.assign(JSON.parse(info.resultObject.orderInfoJson), {
					deliveryInfos: this.deliveryInfos
				})
			)
			await postWebOrderInfoById(this.mobOrderNo, webParams)
		}
		// [END] deliveryInfos PRODH9 저장

		// 상조 결합 상품이 있고, 상조 주문의 상태가 409가 아닌 경우만 노출
		this.isCowayLifeSolution =
			this.funeralProducts.length > 0 &&
			info?.resultObject?.funeralStatusCode !== '409'
		// 상조 상태 주문대기일때만 웹 디비 상조 주문 정보 노출
		if (this.isCowayLifeSolution) {
			for (let order of this.orderList) {
				this.$set(order, 'funeralProdNm', '')
				this.$set(order, 'funeralProdDetail', false)
				if (this.funeralProducts.some(v => v.orderNo === order.ORDER_NO)) {
					const orderNoFuneralPrds = this.funeralProducts.filter(
						v => v.orderNo === order.ORDER_NO
					)
					if (orderNoFuneralPrds.length > 0) {
						const funeralProdNm =
							orderNoFuneralPrds.length === 1
								? orderNoFuneralPrds[0].prd.prodNm
								: orderNoFuneralPrds[0].prd.prodNm +
								  ' 외 ' +
								  (orderNoFuneralPrds.length - 1) +
								  '건'
						this.$set(order, 'funeralProdNm', funeralProdNm)

						// 상조 MB2 응답 값이 있는 경우
						if (order.T_AID_LIST.length > 0) {
							// 상조 전체 주문삭제가 아니라면 '주문상세' 버튼 노출
							if (
								order.T_AID_LIST.every(
									v => v.AID_STATUS !== '' && v.AID_STATUS !== '07'
								)
							) {
								this.$set(order, 'funeralProdDetail', true)
							}
							// 상조 전체 주문삭제라면 이름 미노출
							else if (order.T_AID_LIST.every(v => v.AID_STATUS === '07')) {
								this.$set(order, 'funeralProdNm', '')
							}
						}
					}
				}
			}
		}

		// 알림톡 발송용 주문번호 셋팅
		let ordNoStr = ''
		this.orderList.forEach((res, i) => {
			if (i === 0) {
				ordNoStr = `${res.ORDER_NO}`
			} else {
				ordNoStr = `${ordNoStr}, ${res.ORDER_NO}`
			}
		})
		this.orderNo = ordNoStr
		this.panel = [...Array(this.orderList.length).keys()].map((k, i) => i)

		//주문상태조회
		await this.createOrderStatusList()

		// 고객입력 정보와 SAP정보 비교
		await this.checkCustInfo()
	},
	methods: {
		getSellType,
		getPrdName,
		getPhone,
		getMaskedAccNo,
		dateToBookingText,
		isParameterEmpty,
		alertMessage() {
			this.$alert({
				message:
					'고객이 직접 등록한 정보(결제수단 및 설치처)와 일치하지 않은 내용이 있습니다. 원활한 계약 진행을 위해 고객에게 확인 후 진행해 주세요.'
			})
		},
		goBackPage() {
			this.$router.go(-1)
		},
		signature() {
			const mobOrderNo = this.$store.getters['verify/mobOrderNo'] // 웹주문번호

			fetchCreateEcCookie({
				webOrderNo: mobOrderNo,
				phoneNumber: this.phone
			})
				.then(res => {
					const { eMobOrderNo } = res.resultObject
					const url = `${process.env.VUE_APP_NEW_SIGN_URL}/smarteform/reqMain/requestRentalView.jcg?zsndNo=${eMobOrderNo}&sysId=M&dmId=${process.env.VUE_APP_DMID}`
					window.open(url)
				})
				.catch(() => {
					this.$alert({
						message:
							'일시적인 통신 장애로 서비스에 접속할 수 없습니다. 문제가 계속된다면, 담당자에게 문의해 주세요.'
					})
				})
		},
		canceledOrderChk(i) {
			if (
				this.orderList.length === this.orderStatusList.length &&
				this.orderStatusList[i].ORDER_STATUS === '203'
			) {
				return false
			} else {
				return true
			}
		},
		getUseDutyMonth(ord) {
			let month = ''
			this.deliveryInfos.forEach(v => {
				if (v.orderNo === ord.ORDER_NO) {
					month = v.rentalYears
				}
			})
			return month + '개월'
		},

		debugUrl(userKey) {
			this.$log(
				'url with userKey:',
				`${process.env.VUE_APP_FRT_URL}/order/ec/cust/pass?userKey=${userKey}`
			)
		},
		async createOrderStatusList() {
			const orderNoList = []
			for (const order of this.orderList) {
				orderNoList.push(order.ORDER_NO)
			}

			const response = await checkingStatus({
				saleCd: this.$store.getters['common/codyNo'],
				kunnr: this.$store.getters['verify/custNo'],
				orderNos: orderNoList
			})

			this.orderStatusList = response.resultObject.T_ORDER_STATUS
		},
		async allCanceledOrderChkAndUpdate() {
			const cancelYn = this.orderStatusList.every(v => v.ORDER_STATUS === '203')
			this.$log('all order status cancel : ', cancelYn)
			if (cancelYn) {
				const res = await fetchWebOrderInfoById(this.mobOrderNo)
				const webParams = res.resultObject
				webParams.statusCode = '203'

				await postWebOrderInfoById(this.mobOrderNo, webParams)

				const msg = getMessageByStatusCode('203')
				await this.$alert({
					message: msg
				})
			}

			return cancelYn
		},
		async sendSelf() {
			this.$log('sendSelf')
			let alertRes = false
			alertRes = await this.$alert({
				message: `${this.getPhone(
					this.phone
				)}으로 고객 서명을 위한 알림을 발송했습니다.`
			})

			if (alertRes) {
				const info = await fetchWebOrderInfoById(this.mobOrderNo)
				const payList = this.$store.getters['pay/payProducts']
				const preOrderAidTypeCheck = payList.some(
					v =>
						v.options.preOrder &&
						v.options.preOrder.AID_TYPE &&
						v.options.preOrder.AID_TYPE !== ''
				)
				let reqCd = '1'
				reqCd =
					preOrderAidTypeCheck || info?.resultObject?.funeralType === 'N'
						? 'B'
						: reqCd

				let prdNm = this.orderList[0] ? this.orderList[0].GOODS_NM : '제품명'
				let count =
					this.orderList[0] && this.orderList[0].length > 0
						? this.orderList[0].length - 1
						: ''
				const atParams = {
					reqCd: reqCd, // 1 : 계약서발송, 2 : 주문완료, 3 : 서명완료재진입, B: 상조결합되어있는 경우의 계약서 발송
					gubun: this.custSelf ? '1' : '2', // 1 : 고객직접, 2 : 코디대행
					name: this.name,
					phone: this.phone,
					orderNo: this.orderNo, // TODO orderList에서 추출
					url: `${process.env.VUE_APP_FRT_URL}/order/ec/cust/pass`,
					orderInfoId: this.$store.getters['verify/mobOrderNo'], // 웹주문번호
					customerNo: this.$store.getters['verify/custNo'],
					codyNo: this.$store.getters['common/codyNo'],
					codyPhoneNo: this.$store.getters['common/codyPhoneNo'],
					goodsNm: prdNm,
					goodsInfo: count !== '' ? `${prdNm} 외 ${count}대` : `${prdNm}`,
					onceAmt: this.$options.filters.comma(
						this.webOrderSum.totalSumOfRecpAmt
					),
					monthAmt: this.$options.filters.comma(
						this.webOrderSum.totalSumOfMonthAmt
					)
				}

				const atRes = await sendAlimTalk(atParams)
				if (atRes.resultObject.userKey) {
					// 디버깅용 로그출력
					this.debugUrl(atRes.resultObject.userKey)
					this.$store.dispatch('ec/setUserKey', atRes.resultObject.userKey)
					// const info = await fetchWebOrderInfoById(this.mobOrderNo)
					// 모바일 주문 진행현황
					if (
						this.isCowayLifeSolution &&
						info?.resultObject?.funeralStatusCode === '401' // 주문대기 상태일때만 노출
					) {
						// 상조 주문이 있는 경우
						this.$confirm({
							message:
								'이어서 제휴 상품 주문(코웨이라이프)을 진행 하시겠습니까?',
							okText: '이어서 진행',
							cancelText: '다음에 하기'
						}).then(res => {
							if (res) {
								// 이어서 진행
								this.nextCowayLifeSolution()
							} else {
								// 다음에 하기
								this.$router.push({ name: 'order-status' })
							}
						})
					} else {
						this.$router.push({ name: 'order-status' })
					}
				} else {
					this.$log(JSON.stringify(atRes))
				}
			}
		},
		async send() {
			if (this.isParameterEmpty(this.mobOrderNo)) return
			await this.createOrderStatusList()
			const allCanceledOrder = await this.allCanceledOrderChkAndUpdate()
			if (allCanceledOrder) {
				this.$router.push({ name: 'order-status' })
				return
			}

			let arrParam = {
				id: this.$store.getters['verify/mobOrderNo'], // 웹주문번호
				statusCode: '108'
			}
			const res = await mOrderInfoByEtc(arrParam)
			let msg = undefined
			if (res.resultObject.statusCode !== '108') {
				msg = getMessageByStatusCode(res.resultObject.statusCode)
			}
			if (msg !== undefined) {
				await this.$alert({
					message: msg
				})
				sessionStorage.removeItem('customer')
				this.$router
					.push({
						name: 'order-status',
						query: {
							user_no: sessionStorage.getItem('codyNoEnc'),
							dept_cd: this.$store.getters['common/deptCd'],
							type: this.$store.getters['common/type']
						}
					})
					.catch()
			} else {
				await this.sendSelf()
			}
		},
		async initAppPage() {
			registerCallbackToApp(
				{
					cbPhysicalBack: 'cbPhysicalBack',
					isNotUseDsHeaderPhysicalBack: true
				},
				this
			)
		},
		async cbPhysicalBack() {
			const ok = await this.$alert({
				message: '홈화면으로 이동하시겠습니까?',
				okText: '확인',
				cancelText: '취소'
			})
			if (ok) closePopView()
		},
		async checkCustInfo() {
			// 결제정보 체크
			// PAY_TYPE (R: 정기결제, O: 수납), CONTRACT_ID (1: 일시불, 2:렌탈)
			const info = await fetchWebOrderInfoById(this.mobOrderNo)
			const sum = JSON.parse(info.resultObject.orderInfoJson)
			this.webOrderSum = sum.PAY_SUMMARY_AMT

			const payInfo = (
				await paymentInfo(this.mobOrderNo, { zwebRecp: '04' })
			).resultObject.data.PAYMENT_LIST.filter(x => x.MAIN_PAYMENT)

			if (payInfo.length > 0) {
				// 수납결제
				const recpInfo = sum.recp.recpParams
				if (sum.PAY_SUMMARY_AMT.totalSumOfRecpAmt > 0 && recpInfo) {
					const pay = payInfo.filter(
						pay => pay.PAY_TYPE === 'O' && pay.AF_CARD_NO.length > 0
					)[0]

					// AF_CARD_NO 값이 없는 경우 비교 X
					if (pay && recpInfo) {
						if (
							notEqualIgnoreMasking(
								pay.AF_CARD_NO,
								recpInfo.cardn ?? recpInfo.suacc
							)
						) {
							this.$log('수납결제 불일치')
							this.alertMessage()
							return
						}
					}
				}

				// 정기결제 (일시불인 경우 비교 X)
				if (sum.PAY_SUMMARY_AMT.totalSumOfMonthAmt > 0) {
					const pay = payInfo.filter(
						pay => pay.PAY_TYPE === 'R' && pay.AF_CARD_NO.length > 0
					)[0]
					const orderList = this.orderList.filter(
						x => x.CONTRACT_ID === '2' && x.CARD_NO.length > 0
					)

					if (
						orderList.findIndex(x =>
							notEqualIgnoreMasking(pay.AF_CARD_NO, x.CARD_NO)
						) > -1
					) {
						this.$log('정기결제 불일치')
						this.alertMessage()
						return
					}
				}
			}

			// 설치처정보 체크
			const setupInfo = (await fetchCustSetup(this.mobOrderNo)).resultObject
				.data.setup
			if (setupInfo) {
				if (
					this.orderList.findIndex(x =>
						notEqualIgnoreMasking(setupInfo.telNumber, x.TEL_NUMBER)
					) > -1
				) {
					this.$log('휴대폰번호 불일치')
					this.alertMessage()
					return
				}

				if (
					this.orderList.filter(
						x =>
							x.CITY1.trim() === setupInfo.addr1.trim() &&
							x.STREET.trim() === setupInfo.addr2.trim()
					).length !== this.orderList.length
				) {
					this.$log('주소 불일치')
					this.alertMessage()
					return
				}
			}
		},
		async nextCowayLifeSolution() {
			// 웹 주문번호로 상조 진행되었는지 체크
			const webNoFuneralChk = await getFuneralProcessCheck(this.mobOrderNo, {
				codyNo: this.$store.getters['common/codyNo']
			})
			if (webNoFuneralChk?.resultObject?.TYPE === 'T') {
				await this.$alert({
					message: '상태가 업데이트되었습니다.'
				})

				const codyQuery = {
					tabCode: 'L'
				}
				this.$store.dispatch('common/setUserInfo', codyQuery)
				this.$router.push({ name: 'order-status' })
				return
			}

			const order = await fetchWebOrderInfoById(this.mobOrderNo)
			const funeralType = order.resultObject.funeralType
			let info = JSON.parse(order.resultObject.orderInfoJson)
			// [BEGIN] 상조 결합이 있을 경우 체크
			const continueChkOrderNos = []
			let funeralValidFailMsg = undefined
			if (this.isCowayLifeSolution) {
				for (let funeralProduct of this.funeralProducts) {
					const reqOrderNo =
						funeralProduct.type === 'new'
							? funeralProduct.orderNo
							: funeralProduct.preOrder?.ORDER_NO
					const iStep = funeralProduct.type === 'new' ? 'B' : 'A'
					if (continueChkOrderNos.some(v => v === reqOrderNo)) continue
					const res = await fetchAidOrderCheck({
						I_ORDER_NO: reqOrderNo,
						I_STEP: iStep //  'A' 기존주문 'B' 신규주문
					})

					continueChkOrderNos.push(reqOrderNo)
					const resObj = res.resultObject
					if (resObj.E_RETURN.TYPE !== 'T') {
						funeralValidFailMsg = `${reqOrderNo}가 이미 결합된 주문으로 확인되어 주문 불가합니다. 판매처로 문의해 주세요.`
						break
					}
				}
			}
			if (funeralValidFailMsg) {
				if (funeralType) {
					//상조 주문 상태 변경
					const funeralInfoParam = {
						statusCode: '419', // 상조 주문불가
						type: funeralType // P: 기존결합, N: 신규결합, 신규결합+기존결합, NA: 알신규 + 기존계정 결합
					}
					await postFuneralServiceInfoById(this.mobOrderNo, funeralInfoParam)

					if (funeralType !== 'NA') {
						const webParams = order.resultObject
						webParams.statusCode = '201' // 환가전 주문불가
						info = Object.assign(info, {
							inaccessMsg: {
								message: funeralValidFailMsg
							}
						})
						webParams.orderInfoJson = JSON.stringify(info)

						await postWebOrderInfoById(this.mobOrderNo, webParams)
					}
				}
				await this.$alert({
					message: funeralValidFailMsg
				})

				const codyQuery = {
					tabCode: 'L'
				}
				this.$store.dispatch('common/setUserInfo', codyQuery)
				this.$router.push({ name: 'order-status' })
				return
			}
			// [END] 상조 결합이 있을 경우 체크

			const cowayLifeUserId = getCowayLifeSolutionUserId(
				this.$store.getters['common/type']
			)
			sessionStorage.setItem(
				'cowayLifeSolution',
				JSON.stringify({
					mobOrderNo: this.mobOrderNo,
					userId: cowayLifeUserId
				})
			)
			const routerData = this.$router.resolve({
				name: 'coway-life-solution'
			})
			let rcivInfo = {}
			if (info.recp && info.recp.recpInfos) {
				// 수납은 카드만 가능
				const recpInfo = info.recp.recpInfos
				if (
					recpInfo.mthd === 'BB' ||
					recpInfo.mthd === '02' ||
					recpInfo.mthd === '12'
				) {
					rcivInfo = getFuneralSendRcivInfoParam(
						this.$store.getters['recp/recpParams']
					)
				}
			}

			const itPaymentArr = this.$store.getters['pay/paymentOrders']
			let payInfo = {}
			if (itPaymentArr.length > 0) {
				payInfo = getFuneralSendPayInfoParam(itPaymentArr[0])
			}

			const cond = getFuneralSendOrderListParam(this.funeralProducts)
			const cowayLifeWindow = window.open(routerData.href, '_blank')
			// [BEGIN] 상조 주문 정보 postMessage로 전달
			const sendPostMessage = e => {
				if (e.data === 'cowayLifePopLoad' && cowayLifeWindow) {
					cowayLifeWindow.postMessage(
						{ funeralProductsParam: { cond, payInfo, rcivInfo } },
						'*'
					)
					window.removeEventListener('message', sendPostMessage, false)
					const codyQuery = {
						tabCode: 'L'
					}
					this.$store.dispatch('common/setUserInfo', codyQuery)
					this.$router.push({ name: 'order-status' })
				}
			}
			window.addEventListener('message', sendPostMessage)
			// [END] 상조 주문 정보 postMessage로 전달
		},
		// 상조 주문 상세
		async cowayLifeSolutionDetail() {
			const cowayLifeUserId = getCowayLifeSolutionUserId(
				this.$store.getters['common/type']
			)
			sessionStorage.setItem(
				'cowayLifeSolution',
				JSON.stringify({
					mobOrderNo: this.mobOrderNo,
					userId: cowayLifeUserId
				})
			)
			const routerData = this.$router.resolve({
				name: 'coway-life-solution'
			})
			const cowayLifeWindow = window.open(routerData.href, '_blank')
			const funeralDetailParam = {
				closeUpdate: false
			}
			const sendPostMessage = e => {
				if (e.data === 'cowayLifePopLoad' && cowayLifeWindow) {
					cowayLifeWindow.postMessage(
						{ funeralDetail: funeralDetailParam },
						'*'
					)
					window.removeEventListener('message', sendPostMessage, false)
				}
			}
			window.addEventListener('message', sendPostMessage)
		}
		// updateCowayLifeSolution(result) {
		// 	sessionStorage.removeItem('cowayLifeSolution')
		// 	this.$log(result)
		// 	// 확인 : 주문현황
		// 	const codyQuery = {
		// 		tabCode: 'L'
		// 	}
		// 	this.$store.dispatch('common/setUserInfo', codyQuery)
		// 	this.$router.push({
		// 		name: 'order-status'
		// 	})
		// }
	}
}
</script>

<style scoped lang="scss"></style>

<template>
	<v-dialog
		v-model="dialog"
		:max-width="options.width"
		@keydown.esc="cancel"
		scrollable
		:persistent="options.persistent"
		fullscreen
	>
		<div class="full_dialog_header">
			<h2 class="tit">{{ options.statName }}</h2>
			<v-btn depressed icon plain class="btn_right" @click="close"
				><v-icon dark>
					mdi-window-close
				</v-icon></v-btn
			>
		</div>

		<div class="dialog_all_page expand">
			<div class="infor_area">
				<div class="tag">{{ options.custType }}</div>

				<h3 class="name" v-if="options.custType === '개인사업자'">
					<!-- 개인사업자 인증전 : 고객명, 인증 후 사업자명 -->
					<template v-if="options.stat.info.customer.bizName">
						{{ options.stat.info.customer.bizName }}
					</template>
					<templete v-else>
						{{ options.stat.info.customer.name }}
					</templete>
				</h3>

				<h3 class="name" v-else>{{ options.name }}</h3>
				<!--// 개인사업자 인증전 : 고객명, 인증 후 사업자명 -->
				<div class="flex">
					<p class="data">
						<span class="primary--text">{{ options.sellType }}</span>
						<span>{{ options.goodsNm }}</span>
						<template
							v-if="
								options.stat !== null &&
									options.stat.info.prdList &&
									getProductListCnt(options.stat.info.prdList) > 0
							"
						>
							외 {{ getProductListCnt(options.stat.info.prdList) }}건
						</template>
					</p>
				</div>
				<div class="flex">
					<p class="text_gray">{{ options.address }}</p>
				</div>
				<p class="text_gray">{{ getPhone(options.mob) }}</p>
			</div>

			<div
				class="u-desc-block center mt-5"
				v-if="options.statusCd === '201' && options.stat.info.inaccessMsg"
			>
				<p
					class="u-desc-block__md"
					v-html="options.stat.info.inaccessMsg.message"
				></p>
			</div>

			<!-- 수납금 결제 실패시 출력 정보 -->
			<div v-if="options.statusCd === '106'">
				<h2 class="mt-8">수납금 결제 실패</h2>
				<span class="txt_body1 "
					>아래의 사유로 수납금 결제가 실패하였습니다.<br />
					고객에게 확인 후 '결제 재확인'을 진행해 주세요.</span
				>

				<h3 class="mt-5">결제 요청 정보</h3>
				<div class="info_list mt-2">
					<dl>
						<dt>{{ recp.AF_PAY_MTHD === '11' ? '예금주' : '명의자' }}</dt>
						<dd>{{ recp.AF_OWNER_NM }}</dd>
					</dl>
					<dl>
						<dt>{{ recp.AF_PAY_MTHD === '11' ? '은행' : '카드사' }}</dt>
						{{ recp.AF_BANK_CD_NM }}
					</dl>
					<dl>
						<dt>{{ recp.AF_PAY_MTHD === '11' ? '계좌번호' : '카드번호' }}</dt>
						<dd>{{ getMaskedAccNo(recp.AF_CARD_NO) }}</dd>
					</dl>
					<dl>
						<dt>거부사유</dt>
						<dd>{{ msg }}</dd>
					</dl>
				</div>
			</div>
			<!--// 수납금 결제 실패시 출력 정보 -->
			<div class="info_message my-8">
				{{ codyMatchSuccMessage }}
			</div>
			<div class="bottom_box" v-if="this.disabledFrCodeCheck">
				<v-btn
					color="primary"
					class="col_1_small"
					@click="receipt"
					v-if="options.statusCd === '106'"
				>
					결제 재확인
				</v-btn>
				<v-btn
					color="primary"
					class="col_1_small"
					@click="ok"
					v-else-if="!this.isCustSelfPay && options.statusCd === '102'"
				>
					주문 이어서 하기
				</v-btn>
				<v-btn
					color="primary"
					class="col_1_small"
					@click="ok"
					v-else-if="!this.isCustSelfPay && options.statusCd !== '102'"
				>
					주문 이어서 하기
				</v-btn>
				<v-btn color="primary" class="col_1_small" @click="alim" v-else>
					납부 및 전자계약서 서명 알림 재발송
				</v-btn>
				<v-btn
					depressed
					height="20"
					class="btn_underline my-6"
					@click="cancelOrder"
					v-if="
						options.stat && !['201', '106'].includes(options.stat.statusCode)
					"
				>
					주문삭제
				</v-btn>
			</div>
		</div>
	</v-dialog>
</template>
<script>
import filters from '@/mixins/filters'
import { mapActions } from 'vuex'
import { getPhone, getMaskedAccNo, getMessageByStatusCode } from '@/utils/ec'
import { fetchCustCheckSelf } from '@/apis/order.verify.api'
import { bankInstantTransfer, cardTransfer } from '@/apis/order.recp.api'
import {
	fetchEContract,
	sendAlimTalk,
	paymentRecpCheck
} from '@/apis/order.ec.api'
import {
	codyMatchingValidate,
	postWebOrderInfoById,
	fetchWebOrderInfoById,
	updateCheckingSchedule,
	checkingStatus
} from '@/apis/order.status.api'
import { getPreContractSalesChk } from '@/apis/pre.contract.api'

export default {
	name: 'OrderStatusDialog',
	mixins: [filters],
	data: () => ({
		dialog: false,
		resolve: null,
		reject: null,
		options: {
			persistent: false,
			cancelOrder: false,
			stat: null
		},
		recp: {},
		disabledFrCodeCheck: true,
		codyMatchSuccMessage: '',
		msg: '',
		valid: false,
		isCustSelfPay: false,
		sum: {},
		webParams: {},
		webOrderSum: {},
		prdList: [],
		deliveryInfos: [],
		orderStatusList: [],
		isPreContract: false
	}),
	computed: {},

	methods: {
		getPhone,
		getMaskedAccNo,
		...mapActions({ showSnackbar: 'common/showSnackbar' }),
		open(options) {
			this.isCustSelfPay = false //주문 이어서 하기
			this.disabledFrCodeCheck = true //사기주문 체크
			this.valid = false
			this.deliveryInfos = this.$store.getters['schedule/deliveryInfos']
			this.isPreContract =
				this.$store.state['verify'].customer.orderType === 'C'
			// 주문불가 상태에서 미납/연체와 가해약만 주문이어서하기 가능
			if (
				options.stat.info.inaccessMsg &&
				options.stat.info.inaccessMsg.rtnCode !== 'OV' &&
				options.stat.info.inaccessMsg.rtnCode !== 'FH'
			) {
				this.disabledFrCodeCheck = false
			}

			if (this.$store.getters['recp/payRecp']) {
				this.recp = this.$store.getters['recp/payRecp']
			} else {
				this.recp.AF_OWNER_NM = ''
				this.recp.AF_BANK_CD_NM = ''
				this.recp.AF_CARD_NO = ''
			}
			if (options.stat.info.recp) {
				this.msg = options.stat.info.recp.recpInfos.errMsg
				this.isCustSelfPay = options.stat.info.recp.isCustSelfPay
			} else {
				this.msg = ''
			}
			this.dialog = true
			this.options = Object.assign(this.options, options)
			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},

		// 상품 갯수 카운트
		getProductListCnt(prdList) {
			let result = 0
			prdList.forEach(v => {
				result += Number(v.options.quantity)
			})
			return result - 1
		},
		async ok() {
			if (this.options.stat.statusCode === '102') {
				this.resolve(true)
				this.dialog = false
			} else if (this.options.stat.statusCode === '201') {
				let params = {}
				let customer = this.options.stat.info.customer
				let inaccessMsg = this.options.stat.info.inaccessMsg

				this.$log('inaccessMsg', inaccessMsg)
				this.$log('customer', customer)

				if (customer.custType === 'P') {
					params = {
						custType: customer.custType,
						bahns: inaccessMsg.bahns,
						name: inaccessMsg.name,
						name4: inaccessMsg.name,
						sex: inaccessMsg.sex,
						mob: inaccessMsg.mob,
						salesCd: this.$store.getters['common/codyNo']
					}
				}

				if (customer.custType === 'L') {
					params = {
						custType: customer.custType,
						bahns: inaccessMsg.bahns,
						name: inaccessMsg.name,
						name4: inaccessMsg.name4,
						sex: inaccessMsg.sex,
						mob: inaccessMsg.mob,
						katr6: inaccessMsg.katr6,
						usrLtdNo: customer.bizNo,
						salesCd: this.$store.getters['common/codyNo']
					}
				}

				fetchCustCheckSelf(params).then(async chkRes => {
					let rtn = chkRes.resultObject.data.E_RETURN

					if (rtn.TYPE === 'F') {
						if (rtn.CODE === 'OV' || rtn.CODE === 'FR') {
							// 미납 연체 내역
							this.$alert({
								message: '영업정보 시스템을 통해 미납/연체금액을 납부해주세요.'
							})
						} else if (rtn.CODE === 'FH') {
							// 가해약 고객
							this.$alert({
								message: '가해약 고객은 주문접수가 불가합니다.'
							})
						} else if (rtn.CODE === 'FI') {
							// 대손 고객
							this.$alert({
								message: '대손 고객은 주문접수가 불가합니다.'
							})
						} else if (rtn.CODE === 'ICB') {
							// 휴페업(미등록) 개인사업자 고객
							this.$alert({
								message: rtn.MESSAGE
							})
						} else {
							this.$alert({
								message: rtn.MESSAGE
							})
						}
					} else {
						this.resolve(true)
						this.dialog = false

						const mobOrderNo = this.$store.getters['verify/mobOrderNo']
						await codyMatchingValidate({
							webOrderNo: mobOrderNo,
							empNo: customer.codyNo,
							customerTelNo: customer.phone
						}).catch(error => this.$log(error))

						fetchWebOrderInfoById(mobOrderNo).then(res => {
							let webParams = res.resultObject
							let json = JSON.parse(webParams.orderInfoJson)
							webParams.step = '02' // 상품선택
							webParams.statusCode = '102' // 인증완료
							this.webParams.orderInfoJson = JSON.stringify(json)

							postWebOrderInfoById(mobOrderNo, webParams)
						})
					}
				})
			} else if (this.options.stat.statusCode === '108') {
				await this.createOrderStatusList()
				const allCanceledOrder = await this.allCanceledOrderChkAndUpdate()
				if (allCanceledOrder) {
					this.dialog = false
					this.$emit('reload')
					return
				}
				this.resolve(true)
				this.dialog = false
			} else {
				// 사전계약 관리 판매인 체크
				const preContractSaleCheck = await this.preContractSaleChk()
				if (!preContractSaleCheck) {
					this.dialog = false
					this.$emit('reload')
					return
				} else {
					this.resolve(true)
					this.dialog = false
				}
			}
		},

		async cancelOrder() {
			if (!this.options.cancelOrder) {
				const res = await this.$alert({
					message:
						'주문을 삭제하면 재진행은 불가합니다. 주문을 삭제 하시겠습니까?',
					okText: '예',
					cancelText: '아니오'
				})
				if (res) {
					await this.saveWebOrderInfo('203')
					this.resolve(false)
					this.dialog = false
					this.$emit('reload')
				}
			} else {
				this.resolve(false)
				this.dialog = false
			}
		},
		close() {
			this.dialog = false
		},
		async alim() {
			const res = await this.$alert({
				message: '납부 및 전자계약 서명 문자를 발송하시겠습니까?',
				okText: '발송',
				cancelText: '취소'
			})
			if (res) {
				// 계약확인 조회
				const params = {
					mobOrderNo: this.$store.getters['verify/mobOrderNo']
				}
				const info = await fetchWebOrderInfoById(params.mobOrderNo)
				this.webParams = info.resultObject
				this.sum = JSON.parse(this.webParams.orderInfoJson)
				this.webOrderSum = this.sum.PAY_SUMMARY_AMT

				const res = await fetchEContract(params)
				const orderList = res.resultObject.data[0].setup
				const sumAmt = res.resultObject.data[0].sumAmt
				sumAmt.MONTH_AMT_DC = this.sum.MONTH_AMT_DC
				sumAmt.MONTH_AMT_TOTAL = sumAmt.MONTH_AMT - sumAmt.MONTH_AMT_DC
				const webOrderSum = this.sum.PAY_SUMMARY_AMT
				this.$log('webOrderSum', this.sum.MONTH_AMT_DC)

				let prdNm = orderList[0] ? orderList[0].GOODS_NM : '제품명'
				let count = orderList && orderList.length > 1 ? orderList.length - 1 : 0
				let planDt = orderList[0].FIRST_DT ?? ''

				let ordNoStr = orderList[0] ? orderList[0].ORDER_NO : '주문번호'
				let atParams = {
					reqCd: 3, // 1 : 계약서발송, 2 : 주문완료, 3 : 서명완료재진입
					gubun: '1', // 1 : 고객직접, 2 : 코디대행
					name: this.$store.getters['verify/custName'],
					phone: this.$store.getters['verify/custPhone'],
					orderNo: count > 0 ? `${ordNoStr} 외 ${count}건` : `${ordNoStr}`,
					orderInfoId: params.mobOrderNo,
					customerNo: this.$store.getters['verify/custNo'],
					codyNo: this.$store.getters['common/codyNo'],
					codyPhoneNo: this.$store.getters['common/codyPhoneNo'],
					goodsNm: prdNm,
					goodsInfo: count > 0 ? `${prdNm} 외 ${count}대` : `${prdNm}`,
					onceAmt: this.$options.filters.comma(webOrderSum.totalSumOfRecpAmt),
					monthAmt: this.$options.filters.comma(webOrderSum.totalSumOfMonthAmt),
					planDt: planDt
				}

				atParams.url = `${process.env.VUE_APP_FRT_URL}/order/ec/cust/pass` // 고객납부 링크

				await sendAlimTalk(atParams)
			}
		},
		async confirmBooking(mobOrderNo) {
			const res = await fetchWebOrderInfoById(mobOrderNo)
			let webParams = res.resultObject
			let order = JSON.parse(webParams.orderInfoJson)
			const funeralProducts = order.funeralProducts || []

			if (this.isPreContract || funeralProducts.length > 0) {
				const updateStatus = this.isPreContract ? '112' : '114'
				await this.saveWebOrderInfo(updateStatus)
				await this.showSnackbar({
					msg: '수납금 결제가 완료되었습니다.',
					color: '#222'
				})
				this.resolve(true)
				this.dialog = false
				return
			}

			// deliveryInfos 업데이트
			const deliveryParams = { deliveryInfos: [...this.deliveryInfos] }

			const result = await updateCheckingSchedule(mobOrderNo, deliveryParams)

			if (result.resultObject.statusCode === '109') {
				await this.showSnackbar({
					msg: '수납금 결제 및 배정이 완료되어, 주문이 확정되었습니다.',
					color: '#222'
				})
				this.resolve(true)
			} else {
				await this.showSnackbar({
					msg:
						'수납금 결제는 완료되었으나, 배정이 실패되었습니다. <br /> 배정을 다시 진행해주세요.',
					color: '#222'
				})
				this.resolve(false)
			}
			this.dialog = false
		},
		async receipt() {
			await this.createOrderStatusList()
			const allCanceledOrder = await this.allCanceledOrderChkAndUpdate()
			if (allCanceledOrder) {
				this.dialog = false
				this.$emit('reload')
				return
			}

			const recpParams = this.$store.getters['recp/recpParams']
			const mobOrderNo = this.$store.getters['verify/mobOrderNo']

			recpParams.zwebRecp = '07' // 서명 후 이체는 '04' -> '07'
			recpParams.salesApp = this.$store.getters['common/app'] // 앱 구분자 추가
			recpParams.salesType = this.$store.getters['common/type']
			let transferResponse = {}

			await paymentRecpCheck(mobOrderNo)
				.then(async RES => {
					const result = RES.resultObject.data.E_RETURN
					this.$log('RES ===>>>', result)

					// 수납이 확인 되었음
					if (result.TYPE === 'S') {
						await this.confirmBooking(mobOrderNo)
					} else {
						// 수납이 확인되지 않음
						// payMthd가 11: 은행, 12 : 카드
						if (recpParams.payMthd === '11') {
							// 은행 결제 체크
							await bankInstantTransfer(recpParams)
								.then(res => {
									transferResponse = res
								})
								.catch(error => {
									this.$alert({ message: error })
								})
						} else {
							// 카드 결제 체크
							await cardTransfer(recpParams)
								.then(res => {
									transferResponse = res
								})
								.catch(error => {
									this.$alert({ message: error })
								})
						}

						const { E_RETURN } = transferResponse.resultObject.data
						if (E_RETURN.TYPE === 'S') {
							// 성공
							await this.confirmBooking(mobOrderNo)
						} else {
							// 실패
							this.$alert({
								message:
									'수납금 결제가 정상적으로 진행되지 않았습니다.<br /> 확인 후 다시 진행해 주세요.'
							})
							const mobOrderNo = this.$store.getters['verify/mobOrderNo']
							const res = await fetchWebOrderInfoById(mobOrderNo)
							let webParams = res.resultObject
							let order = JSON.parse(webParams.orderInfoJson)
							let recp = order.recp.recpInfos
							recp = Object.assign(recp, { errMsg: E_RETURN.MESSAGE })
							this.msg = recp.errMsg
							webParams.orderInfoJson = JSON.stringify(order)
							await postWebOrderInfoById(mobOrderNo, webParams)
						}
					}
				})
				.catch(ERR => {
					const e = ERR.resultObject.data.E_RETURN
					this.$log(e)
					this.$alert({ message: `${e.CODE}: ${e.MESSAGE}` })
				})
		},
		async saveWebOrderInfo(statusCode) {
			const mobOrderNo = this.$store.getters['verify/mobOrderNo']

			const res = await fetchWebOrderInfoById(mobOrderNo)
			const webParams = res.resultObject
			webParams.statusCode = statusCode

			await postWebOrderInfoById(mobOrderNo, webParams)
		},
		async createOrderStatusList() {
			const params = {
				mobOrderNo: this.$store.getters['verify/mobOrderNo']
			}
			const res = await fetchEContract(params)
			const orderList = res.resultObject.data[0].setup
			const orderNoList = []
			for (const order of orderList) {
				orderNoList.push(order.ORDER_NO)
			}

			const response = await checkingStatus({
				saleCd: this.$store.getters['common/codyNo'],
				kunnr: this.$store.getters['verify/custNo'],
				orderNos: orderNoList
			})

			this.orderStatusList = response.resultObject.T_ORDER_STATUS
		},
		async allCanceledOrderChkAndUpdate() {
			const cancelYn = this.orderStatusList.every(v => v.ORDER_STATUS === '203')
			this.$log('all order status cancel : ', cancelYn)
			if (cancelYn) {
				const mobOrderNo = this.$store.getters['verify/mobOrderNo']
				const res = await fetchWebOrderInfoById(mobOrderNo)
				const webParams = res.resultObject
				webParams.statusCode = '203'

				await postWebOrderInfoById(mobOrderNo, webParams)

				const msg = getMessageByStatusCode('203')
				await this.$alert({
					message: msg
				})
			}

			return cancelYn
		},
		// 사전 계약 관리판매인 변경 체크
		async preContractSaleChk() {
			if (!this.isPreContract) return true // 사전계약이 아니면 Pass
			// 할인입력,결제입력,설치입력이 아니라면 Pass
			if (!['104', '105', '107'].includes(this.options.stat.statusCode)) {
				return true
			}

			const preOrderList = []
			const products = this.$store.getters['pay/payProducts']
			products.forEach(product => {
				if (product.options.preOrder !== undefined)
					preOrderList.push(product.options.preOrder.ORDER_NO)
			})

			if (preOrderList.length > 0) {
				return await getPreContractSalesChk({
					saleCd: this.$store.getters['common/codyNo'],
					orderNos: [...preOrderList],
					mobOrderNo: this.$store.getters['verify/mobOrderNo']
				})
					.then(async res => {
						const { result } = res.resultObject
						if (result !== 'T') {
							// 주문 삭제
							await this.$alert({
								message:
									'선택된 기존계정의 관리판매인이 변경된 것으로 확인되어 해당 주문이 삭제되었습니다. '
							})
							const mobOrderNo = this.$store.getters['verify/mobOrderNo']

							const res = await fetchWebOrderInfoById(mobOrderNo)
							const webParams = res.resultObject
							webParams.statusCode = '203'

							postWebOrderInfoById(mobOrderNo, webParams).then(postRes => {
								this.$log('postRes: ', JSON.stringify(postRes))
							})
							return false
						} else {
							return true
						}
					})
					.catch(err => {
						this.$log(err)
						this.$alert(
							'일시적인 통신 장애로 서비스에 접속할 수 없습니다. 문제가 계속된다면, 담당자에게 문의해 주세요.'
						)
					})
			}

			return true
		}
	}
}
</script>

import api from '@/apis/config'
const { get, post, put } = api

/**
 * 고객 인증정보 조회
 * 사용자 키를 이용해 고객 인증정보를 조회합니다.
 * @param {string} userKey: 사용자키
 */
export const fetchStatus = async codyNo => {
	const response = await get(`v1/00/03/${codyNo}`)
	return response
}

/**
 * 웹 주문정보 저장
 * @param {*} params
 */
export const postWebOrderInfo = async (paths, params) => {
	let { codyNo, custNo } = paths
	const response = await post(`v1/00/01/${codyNo}/${custNo}`, { ...params })
	return response
}

/**
 * 웹 주문정보 저장 - id로 저장
 * @param {*} params
 */
export const postWebOrderInfoById = async (id, params) => {
	const response = await put(`v1/00/01/${id}`, { ...params })
	return response
}
/**
 * 배정 진행 후 웹 주문정보 저장 - id로 저장
 * @param {*} id
 * @param {*} params
 */
export const updateCheckingSchedule = async (id, params) => {
	const response = await put(`v1/07/02/${id}`, { ...params })
	return response
}
/**
 * 웹 주문정보 조회
 * @param {*} params
 */
export const fetchWebOrderInfo = async paths => {
	let { codyNo, custNo } = paths
	const response = await get(`v1/00/04/${codyNo}/${custNo}`)
	return response
}

/**
 * 웹 주문정보 조회 - 전자서명 후 id로 조회
 * @param {*} params
 */
export const fetchWebOrderInfoById = async id => {
	const response = await get(`v1/00/05/${id}`)
	return response
}

/**
 * 코디번호 복호화
 * 암호화 및 복호화된 코디번호를 던지면 복호화된 코디번호를 조회
 * @param {*} params
 */
export const fetchCody = async codyNo => {
	const params = { codyNo }
	const response = await post(`v1/00/06/`, { ...params })
	return response
}

/**
 * #285 코디매칭혜택적용방식 구현
 * CASE1 : 코디사번 없음 CASE2: 고객 전화번호 일치 CASE3: 고객 전화번호 불일치
 * matchings/validate
 */
export const codyMatchingValidate = async matching => {
	const response = await post(`v1/00/09`, { ...matching })
	return response
}

/**
 * #382 주문번호 생성 이후 재접속 방어로직 구현
 * matchings/validate
 */
export const mOrderInfoByEtc = async arrParam => {
	let { id, statusCode } = arrParam
	const response = await get(`/v1/00/01/02/${id}/${statusCode}`)
	return response
}
/**
 * 주문상태조회
 *  * @param {*} params
 */

export const checkingStatus = async params => {
	const response = await post('v1/00/12', { ...params })
	return response
}

/**
 * 결제수단 정보 조회
 * @param params
 */
export const paymentList = async params => {
	const response = await get('v1/00/13', { ...params })
	return response
}

/**
 * WRMS(상조) pass인증 암호화하여 조회
 * @param id
 */
export const passInfoEncrypt = async id => {
	const response = await get(`v1/00/14/${id}`)
	return response
}

/**
 * 상조 주문정보 저장 - id로 저장
 * @param {*} params
 */
export const postFuneralServiceInfoById = async (id, params) => {
	const response = await post(`v1/00/15/${id}`, { ...params })
	return response
}

/**
 * 판매인 사번으로 상조 접근 가능한 조직 여부 조회
 * @param codyNo
 */
export const generalOfficeInfo = async codyNo => {
	const response = await get(`v1/00/16/${codyNo}`)
	return response
}

/**
 * 웹 주문번호와 판매인 사번으로 상조 주문 상태 체크
 * @param codyNo
 */
export const getFuneralProcessCheck = async (id, params) => {
	const response = await post(`v1/00/17/${id}`, { ...params })
	return response
}
